import { useLayoutEffect, useEffect } from 'react'

const scrollLockClassName = 'scroll-lock'

export default (state) => {
  const canUseDOM = typeof window !== 'undefined'
  const useIsomorphicLayoutEffect = canUseDOM ? useLayoutEffect : useEffect

  useIsomorphicLayoutEffect(() => {
    // Get original body overflow
    const originalStyleBody = window.getComputedStyle(document.body).overflow
    const originalStyleRoot = window.getComputedStyle(document.documentElement).overflow

    if (state) {
      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden'
      document.documentElement.style.overflow = 'hidden'
      document.body.classList.add(scrollLockClassName)
    } else {
      document.body.style.overflow = originalStyleBody
      document.documentElement.style.overflow = originalStyleRoot
      document.body.classList.remove(scrollLockClassName)
    }

    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalStyleBody
      document.documentElement.style.overflow = originalStyleRoot
      document.body.classList.remove(scrollLockClassName)
    }
  }, [state]) // Empty array ensures effect is only run on mount and unmount
}
