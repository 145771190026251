import React from 'react'

//
import { ButtonGroup, Cta, ButtonNew } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { getUrl } from '../../../utils/urls'
import { LocationContext } from '../../page-wrapper'

const BlockCta = ({ data: { title, text, action, secondaryAction } }) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  return (
    <Cta title={title || ''} text={text || ''}>
      <ButtonGroup center>
        {action ? (
          <ButtonNew
            trackingId={action?.trackingId}
            url={getUrl(action, slugPrefix)}
            variant={BUTTON_VARIANT.PRIMARY}
            classes={'new-c-button--cta'}
          >
            {action?.title || ''}
          </ButtonNew>
        ) : null}
        {secondaryAction ? (
          <ButtonNew
            trackingId={secondaryAction?.trackingId}
            variant={BUTTON_VARIANT.SECONDARY}
            url={getUrl(secondaryAction, slugPrefix)}
            classes={'new-c-button--cta'}
          >
            {secondaryAction?.title || ''}
          </ButtonNew>
        ) : null}
      </ButtonGroup>
    </Cta>
  )
}

export default BlockCta
