import cn from 'classnames'
import React from 'react'

//
import { SectionHeader, SectionImage, SimpleContentBlock } from '../../../storybook'
import { THEME } from '../../../utils/common'

const BlockSummary = ({
  data: { title, preface, theme = THEME.light, imageBackgroundColor, image, simpleContentBlocks }
}) => {
  return (
    <section
      className={cn('l-section l-section--force-padding u-block-gap', {
        'l-section--dark': theme === THEME.dark
      })}
    >
      <div className="l-section__content">
        <div className="l-container:12/12 l-container--default-spacing">
          <div className="l-grid l-grid--default-spacing l-grid--align:middle">
            <div className="l-grid l-grid__col:12/12 l-grid__col:6/12@at:viewport-7">
              <SectionImage image={image} imageBackgroundColor={imageBackgroundColor} />
            </div>

            <div className="l-grid__col:6/12 l-grid__col:12/12@to:viewport-7">
              <div>
                <SectionHeader
                  eyebrow={preface}
                  title={title}
                  headingType="h2"
                  classes="l-section__header"
                  inverted={theme === THEME.dark}
                />
              </div>

              <div className="l-container:12/12 l-container--default-spacing@to:viewport-12">
                {simpleContentBlocks &&
                  simpleContentBlocks.map((item, i) => {
                    return (
                      <div
                        key={`${item.id}_${i}`}
                        className={cn({
                          'u-margin-bottom:24': i < simpleContentBlocks.length - 1
                        })}
                      >
                        <SimpleContentBlock
                          icon={item.icon}
                          bulletListColor={item.bulletListColor}
                          title={item.title}
                          description={item.description}
                          action={item.action}
                          theme={theme}
                        />
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockSummary
