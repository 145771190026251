/* eslint-disable array-callback-return */
import React from 'react'

//
import Dropdown from '../Dropdown'
import Filter from '../Filter'

const FilterOverview = ({
  itemsShown = 8,
  title,
  filters = [],
  handleSelect = () => null,
  selectOnClick = false
}) => {
  const filtersDesktop = []
  const filtersMobile = []

  return (
    <div className="c-filter-overview">
      {filters.length > 0 &&
        filters.map((filter) => {
          const { id, title, value, items, showMoreLabel, showLessLabel } = filter

          filtersDesktop.push(
            <Filter
              key={`filter-overview-desktop-${id}`}
              itemsShown={itemsShown}
              title={title}
              showMoreLabel={showMoreLabel}
              showLessLabel={showLessLabel}
              items={items}
              handleSelect={selectOnClick ? handleSelect : undefined}
              selectOnClick={selectOnClick}
            />
          )

          filtersMobile.push(
            <div key={`filter-overview-mobile-${id}`} className="c-filter-overview__mobile-item">
              <Dropdown
                items={items}
                classes="c-dropdown--inlineblock"
                value={value}
                handleSelect={(val) => handleSelect(val, id)}
              />
            </div>
          )
        })}

      {title && <span className="c-filter-overview__title">{title}</span>}

      <div className="c-filter-overview__desktop">{filtersDesktop}</div>

      <div className="c-filter-overview__mobile">{filtersMobile}</div>
    </div>
  )
}

export default FilterOverview
