import cn from 'classnames'
import React from 'react'

//
import { LocationContext } from '../../components'
import { getUrl } from '../../utils/urls'
import { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
import SectionHeader, { HEADING_TYPE, ALIGN } from '../SectionHeader'
import { withAnchor } from '../hooks'
import { BoardMemberCard, ButtonNew } from '../index'

const TeamMembersBlock = ({
  eyebrow = '',
  title = '',
  description,
  numberOfColumns = 4,
  descriptionTokens = {},
  items = [],
  inverted = false,
  background = 'white',
  classes = '',
  action = null
}) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location
  return (
    <section
      className={cn('u-block-gap l-section', classes, {
        'l-section--black': background === 'dark',
        'l-section--grey': background === 'light'
      })}
    >
      <div className="l-section__header">
        <div className="l-container:8/12 l-container--default-spacing">
          <SectionHeader
            eyebrow={eyebrow}
            title={title}
            headingType={HEADING_TYPE.h2}
            align={ALIGN.center}
            text={description}
            textTokens={descriptionTokens}
            inverted={inverted}
          />
        </div>
      </div>

      <div className="l-section__content">
        <div className="l-container:12/12 l-container--default-spacing">
          <div className="l-grid l-grid--default-spacing">
            {items.length > 0 &&
              items.map((item) => (
                <div
                  key={`board-member-${item.id}`}
                  className={cn(
                    'l-grid l-grid--align:center l-grid__col:12/12 l-grid__col:6/12@at:viewport-7 l-grid__col:4/12@at:viewport-9',
                    { 'l-grid__col:3/12@at:viewport-12': numberOfColumns === 4 }
                  )}
                >
                  <BoardMemberCard
                    image={item.image}
                    title={item.title}
                    subtitle={item.subtitle}
                    inverted={inverted}
                    description={item.description || null}
                    textAlignment={item.textAlignment || null}
                  />
                </div>
              ))}
          </div>
          {action ? (
            <div className="l-grid l-grid--default-spacing l-grid--align:center u-margin-top:24">
              <ButtonNew
                variant={background === 'light' ? BUTTON_VARIANT.PRIMARY : BUTTON_VARIANT.SECONDARY}
                url={getUrl(action, slugPrefix)}
              >
                {action.title}
              </ButtonNew>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  )
}

export default withAnchor(TeamMembersBlock)
