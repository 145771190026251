import cn from 'classnames'
import React from 'react'

//
import { SiteDataContext } from '../../components'
import Integration, { IntegrationTypes } from '../Integration'
import SectionHeader, { HEADING_TYPE } from '../SectionHeader'

const IntegrationSection = ({
  title = '',
  description,
  descriptionTokens = {},
  posTitle = '',
  posIntegrations = [],
  channelsTitle = '',
  channelsIntegrations = [],
  children
}) => {
  const { isTextRtl } = React.useContext(SiteDataContext)

  return (
    <section
      className={cn('l-section l-section--black c-integration-section', {
        'c-integration-section--rtl': isTextRtl
      })}
    >
      <div className="l-container:12/12 l-container--default-spacing">
        <div className="l-grid l-grid--default-spacing l-grid--spacing:16@at:viewport-9 c-integration-section__wrapper">
          <SectionHeader
            title={title}
            text={description}
            textTokens={descriptionTokens}
            inverted
            headingType={HEADING_TYPE.h3}
            classes="c-integration-section__section-header"
          >
            {children}
          </SectionHeader>

          <div className="l-grid l-grid--default-spacing l-grid--align:center c-integration-section__list-container">
            <div className="l-grid__col:12/12@to:viewport-9 c-integration-section__pos">
              <h3 className="u-color:white c-integration-section__column-title">{posTitle}</h3>

              <div className="u-margin-top:24 l-grid l-grid--spacing:4">
                {posIntegrations.length > 0 &&
                  posIntegrations.map((integration) => (
                    <div
                      key={integration.id}
                      className="l-grid__col:12/12 l-grid__col:6/12@at:viewport-4 l-grid__col:12/12@at:viewport-9"
                    >
                      <Integration
                        title={integration.title}
                        type={integration.type}
                        url={integration.url}
                        logo={integration.logo}
                        size={IntegrationTypes.medium}
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div className="l-grid__col:12/12@to:viewport-9">
              <h3 className="u-color:white c-integration-section__column-title">{channelsTitle}</h3>

              <div className="u-margin-top:24 l-grid l-grid--spacing:4">
                {channelsIntegrations.length > 0 &&
                  channelsIntegrations.map((integration) => (
                    <div
                      key={integration.id}
                      className="l-grid__col:12/12 l-grid__col:6/12@at:viewport-4 l-grid__col:12/12@at:viewport-9"
                    >
                      <Integration
                        title={integration.title}
                        type={integration.type}
                        url={integration.url}
                        logo={integration.logo}
                        size={IntegrationTypes.medium}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IntegrationSection
