import cn from 'classnames'
import React, { Children, useState } from 'react'

//
import IntegrationLabel, { IntegrationLabelTypes } from '../IntegrationLabel'

const TabBox = ({ tabs = [], children, handleActiveTab = () => null }) => {
  const [activeTab, setActiveTab] = useState(0)

  const handleClickTab = (index) => {
    // Set active tab based on index
    setActiveTab(index)
    handleActiveTab(index)
  }

  return (
    <div className="c-tab-box">
      <ul className="c-tab-box__tabs">
        {tabs.map((tab, index) => {
          const { id, label, discount } = tab

          return (
            <li
              key={`tab-${id}`}
              className={cn('c-tab-box__tab', {
                'is-active': index === activeTab
              })}
              onClick={() => handleClickTab(index)}
              onKeyDown={() => handleClickTab(index)}
              role="tab"
              tabIndex={0}
            >
              {label}

              {discount && (
                <IntegrationLabel
                  classes={'c-tab-box__discount'}
                  text={discount}
                  type={IntegrationLabelTypes.softGreen}
                >
                  {discount}
                </IntegrationLabel>
              )}
            </li>
          )
        })}
      </ul>

      <div className="c-tab-box__content">
        {Children.map(children, (child, index) => {
          return (
            <div
              className={cn('c-tab-box__content-item', {
                'is-active': index === activeTab
              })}
            >
              {child}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TabBox
