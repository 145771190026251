export const InterfaceIcons = Object.freeze([
  'interface/arrow-right',
  'interface/speech',
  'interface/chevron-down',
  'interface/chevron-up',
  'interface/checkmark',
  'interface/location',
  'interface/order',
  'interface/plus',
  'interface/minus',
  'interface/menu',
  'interface/report',
  'interface/search',
  'interface/switch',
  'interface/times',
  'interface/play',
  'interface/pause',
  'interface/close'
])

export const GeneralIcons = Object.freeze([
  'general/chain',
  'general/dark-kitchen',
  'general/delays',
  'general/delivery',
  'general/eye',
  'general/global',
  'general/orders',
  'general/pos',
  'general/printer',
  'general/profit',
  'general/restaurant',
  'general/saving',
  'general/stress',
  'general/ticket-errors',
  'general/ticket',
  'general/grid',
  'general/cloud-upload'
])

export const TopicIcons = Object.freeze([
  'topic/apple',
  'topic/dark-kitchen',
  'topic/checkmark',
  'topic/checkmark-two',
  'topic/chain-restaurant',
  'topic/delivery-channels',
  'topic/enterprise',
  'topic/graph',
  'topic/heart',
  'topic/in-house-dining-apps',
  'topic/online-ordering',
  'topic/point-of-sales',
  'topic/single-restaurant',
  'topic/speed',
  'topic/ticket',
  'topic/menu',
  'topic/menu-management',
  'topic/play',
  'topic/spaceship',
  'topic/stars',
  'topic/shooting-star',
  'topic/planets',
  'topic/galaxy',
  'topic/astronaut',
  'topic/fulfilment',
  'topic/pickup-screen',
  'topic/delivery-manager-app',
  'topic/kitchen-display-screen',
  'topic/api-docs',
  'topic/blog',
  'topic/ebooks',
  'topic/events',
  'topic/faq',
  'topic/guide',
  'topic/help-desk',
  'topic/phone',
  'topic/support',
  'topic/paper-plane',
  'topic/important'
])

export const SocialIcons = Object.freeze([
  'social/facebook',
  'social/instagram',
  'social/linkedin',
  'social/twitter',
  'social/youtube'
])

export const TablerIcons = Object.freeze([
  'ti-apps',
  'ti-receipt',
  'ti-mail',
  'ti-shopping-cart',
  'ti-message',
  'ti-music'
])
