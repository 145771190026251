import cn from 'classnames'
import { Link } from 'gatsby'
import React from 'react'

//
import ExpansionPanel from '../ExpansionPanel'
import { isExternalUrl } from '../helpers'

const FooterMenu = ({ title = '', items = [], classes = '' }) => {
  return (
    <div className={cn('c-footer-menu', classes)}>
      <div className="c-footer-menu__container-mobile">
        <ExpansionPanel label={title} classes={'c-footer__expansion-title'}>
          {items.length > 0 && (
            <ul className="c-footer-menu__item">
              {items.map((item, i) => (
                <li key={i} className="c-footer-menu__item">
                  {item.url && isExternalUrl(item.url) ? (
                    <a
                      className="c-footer-menu__link"
                      rel="noopener noreferrer"
                      target="_blank"
                      href={item.url}
                    >
                      {item.label}
                    </a>
                  ) : (
                    <Link to={item.url} className="c-footer-menu__link">
                      {item.label}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          )}
        </ExpansionPanel>
      </div>

      <div className="c-footer-menu__container-desktop">
        {title && <h4 className="c-footer-menu__title">{title}</h4>}

        {items.length > 0 && (
          <ul className="c-footer-menu__items">
            {items.map((item, i) => (
              <li key={i} className="c-footer-menu__item">
                {item.url && isExternalUrl(item.url) ? (
                  <a
                    className="c-footer-menu__link"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={item.url}
                  >
                    {item.label}
                  </a>
                ) : (
                  <Link to={item.url} className="c-footer-menu__link">
                    {item.label}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default FooterMenu
