import { useEffect, useState } from 'react'

import { getSliderIndexValue } from '../../helpers/getSliderIndexValue'
import { PRICING } from '../../utils/common'

export const useEstimatedPrice = (
  orders,
  billingTime,
  maxOrders,
  minOrders,
  pricingTiers,
  sliderRef
) => {
  const [orderPlan, setOrderPlan] = useState(PRICING.ORDER_PLAN)

  const sliderIndexValue = getSliderIndexValue(
    maxOrders,
    minOrders,
    pricingTiers,
    sliderRef?.current?.value
  )

  useEffect(() => {
    const plan = pricingTiers[sliderIndexValue]

    setOrderPlan({
      maxNoOfOrders: plan?.maxOrders,
      monthlyExtraCharge: plan?.monthlyExtraCharge,
      annualExtraCharge: plan?.annualExtraCharge,
      maxOrderThreshold: plan?.maxOrderThreshold,
      monthlyPricePlan: plan?.monthlyPricePlan,
      annualPricePlan: plan?.annualPricePlan,
      totalAnnualPricePlan: plan?.totalAnnualPricePlan,
      monthlySetupFee: plan?.monthlySetupFee,
      annualSetupFee: plan?.annualSetupFee
    })
  }, [sliderRef?.current?.value])

  const isMonthlyBillingTime = billingTime === PRICING.BILLING_TIME_MONTHLY

  const {
    monthlyExtraCharge,
    annualExtraCharge,
    maxOrderThreshold,
    monthlyPricePlan,
    annualPricePlan,
    totalAnnualPricePlan,
    monthlySetupFee,
    annualSetupFee
  } = orderPlan

  const extraCharge = isMonthlyBillingTime ? monthlyExtraCharge : annualExtraCharge

  const getExtraCharge = () => {
    if (maxOrderThreshold && orders > maxOrderThreshold) return extraCharge
    return 0
  }
  const extraCharged = getExtraCharge()

  const setUpFee = isMonthlyBillingTime ? monthlySetupFee : annualSetupFee

  const estimatedPricePlan = isMonthlyBillingTime ? monthlyPricePlan : annualPricePlan
  const pricePlan = isMonthlyBillingTime ? monthlyPricePlan : totalAnnualPricePlan

  const totalPrice = pricePlan + setUpFee

  return {
    estimatedPricePlan,
    totalPrice,
    setUpFee,
    isMonthlyBillingTime,
    orderPlan,
    extraCharged
  }
}
