const { URLSearchParams } = require('url')
export function createUrl(imgUrl, options = {}) {
  const cornerRadius = options.cornerRadius === -1 ? `max` : options.cornerRadius

  if (options.toFormat === `auto`) {
    delete options.toFormat
  }

  // Convert to Contentful names and filter out undefined/null values.
  const urlArgs = {
    w: options.width || undefined,
    h: options.height || undefined,
    fl: options.toFormat === `jpg` && options.jpegProgressive ? `progressive` : undefined,
    q: options.quality || undefined,
    fm: options.toFormat || undefined,
    fit: options.resizingBehavior || undefined,
    f: options.cropFocus || undefined,
    bg: options.background || undefined,
    r: cornerRadius || undefined
  }

  const isBrowser = typeof window !== `undefined`

  const searchParams = isBrowser ? new window.URLSearchParams() : new URLSearchParams()

  for (const paramKey in urlArgs) {
    if (typeof urlArgs[paramKey] !== `undefined`) {
      searchParams.append(paramKey, urlArgs[paramKey] ?? ``)
    }
  }

  return `${imgUrl}?${searchParams.toString()}`
}
