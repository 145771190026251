import cn from 'classnames'
import React from 'react'

const Loader = ({ small }) => {
  return (
    <div className="c-loader">
      <div className={cn('c-loader__container', small ? 'small' : '')}>
        <div className="c-loader__circle-1" />

        <div className="c-loader__circle-2" />
      </div>
    </div>
  )
}

export default Loader
