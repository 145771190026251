import React from 'react'

//
import {
  List,
  Wistia,
  FormLayout1 as FormLayout,
  FormLayout2 as FormLayoutWithLeftContent
} from '../../../storybook'
import { getWistiaAspectRatio } from '../../../utils/common'

const BlockWistiaVideo = ({
  data: { title, description, bulletPoints, wistiaVideo, videoSource }
}) => {
  const aspectRatio = getWistiaAspectRatio(wistiaVideo?.aspectRatio)

  if (!bulletPoints) {
    return (
      <FormLayout text={description || ''} title={title || ''} smallPadding>
        <Wistia
          videoSource={videoSource}
          videoId={wistiaVideo?.videoId}
          thumbnail={wistiaVideo?.thumbnail || null}
          aspectRatioX={aspectRatio.x}
          aspectRatioY={aspectRatio.y}
          cover={wistiaVideo?.cover}
          autoPlay={wistiaVideo?.autoPlay}
          controlsVisibleOnLoad={wistiaVideo?.controlsVisibleOnLoad}
          muted={wistiaVideo?.muted}
          transparentBackground={wistiaVideo?.transparentBackground}
          popover={wistiaVideo?.popover}
          hideControls={wistiaVideo?.hideControls}
        />
      </FormLayout>
    )
  }

  return (
    <FormLayoutWithLeftContent text={description || ''} title={title || ''} smallPadding>
      <FormLayoutWithLeftContent.LeftContent>
        <List
          type="ol"
          inverted
          items={bulletPoints.map((item) => ({ id: item.id, text: item.content }))}
        />
      </FormLayoutWithLeftContent.LeftContent>
      <FormLayoutWithLeftContent.MainContentDesktop>
        <Wistia
          videoSource={videoSource}
          videoId={wistiaVideo?.videoId}
          thumbnail={wistiaVideo?.thumbnail || null}
          aspectRatioX={aspectRatio.x}
          aspectRatioY={aspectRatio.y}
          cover={wistiaVideo?.cover}
          autoPlay={wistiaVideo?.autoPlay}
          controlsVisibleOnLoad={wistiaVideo?.controlsVisibleOnLoad}
          muted={wistiaVideo?.muted}
          transparentBackground={wistiaVideo?.transparentBackground}
          popover={wistiaVideo?.popover}
          hideControls={wistiaVideo?.hideControls}
        />
      </FormLayoutWithLeftContent.MainContentDesktop>
      <FormLayoutWithLeftContent.MainContentMobile>
        <Wistia
          videoSource={videoSource}
          videoId={wistiaVideo?.videoId}
          thumbnail={wistiaVideo?.thumbnail || null}
          aspectRatioX={aspectRatio.x}
          aspectRatioY={aspectRatio.y}
          cover={wistiaVideo?.cover}
          autoPlay={wistiaVideo?.autoPlay}
          controlsVisibleOnLoad={wistiaVideo?.controlsVisibleOnLoad}
          muted={wistiaVideo?.muted}
          transparentBackground={wistiaVideo?.transparentBackground}
          popover={wistiaVideo?.popover}
          hideControls={wistiaVideo?.hideControls}
        />
      </FormLayoutWithLeftContent.MainContentMobile>
    </FormLayoutWithLeftContent>
  )
}

export default BlockWistiaVideo
