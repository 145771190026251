import cn from 'classnames'
import React from 'react'

//
import SearchResult from '../SearchResult'

const Search = ({ value, results = [], children, emptyState, classes, noSuggestions = false }) => {
  return (
    <div className={cn('c-search', classes)}>
      {children}

      {!noSuggestions && (
        <>
          <ul
            className={cn('c-search__results', {
              'is-open': value && results.length > 0
            })}
          >
            {results.length > 0 &&
              results.map((result, i) => (
                <li key={`${result.id}-${i}`} className="c-search__item">
                  <SearchResult label={result.label} url={result.url} logo={result.logo} />
                </li>
              ))}
          </ul>

          <div
            className={cn('c-search__results c-search__empty-state', {
              'is-open': value && !results.length
            })}
          >
            {emptyState}
          </div>
        </>
      )}
    </div>
  )
}

export default Search
