import React from 'react'

//
import { MicroCopyContext } from '../../../components/page-wrapper'
import { LoadMore, Articles } from '../../../storybook'
import { ImageFluid } from '../../../utils/image'
import { getMicroCopy } from '../../../utils/microcopy'

const ArticlesListing = ({ articles, slugPrefix = '/', limit = 6 }) => {
  const microCopyData = React.useContext(MicroCopyContext)
  const [offsetToShow, setOffsetToShow] = React.useState(limit)

  const articlesToShow = [...articles]

  return (
    <Articles
      articles={
        articles &&
        articlesToShow &&
        articlesToShow.slice(0, offsetToShow).map((item) => ({
          id: item?.id || item?.sys?.id || '',
          eyebrow: item?.categories?.map((cat) => cat.name)?.join(', '),
          image: <ImageFluid image={item?.image} width={400} height={256} />,
          video: item?.video,
          title: item?.title,
          description: item?.description,
          readtime: getMicroCopy({
            key: `global.readingTime`,
            data: microCopyData,
            tokens: { min: item?.readingTime || '{min}' }
          }),
          date: item?.date,
          url: `${slugPrefix}blog/${item?.categories?.[0]?.slug}/${item.slug}`.replace(
            /\/{2,}/gim,
            '/'
          )
        }))
      }
      footerSlot={
        offsetToShow < articlesToShow.length && (
          <div className="l-section__footer">
            <LoadMore
              current={offsetToShow}
              total={articlesToShow.length}
              loadLabel={getMicroCopy({
                key: `integration.paginationLoadMore`,
                data: microCopyData
              })}
              separationLabel={getMicroCopy({
                key: `integration.paginationOf`,
                data: microCopyData
              })}
              handleLoadMore={() => setOffsetToShow(offsetToShow + limit)}
            />
          </div>
        )
      }
      classes={'l-section--grey'}
    />
  )
}

export default ArticlesListing
