import cn from 'classnames'
import React, { Children, useContext } from 'react'

//
import { SiteDataContext } from '../../components'
import RichText from '../../components/rich-text'
import SectionHeader from '../SectionHeader'

const HERO_DUAL_THEME = {
  LIGHT: 'light',
  DARK: 'dark'
}

const HeroDual = ({
  title = '',
  eyebrow = '',
  media = null,
  text = null,
  textTokens = {},
  logo = null,
  children,
  headingType = 'h1',
  greenEyebrow = false,
  search = false,
  prefaceBelow = true,
  homepage = false,
  theme = HERO_DUAL_THEME.LIGHT,
  classes
}) => {
  const { isTextRtl } = useContext(SiteDataContext)
  if (homepage) {
    return (
      <div className={cn('c-hero-homepage l-grid l-grid--align:middle')}>
        <div className="l-grid__col:6/12 l-grid__col:12/12@to:viewport-9">
          <div className="l-container:12/12 l-container--default-spacing c-hero-home-two__content">
            <h1 className="c-hero-home-two__title">{title}</h1>

            <div className="c-hero-home-two__mobile-visual">{media}</div>

            {text && (
              <div className="c-hero-home-two__description">
                <RichText data={text} tokens={textTokens} />
              </div>
            )}

            {children && (
              <div className="c-hero-home-two__actions">
                {Children.map(children, (child) => (
                  <div className="c-hero-home-two__action">{child}</div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="l-grid__col:6/12 c-hero-home-two__desktop-visual">{media}</div>
      </div>
    )
  }
  return (
    <section
      className={cn('c-hero-dual l-section', classes, {
        'c-hero-dual--rtl': isTextRtl,
        'l-section--dark': theme === HERO_DUAL_THEME.DARK
      })}
    >
      <div className="l-container:12/12 l-container--default-spacing">
        <div className="l-grid l-grid--align:middle l-grid--align:center l-grid--default-spacing">
          <div className="l-grid__col:6/12 l-grid__col:12/12@to:viewport-7">
            <SectionHeader
              logo={logo}
              title={title}
              eyebrow={eyebrow}
              text={text}
              textTokens={textTokens}
              headingType={headingType}
              media={media}
              greenEyebrow={greenEyebrow}
              prefaceBelow={prefaceBelow}
              inverted={theme === HERO_DUAL_THEME.DARK}
            >
              {children}
            </SectionHeader>
          </div>
          <div className="c-hero-dual__media l-grid__col:6/12 u-display:none@to:viewport-7">
            {media}
          </div>
        </div>

        {search ? (
          <div
            className={cn('c-hero-dual__search l-container:12/12 l-container--default-spacing', {
              'c-hero-dual__search-video': media?.props?.videoId
            })}
          >
            {search}
          </div>
        ) : null}
      </div>
    </section>
  )
}

export default HeroDual
