import React, { useState, useContext } from 'react'

//
import { MicroCopyContext, SiteDataContext } from '../../../components/page-wrapper'
import {
  IntegrationDropdown,
  IntegrationResults,
  Matchmaker,
  Kpi,
  Logo,
  ButtonNew
} from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { graphqlFetch } from '../../../utils/graphql-fetch'
import { getMicroCopy } from '../../../utils/microcopy'
import { renderRichTextLine } from '../../../utils/text'
import { getUrl } from '../../../utils/urls'

const query = `
  query($locale: String!, $limit: Int!) {
    integrationCollection(locale: $locale, limit: $limit, order: highlight_ASC) {
      items {
        sys {
          id
        }
        slug
        title
        label
        highlight
        categoriesCollection(locale: $locale, limit: 10) {
          items {
            sys {
              id
            }
            title
            slug
            type
          }
        }
        logo {
          title
          fileName
          url
        }
      }
    }
  }
`

const POS_KEY = 'pos'
const DC_KEY = 'dc'

const BlockMatchMaker = ({
  data: {
    preface = '',
    title = '',
    matchedPreface = '',
    matchedTitle = '',
    preselected = '',
    action,
    extra
  }
}) => {
  const siteData = useContext(SiteDataContext)
  const microCopyData = useContext(MicroCopyContext)
  const [integrations, setIntegrations] = useState()
  const [selectedIntegration, setSelectedIntegration] = useState({
    [POS_KEY]: null,
    [DC_KEY]: null
  })
  const [openDropdown, setOpenDropdown] = useState({ [POS_KEY]: false, [DC_KEY]: false })
  const [searchResults, setSearchResults] = useState({ [POS_KEY]: null, [DC_KEY]: null })

  const { locale, settings, allPublishedIntegrations } = siteData

  const createDropdownItems = (integrations, key) => {
    if (!integrations) {
      return []
    }

    const dropdownItems = [{ id: `group-${key}` }]

    dropdownItems[0].items = integrations.map((integration) => ({
      id: integration.sys.id,
      label: integration.title,
      logo: integration?.logo?.url,
      slug: integration.slug
    }))

    return dropdownItems
  }

  React.useEffect(() => {
    const publishedIntegrationsCount = Object.keys(allPublishedIntegrations || {})?.length
    const variables = { locale: locale, limit: publishedIntegrationsCount }
    graphqlFetch(query, variables)
      .then((res) => {
        const allIntegrations = res?.data?.integrationCollection?.items || []
        const posIntegrations = allIntegrations.filter((int) => {
          return int.categoriesCollection.items.some((cat) => cat.type === 'POS System')
        })

        const dcIntegrations = allIntegrations.filter((int) => {
          return int.categoriesCollection.items.some((cat) => cat.type === 'Delivery Channel')
        })

        const posDropdownItems = createDropdownItems(posIntegrations, POS_KEY)
        const dcDropdownItems = createDropdownItems(dcIntegrations, DC_KEY)

        const preselectedPos =
          posDropdownItems &&
          posDropdownItems[0]?.items?.filter((item) => item.id === preselected)[0]
        let preselectedDc =
          dcDropdownItems && dcDropdownItems[0]?.items?.filter((item) => item.id === preselected)[0]

        if (preselectedPos?.id === preselectedDc?.id) {
          preselectedDc = null
        }

        setIntegrations({
          [POS_KEY]: posDropdownItems,
          [DC_KEY]: dcDropdownItems
        })

        setSelectedIntegration({
          ...setSelectedIntegration,
          [POS_KEY]: preselectedPos,
          [DC_KEY]: preselectedDc
        })
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  const handleIsOpen = (key, state) => {
    setOpenDropdown({
      ...openDropdown,
      [key]: state
    })
  }

  const handleClickResult = (key, item) => {
    setSelectedIntegration({
      ...selectedIntegration,
      [key]: item
    })

    handleIsOpen(key, false)
  }

  const noSearchResult = (
    <Kpi
      icon="topic/online-ordering"
      title={getMicroCopy({ key: 'integration.yourIntegrationNotListed', data: microCopyData })}
      description={renderRichTextLine(
        getMicroCopy({ key: 'integration.whichIntegration', data: microCopyData })
      )}
    >
      <ButtonNew
        variant={BUTTON_VARIANT.SECONDARY}
        size={'md'}
        icon="ti-arrow-narrow-right"
        url={`/${extra.slugPrefix}/${settings.pages.requestIntegration.slug}`}
      >
        {getMicroCopy({ key: 'integration.addYourIntegration', data: microCopyData })}
      </ButtonNew>
    </Kpi>
  )

  const handleSearch = (key, e) => {
    if (!e) {
      setSearchResults({
        ...searchResults,
        [key]: null
      })
    }

    if (e) {
      const filteredResults =
        integrations[key] &&
        integrations[key][0].items.filter((integration) =>
          integration.label.toLowerCase().includes(e.toLowerCase())
        )

      setSearchResults({
        ...searchResults,
        [key]: filteredResults.length
          ? [
              {
                id: `group-${key}`,
                items: filteredResults
              }
            ]
          : []
      })
    }
  }

  if (!integrations) {
    return null
  }

  return (
    <Matchmaker
      subtitle={preface}
      title={title}
      matchSubtitle={matchedPreface}
      matchTitle={matchedTitle}
      matched={selectedIntegration[POS_KEY] && selectedIntegration[DC_KEY]}
      action={action}
      matchAction={{
        label: action?.title,
        url: getUrl(action, extra.slugPrefix)
      }}
    >
      <IntegrationDropdown
        isOpen={openDropdown[POS_KEY]}
        handleIsOpen={(state) => handleIsOpen(POS_KEY, state)}
        value={selectedIntegration[POS_KEY]}
        placeholder={getMicroCopy({ key: 'integration.selectYourPosSystem', data: microCopyData })}
      >
        <IntegrationResults
          debounceDelay="300"
          items={searchResults[POS_KEY] ? searchResults[POS_KEY] : integrations[POS_KEY]}
          handleClick={(item) => handleClickResult(POS_KEY, item)}
          emptyState={noSearchResult}
          handleSearch={(e) => handleSearch(POS_KEY, e)}
          placeholder={getMicroCopy({ key: 'integration.searchPointOfSale', data: microCopyData })}
        />
      </IntegrationDropdown>

      <div className="c-matchmaker__middle-logo">
        <Logo inverted size={60} width={60} />
      </div>

      <IntegrationDropdown
        isOpen={openDropdown[DC_KEY]}
        handleIsOpen={(state) => handleIsOpen(DC_KEY, state)}
        value={selectedIntegration[DC_KEY]}
        placeholder={getMicroCopy({
          key: 'integration.selectYourDeliveryChannel',
          data: microCopyData
        })}
      >
        <IntegrationResults
          debounceDelay="300"
          items={searchResults[DC_KEY] ? searchResults[DC_KEY] : integrations[DC_KEY]}
          handleClick={(item) => handleClickResult(DC_KEY, item)}
          emptyState={noSearchResult}
          handleSearch={(e) => handleSearch(DC_KEY, e)}
          placeholder={getMicroCopy({
            key: 'integration.searchDeliveryChannel',
            data: microCopyData
          })}
        />
      </IntegrationDropdown>
    </Matchmaker>
  )
}

export default BlockMatchMaker
