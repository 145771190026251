import cn from 'classnames'
import React, { useContext } from 'react'

//
import { MicroCopyContext } from '../../components/page-wrapper'
import RichText from '../../components/rich-text'
import { getMicroCopy } from '../../utils/microcopy'
import IconNew from '../IconNew'

const Notification = ({
  title = '',
  text = null,
  textTokens = {},
  isOpen = false,
  handleClose = () => null,
  children,
  classes = ''
}) => {
  const microCopyData = useContext(MicroCopyContext)

  return (
    <div
      className={cn('c-notification', classes, {
        'is-open': isOpen
      })}
    >
      {title && <span className="c-notification__title">{title}</span>}

      {text && (
        <div className="c-notification__text">
          <RichText data={text} tokens={textTokens} />
        </div>
      )}

      {children && <div className="c-notification__actions">{children}</div>}

      <button
        type="button"
        onClick={handleClose}
        className="c-notification__close"
        aria-label={getMicroCopy({ key: 'global.close', fallback: 'Close', data: microCopyData })}
      >
        <IconNew name="tabler-x" size={20} />
      </button>
    </div>
  )
}

export default Notification
