import disableScroll from 'disable-scroll'
import React, { useCallback, useState } from 'react'

import { Modal } from '..'

const useModal = (options = {}, elementId = '___gatsby') => {
  const { preventScroll = false, closeOnOverlayClick = true } = options
  const [isOpen, setOpen] = useState(false)

  const open = useCallback(() => {
    const element = document.querySelectorAll('header.c-header')[0]
    const spacer = document.querySelectorAll('div.pin-spacer')[0]

    setOpen(true)
    if (preventScroll) {
      disableScroll.on()
    }

    if (element) {
      element.classList.add('c-modal-open')
    }

    if (spacer) {
      spacer.style.zIndex = 1
    }
  }, [setOpen, preventScroll])

  const close = useCallback(() => {
    const element = document.querySelectorAll('header.c-header')[0]
    const spacer = document.querySelectorAll('div.pin-spacer')[0]

    setOpen(false)
    if (preventScroll) {
      disableScroll.off()
    }

    if (element) {
      element.classList.remove('c-modal-open')
    }

    if (spacer) {
      spacer.style.zIndex = 6000
    }
  }, [setOpen, preventScroll])

  const onOverlayClick = useCallback(
    (event) => {
      event.stopPropagation()
      if (closeOnOverlayClick) {
        close()
      }
    },
    [closeOnOverlayClick, close]
  )

  const ModalWrapper = useCallback(
    ({ children }) => {
      return (
        <Modal isOpen={isOpen} onOverlayClick={onOverlayClick} elementId={elementId}>
          {children}
        </Modal>
      )
    },
    [isOpen, close, elementId]
  )

  return [ModalWrapper, open, close, isOpen]
}

export default useModal
