import de from 'date-fns/locale/de'
import enGB from 'date-fns/locale/en-GB'
import enUS from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'
import fr from 'date-fns/locale/fr'
import nl from 'date-fns/locale/nl'
import React from 'react'

//
import { MicroCopyContext } from '../../components/page-wrapper'
import { getArticleItems } from '../../helpers/getUserArticleItems'
import { Articles } from '../../storybook'
import { getMicroCopy } from '../../utils/microcopy'

const validLocales = { en: enGB, de: de, fr: fr, es: es, nl: nl, us: enUS }

const RelatedArticles = ({
  articles,
  slugPrefix = 'en',
  title = '',
  preface = '',
  locales = ''
}) => {
  if (!articles?.length) {
    return null
  }

  let locale = locales || slugPrefix.split('-')[0] || 'en'
  if (!validLocales[locale]) {
    locale = 'en'
  }

  const microCopyData = React.useContext(MicroCopyContext)

  const relatedArticles =
    getArticleItems(articles, slugPrefix, locale, microCopyData, validLocales) || []

  return (
    <Articles
      {...{
        title: title || getMicroCopy({ key: `global.relatedArticlesTitle`, data: microCopyData }),
        eyebrow: preface || getMicroCopy({ key: `global.relatedArticles`, data: microCopyData }),
        classes: 'l-section--grey',
        articles: relatedArticles,
        hideDescription: false
      }}
    />
  )
}

export default RelatedArticles
