import cn from 'classnames'
import React, { useContext } from 'react'

import { SiteDataContext } from '../../components'
import RichText from '../../components/rich-text'
import ButtonNew from '../ButtonNew'
import { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'

const PricingSide = ({
  title = '',
  footNote,
  footNoteTokens = {},
  description = '',
  descriptionTokens = {},
  action = {},
  classes = {}
}) => {
  const { isTextRtl } = useContext(SiteDataContext)

  return (
    <>
      <div className={cn('c-pricing-side', classes, { 'c-pricing-side--rtl': isTextRtl })}>
        <div
          className={cn('c-pricing-side__container', { 'c-pricing-side--has-footnote': footNote })}
        >
          <h4 className="c-pricing-side__title">{title}</h4>

          {description && (
            <RichText
              data={description}
              tokens={descriptionTokens}
              classes={cn('s-wysiwyg-editor c-pricing-side__description', {
                's-wysiwyg-editor--rtl': isTextRtl
              })}
            />
          )}

          {action && action.url && action.label && (
            <ButtonNew
              variant={BUTTON_VARIANT.PRIMARY}
              url={action.url}
              classes="c-pricing-side__action"
            >
              {action.label}
            </ButtonNew>
          )}
        </div>

        {footNote && (
          <RichText classes="c-pricing-side__footnote" data={footNote} Tokens={footNoteTokens} />
        )}
      </div>
    </>
  )
}

export default PricingSide
