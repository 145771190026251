import React from 'react'

//
import { HeroDual as StorybookHeroDual, ButtonNew } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { getUrl } from '../../../utils/urls'
import { LocationContext } from '../../page-wrapper'
import { MediaComponent } from '../crissCross/mediaComponent'

const HeroDual = ({
  data: {
    preface,
    title,
    description,
    primaryAction,
    secondaryAction,
    image,
    wistiaVideo,
    logo,
    homepage,
    theme
  }
}) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  return (
    <StorybookHeroDual
      headingType="h1"
      eyebrow={preface}
      logo={logo?.svgIcon?.file?.url}
      title={title}
      text={description}
      search={null}
      prefaceBelow={false}
      homepage={homepage}
      media={<MediaComponent {...{ wistiaVideo, image }} />}
      theme={theme}
    >
      {primaryAction && (
        <div>
          <ButtonNew
            variant={BUTTON_VARIANT.GREEN}
            url={getUrl(primaryAction, slugPrefix)}
            icon={primaryAction?.icon || ''}
            subtext={primaryAction.subtext}
          >
            {primaryAction?.title || ''}
          </ButtonNew>
        </div>
      )}
      {secondaryAction && (
        <ButtonNew
          url={getUrl(secondaryAction, slugPrefix)}
          icon={secondaryAction?.icon || ''}
          variant={BUTTON_VARIANT.SECONDARY}
          subtext={secondaryAction.subtext}
        >
          {secondaryAction?.title || ''}
        </ButtonNew>
      )}
    </StorybookHeroDual>
  )
}

export default HeroDual
