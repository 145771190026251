import cn from 'classnames'
import React from 'react'

import { IconNew } from '..'
import { LocationContext } from '../../components'
import RichText from '../../components/rich-text'
import { THEME } from '../../utils/common'
import { getUrl } from '../../utils/urls'
import ButtonNew, { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'

const SimpleContentBlock = ({
  icon,
  title,
  description,
  action,
  bulletListColor,
  classes,
  theme = THEME.light
}) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  return (
    <div className={cn('c-simple_content_block__container', classes)}>
      {icon ? (
        <div
          className={cn(
            'c-feature-card-list__items-icon',
            bulletListColor ? `c-feature-card-list__items-icon--${bulletListColor}` : null
          )}
        >
          {' '}
          <IconNew name={icon} />{' '}
        </div>
      ) : null}
      <div>
        {title ? <h3 className="c-simple_content_block__title">{title}</h3> : null}
        {description ? (
          <div className="c-simple_content_block__description">
            {typeof description === 'string' ? (
              description
            ) : (
              <RichText data={description} classes="s-wysiwyg-editor " />
            )}
          </div>
        ) : null}
        {action ? (
          <div className="c-simple_content_block__action">
            {' '}
            <ButtonNew
              url={getUrl(action, slugPrefix)}
              variant={theme === THEME.dark ? BUTTON_VARIANT.SECONDARY : BUTTON_VARIANT.PRIMARY}
              size="md"
            >
              {action.title}
            </ButtonNew>{' '}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default SimpleContentBlock
