import cn from 'classnames'
import React, { useContext } from 'react'

//
import { MicroCopyContext } from '../../components/page-wrapper'
import { getMicroCopy } from '../../utils/microcopy'

const Hamburger = ({ isOpen = false, toggleIsOpen = () => null, classes = '' }) => {
  const microCopyData = useContext(MicroCopyContext)
  const Microkey = isOpen ? 'global.close' : 'global.open'

  return (
    <button
      onClick={toggleIsOpen}
      className={cn('c-hamburger', classes, {
        open: isOpen
      })}
      aria-label={getMicroCopy({ key: Microkey, fallback: 'Close', data: microCopyData })}
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  )
}

export default Hamburger
