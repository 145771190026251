import cn from 'classnames'
import React from 'react'

//
import { ImageFluid } from '../../utils/image'
import Kpi from '../Kpi'
import { NavLink } from '../helpers'
import { withAnchor } from '../hooks'

const FeatureCard = ({
  title = '',
  icon = null,
  bulletListColor,
  description,
  textTokens = {},
  image,
  children,
  classes = '',
  theme,
  url = null
}) => {
  const fluidImg = image && 'fluid' in image

  return (
    <div
      data-variant={theme}
      className={cn('c-feature-card', classes, { 'c-feature-card--noImage': !image })}
    >
      <div className="c-feature-card__content">
        <Kpi
          title={title}
          bulletListColor={bulletListColor}
          description={description}
          icon={icon}
          textTokens={textTokens}
        >
          {children}
        </Kpi>
      </div>

      {url && image ? (
        <NavLink className={'c-feature-card__image'} to={url}>
          <div
            className={cn({
              'c-feature-card__image--clickable': url
            })}
          >
            {!fluidImg ? image : <ImageFluid image={image} containImages />}
          </div>
        </NavLink>
      ) : image ? (
        <div
          className={cn('c-feature-card__image', {
            'c-feature-card__image--clickable': url
          })}
        >
          {!fluidImg ? image : <ImageFluid image={image} containImages />}
        </div>
      ) : null}
    </div>
  )
}

export default withAnchor(FeatureCard)
