import cn from 'classnames'
import React from 'react'

//
import { SiteDataContext } from '../../components'
import { ImageFluid } from '../../utils/image'
import Icon from '../IconNew/Icon'

const MenuList = ({ title = '', items = [], classes }) => {
  const { isTextRtl } = React.useContext(SiteDataContext)

  const getImage = (item) => {
    if (item.image?.fluid) return item.image

    return item.image?.file ?? item.logo?.file ?? null
  }

  return (
    <div className={cn('c-menu-list', classes, { 'c-menu-list--rtl': isTextRtl })}>
      {title && <div className={'c-menu-list__title'}>{title}</div>}

      <ul className="c-menu-list__list">
        {items.map((item) => {
          if (item.image && item.background) {
            return (
              <li key={item.id}>
                <a
                  href={item.url}
                  style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}
                  className="c-menu-list__item--with-illustration"
                >
                  {item.label && (
                    <div className={cn('c-menu-list__title--product')}>
                      {item.logo && (
                        <ImageFluid
                          image={{
                            ...item.logo.file
                          }}
                          width={28}
                          height={28}
                          containImages={true}
                        />
                      )}
                      {item.label}
                    </div>
                  )}
                  {item.image || item.background ? (
                    <div
                      className={cn('c-menu-list__illustration', {
                        'c-menu-list__illustration--no-background': !item.background
                      })}
                    >
                      {item.background && (
                        <div style={{ position: 'absolute' }}>
                          <ImageFluid image={item.background?.file} />
                        </div>
                      )}
                      {item.image && (
                        <div>
                          <ImageFluid image={item.image?.file} containImages />
                        </div>
                      )}
                    </div>
                  ) : null}
                  {item.subtext && <p className="c-menu-list__description">{item.subtext}</p>}
                </a>
              </li>
            )
          }

          let assetToDisplay = null

          const img = getImage(item)
          if (img) {
            assetToDisplay = (
              <div className="c-menu-list__item-logo">
                <ImageFluid image={img} width={28} height={28} />
              </div>
            )
          } else if (item.icon) {
            assetToDisplay = (
              <div>
                <Icon name={item.icon} classes={'c-menu-list__item-icon'} />
              </div>
            )
          }
          return (
            <li key={item.id}>
              <a href={item.url} className="c-menu-list__item">
                {assetToDisplay}
                <div>
                  <div className="c-menu-list__item-title">{item.label}</div>
                  {item.subtext ? (
                    <div className="c-menu-list__item-subtext">{item.subtext}</div>
                  ) : null}
                </div>
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default MenuList
