import cn from 'classnames'
import React from 'react'

import { IconNew } from '..'
import { SiteDataContext } from '../../components'
import ButtonNew, { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
import IconButton from '../IconButton'
import { NavLink } from '../helpers'
import { withAnchor } from '../hooks'

const Story = ({
  name = '',
  type = '',
  logo = '',
  image,
  title = '',
  url = '',
  video = false,
  actionLabel = '',
  classes = ''
}) => {
  const { isTextRtl } = React.useContext(SiteDataContext)

  return (
    <NavLink to={url} className={cn('c-story', classes, { 'c-story--rtl': isTextRtl })}>
      {image && (
        <div className="c-story__image">
          {image}

          {video && (
            <div className="c-story__play">
              <IconButton icon="tabler-play" black />
            </div>
          )}
        </div>
      )}

      <div className="c-story__content-wrapper">
        {logo && (
          <div className="c-story__logo-wrapper">
            <img
              loading="lazy"
              src={logo.image}
              title={logo.title}
              alt={logo.title || title}
              className="c-story__logo"
              width="58"
              height="58"
            />
            <h4 className="c-story__name">{name}</h4>
          </div>
        )}

        {/* eyebrow && <p className="c-story__eyebrow">{eyebrow}</p> */}

        {type && <p className="c-story__type">{type}</p>}

        {title && <h4 className="c-story__title">{title}</h4>}

        {actionLabel && (
          <div className="c-story__action">
            <ButtonNew variant={BUTTON_VARIANT.SECONDARY} size="md">
              {actionLabel} <IconNew name={'tabler-arrow-narrow-right'} />
            </ButtonNew>
          </div>
        )}
      </div>
    </NavLink>
  )
}

export default withAnchor(Story)
