import auth0 from 'auth0-js'

import { getContentForMarket } from './market'
import { getSlug } from './slugs'
import { extendUrlByUtmParams } from './utm'

const GATSBY_AUTH0_CLIENT_ID = process.env.GATSBY_AUTH0_CLIENT_ID
const GATSBY_AUTH0_DOMAIN = process.env.GATSBY_AUTH0_DOMAIN

export const getUrl = (navItem, slugPrefix) => {
  if (!navItem || process.env.PREVIEW_MODE === 'true') {
    return '#'
  }

  const { externalUrl, content, relativePath, sendUtmParams } = navItem

  // External url takes precedence
  if (externalUrl) {
    return extendUrlByUtmParams(externalURLBuilder(externalUrl, slugPrefix), sendUtmParams)
  }

  if (content) {
    // check if has items array
    if (Array.isArray(content.items) && content.items.length > 0) {
      if (
        content.items[0].__typename === 'WistiaVideo' ||
        content.items[0].__typename === 'ContentfulWistiaVideo'
      ) {
        return {
          videoId: content.items[0].videoId
        }
      }
    } else if (Array.isArray(content) && content.length > 0) {
      if (
        content[0]?.__typename === 'WistiaVideo' ||
        content[0]?.__typename === 'ContentfulWistiaVideo'
      ) {
        return {
          videoId: content[0].videoId
        }
      }
    }
  }

  // if content is an array and has at least 1 item
  return extendUrlByUtmParams(internalURLBuilder(content, slugPrefix, relativePath), sendUtmParams)
}

function externalURLBuilder(externalUrl, slugPrefix) {
  const startingWords = ['tel:', 'mailto', 'http', slugPrefix, `/${slugPrefix}`]
  const isStartsWith = startingWords.some((word) => externalUrl.startsWith(word))

  if (isStartsWith && externalUrl.includes('createaccount')) {
    return auth0UrlBuilder(externalUrl)
  }

  if (isStartsWith) {
    return externalUrl
  }

  return `/${slugPrefix}/${externalUrl}`.replace(/\/\//g, '/')
}

function internalURLBuilder(content, slugPrefix, relativePath) {
  if (Array.isArray(content) && content.length > 0) {
    const contentToUse = getContentForMarket(content, slugPrefix)
    return getSlug(contentToUse, slugPrefix)

    // otherwise if content is set and has a slug
  } else if (content && content.slug) {
    return getSlug(content, slugPrefix)
  }

  // for notification bar
  if (content?.items && Array.isArray(content?.items) && content?.items[0]) {
    const { slug, customerSlug, customerTypeSlug, careersSlug, blogSlug, integrationSlug } =
      content?.items[0]

    if (typeof customerSlug === 'string') {
      return `/${slugPrefix}/customers/${customerSlug}`.replace(/\/\//g, '/')
    }
    if (typeof customerTypeSlug === 'string') {
      return `/${slugPrefix}/customers/type/${customerTypeSlug}`.replace(/\/\//g, '/')
    }
    if (typeof careersSlug === 'string') {
      return `/en/careers/${careersSlug}`.replace(/\/\//g, '/')
    }
    if (typeof blogSlug === 'string') {
      return `/${slugPrefix}/blog/${blogSlug}`.replace(/\/\//g, '/')
    }
    if (typeof integrationSlug === 'string') {
      return `/${slugPrefix}/integrations/${integrationSlug}`.replace(/\/\//g, '/')
    }
    return `/${slugPrefix}/${slug}`.replace(/\/\//g, '/')
  }

  if (relativePath) {
    return `/${slugPrefix}${relativePath}`.replace(/\/\//g, '/')
  }

  return '#'
}

function auth0UrlBuilder(externalUrl) {
  if (typeof window !== 'undefined') {
    const webAuth = new auth0.WebAuth({
      domain: GATSBY_AUTH0_DOMAIN,
      clientID: GATSBY_AUTH0_CLIENT_ID,
      action: 'signup'
    })

    return webAuth.client.buildAuthorizeUrl({
      clientID: GATSBY_AUTH0_CLIENT_ID,
      responseType: 'token',
      redirectUri: externalUrl,
      screen_hint: 'signup',
      initialScreen: 'signUp',
      is_selfserve: true
    })
  }
}
