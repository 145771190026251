import format from 'date-fns/format'
import React from 'react'

import { getDateFromTimeStamp } from '../utils/date-from-timestamp'
import { ImageFluid, ImageFluidPreview } from '../utils/image'
import { getMicroCopy } from '../utils/microcopy'

export const getArticleItems = (
  articleItems,
  slugPrefix,
  locale,
  microCopyData,
  validLocales,
  preview
) => {
  if (!articleItems && !articleItems?.length) return null

  const getArticleCategories = (data) => {
    if (data?.categories) return data?.categories
    return data?.categoriesCollection?.items
  }

  const getImageComponent = (image) => {
    if (preview) return <ImageFluidPreview url={image?.url} />
    return <ImageFluid image={image} width={400} height={256} />
  }

  const getCustomerData = (article) => {
    let articleCTAText = ''

    if (article.actionLabel) {
      const actionLabelObj = article.actionLabel.reduce((a, v) => ({ ...a, [v.__typename]: v }), {})
      articleCTAText = getMicroCopy({
        key: `${actionLabelObj['ContentfulMicrocopySet'].key}.${actionLabelObj['ContentfulMicrocopy'].key}`,
        data: microCopyData
      })
    }

    return {
      type: 'ContentfulCustomer',
      id: article.id,
      icon: article.logo ? (
        <ImageFluid
          image={{
            url: article.logo.file.url
          }}
          width={40}
          height={40}
          containImages={true}
          style={{
            flex: 'none'
          }}
        />
      ) : null,
      title: article.title || '',
      url: !preview ? `/${slugPrefix}/customers/${article.slug}`.replace(/\/{2,}/gim, '/') : '#',
      image: article.image ? <ImageFluid image={article.image} /> : null,
      eyebrow: article.type ? article.type.name : '',
      description: article.description,
      readtime: articleCTAText
    }
  }

  return articleItems?.map((article) => {
    const articleData = article

    if (article['__typename'] === 'ContentfulCustomer') {
      return getCustomerData(article)
    }

    const date = getDateFromTimeStamp(articleData?.articlePublishedDate)
    const categories = getArticleCategories(articleData) || []

    const imageComponent = getImageComponent(articleData.image)
    const url = !preview
      ? `/${slugPrefix}/blog/${categories?.[0]?.slug}/${articleData.slug}`.replace(/\/{2,}/gim, '/')
      : '#'

    return {
      type: 'ContentfulArticle',
      id: article?.id || article?.sys?.id || '',
      eyebrow: categories?.map((cat) => cat.name)?.join(', '),
      image: imageComponent,
      video: articleData.video || null,
      title: articleData.title,
      description: articleData.description?.description || articleData.description,
      readtime: articleData.readingTime
        ? getMicroCopy({
            key: `global.readingTime`,
            data: microCopyData,
            tokens: { min: articleData.readingTime || '{min}' }
          })
        : null,
      date: date ? format(date, 'MMMM d, y', { locale: validLocales[locale] || 'en' }) : null,
      url
    }
  })
}
