import cn from 'classnames'
import React from 'react'

//
import RichText from '../../components/rich-text'
import IconNew from '../IconNew'

const Checkbox = ({
  label = null,
  textLabel = '',
  labelTokens = {},
  handleChange = () => null,
  classes = '',
  checked = false
}) => {
  return (
    <label className={cn('c-checkbox', classes)}>
      <input
        type="checkbox"
        onChange={(e) => handleChange(e.target.checked)}
        className="c-checkbox__input"
        checked={checked}
      />

      <div className="c-checkbox__control">
        <IconNew name="tabler-check" size={24} classes="c-checkbox__icon" />
      </div>

      {label && (
        <span className="c-checkbox__label">
          <RichText data={label} tokens={labelTokens} />
        </span>
      )}

      {textLabel && !label && <span className="c-checkbox__label">{textLabel}</span>}
    </label>
  )
}

export default Checkbox
