import cn from 'classnames'
import React from 'react'

//
import ButtonNew from '../ButtonNew'
import { FeatureCard } from '../FeatureCard'
import SectionHeader, { ALIGN, HEADING_TYPE } from '../SectionHeader'
import { withAnchor } from '../hooks'

const FeatureContactBlock = ({
  title,
  eyebrow = '',
  description,
  descriptionTokens = {},
  items = [],
  classes = ''
}) => {
  return (
    <section className={cn('l-section', classes)}>
      {title && (
        <div className="l-section__header">
          <div className="l-container:8/12 l-container--default-spacing">
            <SectionHeader
              title={title}
              eyebrow={eyebrow}
              text={description}
              textTokens={descriptionTokens}
              headingType={HEADING_TYPE.h2}
              align={ALIGN.center}
            />
          </div>
        </div>
      )}

      <div className="l-section--abstract">
        <div className="l-container:12/12 l-container--default-spacing">
          <div className="l-grid l-grid--spacing:20 l-grid--spacing:16@to:viewport-9">
            {items.length > 0 &&
              items.map((feature) => {
                const { id, title, icon, description, textTokens, action } = feature

                return (
                  <FeatureCard
                    key={`story-${id}`}
                    title={title}
                    icon={icon}
                    description={description}
                    textTokens={textTokens}
                    classes="l-grid__col:6/12 l-grid__col:12/12@to:viewport-7"
                  >
                    {action && <ButtonNew {...action}>{action.text}</ButtonNew>}
                  </FeatureCard>
                )
              })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default withAnchor(FeatureContactBlock)
