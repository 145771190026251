import React, { useState, useEffect, useContext } from 'react'

//
import { ButtonNew, Notification } from '../../storybook'
import { BUTTON_VARIANT } from '../../storybook/ButtonNew/ButtonNew'
import { useLocalStorage } from '../../utils/local-storage'
import { getMicroCopy } from '../../utils/microcopy'
import { renderRichTextLine } from '../../utils/text'
import { SiteDataContext, MicroCopyContext } from '../page-wrapper'

const CountrySwitcher = ({ marketsSlugs, allMarkets }) => {
  const settings = useContext(SiteDataContext)
  const microCopyData = useContext(MicroCopyContext)
  const { market, lang } = settings
  const { isoCode } = market

  const [dismissed, setDismissed] = useLocalStorage('dismissed', [])
  const [isNotificationOpen, setIsNotificationOpen] = useState(true)
  const [userCountry, setUserCountry] = useState(isoCode)
  const [userCountryTitle, setUserCountryTitle] = useState(isoCode)
  const [switchSlug, setSwitchSlug] = useState(null)

  const toggleIsNotificationOpen = () => {
    if (isNotificationOpen) {
      const values = [...dismissed]
      if (!values.includes(isoCode)) {
        values.push(isoCode)
        setDismissed(values)
      }
    }
    setIsNotificationOpen(!isNotificationOpen)
  }

  useEffect(() => {
    if (process.env.GATSBY_GET_COUNTRY_URL) {
      fetch(process.env.GATSBY_GET_COUNTRY_URL)
        .then((res) => res.json())
        .then((res) => {
          // Notification should be only displayed if user is on a valid country (market)
          if (res?.country && allMarkets[res.country] && res.country !== isoCode) {
            const title = allMarkets[res.country][lang] && allMarkets[res.country][lang].title
            const slug = (title && marketsSlugs[title]) || null

            if (slug) {
              setUserCountry(res.country)
              setUserCountryTitle(title)
              setSwitchSlug(slug)
            }
          }
        })
        .catch((e) => console.error(e))
    }
  }, [isoCode])

  if (isoCode === userCountry || !switchSlug || dismissed.includes(isoCode)) {
    return null
  }

  return (
    <Notification
      handleClose={toggleIsNotificationOpen}
      isOpen={isNotificationOpen}
      text={renderRichTextLine(
        `${getMicroCopy({
          key: 'global.switchLocationText',
          data: microCopyData
        })}`,
        []
      )}
      textTokens={{
        country: userCountryTitle
      }}
      title={''}
    >
      <ButtonNew icon="ti-arrow-narrow-right" variant={BUTTON_VARIANT.SECONDARY} url={switchSlug}>
        {`${getMicroCopy({ key: 'global.switchLocationCTALabel', data: microCopyData })}`}
      </ButtonNew>
    </Notification>
  )
}

export default CountrySwitcher
