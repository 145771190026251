import cn from 'classnames'
import React from 'react'

//
import { SiteDataContext } from '../../components'
import RichText from '../../components/rich-text'
import { withAnchor } from '../hooks'

const Testimonial = ({ title = '', text = '', textTokens = {}, image, children, classes = '' }) => {
  const { isTextRtl } = React.useContext(SiteDataContext)

  return (
    <div className={cn('c-testimonial', classes, { 'c-testimonial--rtl': isTextRtl })}>
      {title && <span className="c-testimonial__title">{title}</span>}

      {text && (
        <div className="c-testimonial__text">
          <RichText data={text} tokens={textTokens} />
        </div>
      )}

      {children && <div className="c-testimonial__action">{children}</div>}
    </div>
  )
}

export default withAnchor(Testimonial)
