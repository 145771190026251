import React from 'react'

import { Wistia } from '../../../storybook'
import { getWistiaAspectRatio } from '../../../utils/common'
import { ImageFluid, ImageFluidPreview } from '../../../utils/image'

// Image or Video (Wisitia)
export const MediaComponent = ({ height, width, image, wistiaVideo, preview }) => {
  const isMediaMP4 = image?.file?.contentType === 'video/mp4'

  if (isMediaMP4) {
    return (
      <video loop playsInline autoPlay muted aria-label="Video Player" style={{ width: '100%' }}>
        <source src={image?.file?.url} type="video/mp4" />
        <track kind="captions" />
      </video>
    )
  }

  if (wistiaVideo && wistiaVideo?.videoId) {
    return (
      <Wistia
        videoSource={wistiaVideo.videoSource}
        thumbnail={wistiaVideo?.thumbnail || image || null}
        videoId={wistiaVideo.videoId}
        aspectRatioX={getWistiaAspectRatio(wistiaVideo?.aspectRatio).x}
        aspectRatioY={getWistiaAspectRatio(wistiaVideo?.aspectRatio).y}
        cover={wistiaVideo?.cover}
        autoPlay={wistiaVideo?.autoPlay}
        controlsVisibleOnLoad={wistiaVideo?.controlsVisibleOnLoad}
        muted={wistiaVideo?.muted}
        transparentBackground={wistiaVideo?.transparentBackground}
        popover={wistiaVideo?.popover}
        hideControls={wistiaVideo?.hideControls}
      />
    )
  }
  if (preview && image?.url) {
    return <ImageFluidPreview url={image?.url} height={height} width={width} />
  }
  if (image?.fluid || image?.url) {
    return <ImageFluid {...{ image, height, width }} />
  }
  return null
}
