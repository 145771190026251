import Cookies from 'js-cookie'

export function getCookie(name) {
  const cookie = name ? Cookies.get(name) : Cookies.get()
  const parsedCookie = typeof cookie === 'string' && JSON.parse(cookie)
  return parsedCookie
}

export function setCookie(name, value, attributes) {
  if (typeof value !== 'string') return Cookies.set(name, JSON.stringify(value), attributes)
  return Cookies.set(name, value, attributes)
}
