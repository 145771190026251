import React from 'react'

import { MicroCopyContext } from '../../../components/page-wrapper'
import { LoadMore, Testimonials } from '../../../storybook'
import { ImageFluid } from '../../../utils/image'
import { getMicroCopy } from '../../../utils/microcopy'
import { CUSTOMER_TYPES } from '../customerTypes'

function TestimonialsListing({ customers = [], limit = 6, slugPrefix }) {
  const microCopyData = React.useContext(MicroCopyContext)
  const [offsetToShow, setOffsetToShow] = React.useState(limit)

  const customersToShow = customers.slice()

  const stories = customersToShow.slice(0, offsetToShow).map((item) => {
    return {
      id: item?.id || item?.sys?.id || '',
      name: item?.name,
      type: item.customerType
        ? getMicroCopy({ key: `global.${CUSTOMER_TYPES[item.customerType]}`, data: microCopyData })
        : '',
      logo: item?.logo && { image: item?.logo?.url, title: item?.logo?.title },
      image: <ImageFluid image={item?.image} />,
      title: item?.quote,
      actionLabel: getMicroCopy({ key: 'global.readTestimonial', data: microCopyData }),
      url: `/${slugPrefix}customers/${item.slug}`.replace(/\/{2,}/gim, '/')
    }
  })

  const footerSlot = offsetToShow < customersToShow.length && (
    <div className="l-section__footer">
      <LoadMore
        current={offsetToShow}
        total={customersToShow.length}
        loadLabel={getMicroCopy({ key: 'integration.paginationLoadMore', data: microCopyData })}
        separationLabel={getMicroCopy({ key: 'integration.paginationOf', data: microCopyData })}
        handleLoadMore={() => setOffsetToShow(offsetToShow + limit)}
      />
    </div>
  )

  return <Testimonials stories={stories} footerSlot={footerSlot} />
}

export default TestimonialsListing
