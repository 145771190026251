export const replaceTokens = (text, tokens = {}) => {
  const replacements = {}
  Object.keys(tokens).forEach((key) => {
    replacements[`{${key}}`] = tokens[key]
  })

  if (text && Object.keys(replacements).length) {
    const re = new RegExp(Object.keys(replacements).join('|'), 'gi')
    const result = text.replace(re, (matched) => {
      return replacements[matched]
    })

    return result
  }

  return text
}

export const renderRichTextLine = (textLine = '') => {
  return {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: textLine,
            nodeType: 'text'
          }
        ],
        nodeType: 'paragraph'
      }
    ],
    nodeType: 'document'
  }
}
