import getUnixTime from 'date-fns/getUnixTime'
import React, { useState, useEffect, useMemo } from 'react'

//
import { ButtonNew, HeroDual, Loader, ToggleGroup, Wistia } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import Hero from '../../../storybook/Hero/Hero'
import { getWistiaAspectRatio } from '../../../utils/common'
import { graphqlFetch } from '../../../utils/graphql-fetch'
import { ImageFluid } from '../../../utils/image'
import { getMicroCopy } from '../../../utils/microcopy'
import { LocationContext, MicroCopyContext } from '../../page-wrapper'
import { CUSTOMER_TYPES } from '../customerTypes'

import TestimonialsListing from './testimonials'

const query = `
  query($locale: String!, $enabledLocales: Boolean!, $slugPrefix: String!) {
    customerCollection(locale: $locale, limit: 200, where: { enabledLocales: $enabledLocales }, order:highlight_DESC) {
      items {
        sys {
          id
          firstPublishedAt
        }
        customerType
        highlight
        title
        quote
        name
        slug
        logo {
          url
        }
        image {
          url
          height
          width
        }
        enabledMarketsCollection(limit: 1,where:{slugPrefix: $slugPrefix}){
          items {
            slugPrefix
          }
        }
      }
    }
  }
`

const BlockCustomersTop = ({
  data,
  data: { title, description, defaultStoryHighlight, customerTypesFilter }
}) => {
  const [selectedType, setSelectedType] = useState('')
  const [isLoading, setIsLoading] = React.useState(true)
  const [customers, setCustomers] = React.useState(null)
  const microCopyData = React.useContext(MicroCopyContext)
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  useEffect(() => {
    const variables = {
      locale: data.extra.locale,
      enabledLocales: true,
      slugPrefix: slugPrefix.replace(/\//g, '')
    }

    window.scrollTo({ top: 0 })

    graphqlFetch(query, variables)
      .then((res) => {
        const customerStoriesFetched =
          res?.data?.customerCollection?.items
            ?.filter(
              (node) =>
                data.extra.allPublishedCustomers[node.sys.id] &&
                node.enabledMarketsCollection.items.length > 0
            )
            .map((customer) => {
              if (customer?.sys?.firstPublishedAt) {
                const timestamp = getUnixTime(new Date(customer?.sys?.firstPublishedAt))
                return { ...customer, timestamp }
              }
              return customer
            }) || []

        customerStoriesFetched
          .sort((a, b) => {
            if (a?.highlight) return -1
            if (a?.timestamp > b?.timestamp) {
              return -1
            }
            if (a?.timestamp === b?.timestamp) {
              return 0
            }
            if (a?.timestamp < b?.timestamp) {
              return 1
            }
            return -1
          })
          .sort((a) => (a.highlight ? -1 : 1))

        setCustomers(customerStoriesFetched)

        window.scrollTo({ top: 0 })
        setIsLoading(false)
      })
      .catch((e) => {
        console.error(e)
      })

    if (!location?.hash) return

    const decodedHash = decodeURIComponent(location.hash).slice(1)
    if (!customerTypesFilter.includes(decodedHash)) return

    setSelectedType(decodedHash)
  }, [])

  function setAnchor(value) {
    window.location.hash = value
  }

  const handleToggle = (value) => {
    setAnchor(value)
    setSelectedType(value)
  }

  const filterArray = useMemo(() => {
    return [
      {
        id: 'item1',
        value: '',
        label: getMicroCopy({ key: `global.allStories`, data: microCopyData })
      },
      ...customerTypesFilter.map((type, i) => {
        return {
          id: 'item' + (i + 2),
          value: type,
          label: type
        }
      })
    ]
  }, [customerTypesFilter])

  const selectedStoryHighlight =
    customers && typeof selectedType === 'string' && selectedType.length > 0
      ? customers.find((customer) => customer.customerType === selectedType)
      : defaultStoryHighlight

  const customersToShow = selectedType
    ? customers?.filter(
        (customer) =>
          customer?.customerType === selectedType && customer?.slug !== selectedStoryHighlight.slug
      )
    : customers?.filter((customer) => customer?.slug !== selectedStoryHighlight?.slug)

  return (
    <>
      <Hero title={title} content={description} background="c-hero--green-pattern">
        <ToggleGroup activeValue={selectedType} handleToggle={handleToggle} items={filterArray} />
      </Hero>
      {!isLoading ? (
        selectedStoryHighlight || customersToShow?.length > 0 ? (
          <div className="u-margin-top:-16 u-border-radius-top u-background-color:white">
            <HeroDual
              headingType="h3"
              logo={selectedStoryHighlight?.logo?.file?.url || selectedStoryHighlight?.logo?.url}
              title={selectedStoryHighlight?.title}
              text={selectedStoryHighlight?.description || selectedStoryHighlight?.quote}
              eyebrow={
                selectedStoryHighlight?.customerType
                  ? getMicroCopy({
                      key: `global.${CUSTOMER_TYPES[selectedStoryHighlight?.customerType]}`,
                      data: microCopyData
                    })
                  : ''
              }
              prefaceBelow={false}
              media={
                selectedStoryHighlight.video ? (
                  <Wistia
                    key={selectedStoryHighlight.video.videoId}
                    videoId={selectedStoryHighlight.video.videoId}
                    videoSource={selectedStoryHighlight.video.videoSource}
                    thumbnail={
                      selectedStoryHighlight?.video?.thumbnail ||
                      selectedStoryHighlight.image ||
                      null
                    }
                    aspectRatioX={
                      getWistiaAspectRatio(selectedStoryHighlight?.video?.aspectRatio).x
                    }
                    aspectRatioY={
                      getWistiaAspectRatio(selectedStoryHighlight?.video?.aspectRatio).y
                    }
                    cover={selectedStoryHighlight?.video?.cover}
                    autoPlay={selectedStoryHighlight?.video?.autoPlay}
                    controlsVisibleOnLoad={selectedStoryHighlight?.video?.controlsVisibleOnLoad}
                    muted={selectedStoryHighlight?.video?.muted}
                    transparentBackground={selectedStoryHighlight?.video?.transparentBackground}
                    popover={selectedStoryHighlight?.video?.popover}
                    hideControls={selectedStoryHighlight?.video?.hideControls}
                  />
                ) : (
                  <ImageFluid
                    className={'c-blog-top__image-highlight'}
                    image={selectedStoryHighlight.image}
                    loading={'eager'}
                  />
                )
              }
            >
              <ButtonNew
                variant={BUTTON_VARIANT.GREEN}
                url={`/${data.extra.slugPrefix}/customers/${selectedStoryHighlight.slug}`}
              >
                {getMicroCopy({ key: `global.readTestimonial`, data: microCopyData })}
              </ButtonNew>
            </HeroDual>

            <TestimonialsListing
              customers={customersToShow}
              slugPrefix={`${data.extra.slugPrefix}/`}
              limit={12}
            />
          </div>
        ) : null
      ) : (
        <section className="l-section u-margin-top:-16 u-border-radius-top u-background-color:white">
          <div className="l-container:12/12 l-container--default-spacing">
            <Loader />
          </div>
        </section>
      )}
    </>
  )
}

export default BlockCustomersTop
