import React from 'react'

//
import {
  Header as HeaderLMR,
  MenuDropdown,
  MenuList,
  MobileNav,
  HeaderContentBlock
} from '../../storybook'
import { HEADER_CONTENT_VARIANT } from '../../storybook/HeaderContentBlock/HeaderContentBlock.const'
import getButtonAction from '../../utils/get-button-action'
import { getMicroCopy } from '../../utils/microcopy'
import { getUrl } from '../../utils/urls'
import { LocationContext, MicroCopyContext } from '../page-wrapper'

const Header = ({
  headerData: { primaryAction, secondaryAction, mainNavigation, thirdAction },
  minimalHeader,
  minimalHeaderAction,
  isGlobalSite = true,
  langSlugs = {},
  marketsSlugs = {},
  allLangSlugs = [],
  hideTopBar = false
}) => {
  const [activeDropdown, setActiveDropdown] = React.useState(null)
  const [isNavOpen, setIsNavOpen] = React.useState(false)

  const microCopyData = React.useContext(MicroCopyContext)
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  const handleDropdown = (id) => {
    setActiveDropdown(id)
  }

  const toggleIsNavOpen = () => {
    setIsNavOpen(!isNavOpen)
  }

  const primeAction = getButtonAction(primaryAction, slugPrefix)
  const secAction = getButtonAction(secondaryAction, slugPrefix)
  const trdAction = thirdAction ? getButtonAction(thirdAction, slugPrefix) : null

  const mainNavigationItems =
    mainNavigation?.map((item) => {
      const url = item.navigationItem ? getUrl(item.navigationItem, slugPrefix) : null
      const navItem = {
        label: item.title,
        dropdown: item.dropdown || null,
        id: item.id,
        navigationItem: url || null
      }

      item.dropdown ? (navItem.id = item.id) : (navItem.url = url)

      return navItem
    }) || []

  const mobileNavigationItems = mainNavigationItems.map((item) => {
    return {
      label: item.label,
      url: item.url ? item.url : null,
      id: `mobile-nav-${item.id}`,
      items: item.dropdown
        ? item.dropdown.contentBlocks.filter((item) => {
            return item.__typename === 'ContentfulHeaderNavigationBlock'
          })
        : null
    }
  })

  secondaryAction &&
    isGlobalSite &&
    mobileNavigationItems.push({
      label: getMicroCopy({ key: 'global.headerNavSignInTitle', data: microCopyData }),
      action: {
        label: secondaryAction?.title,
        url: getUrl(secondaryAction, slugPrefix)
      },
      id: 'mobile-headerNavSignInTitle',
      icon: 'topic/graph'
    })

  const createContentBlock = (blockData) => {
    const {
      __typename,
      id,
      title = '',
      description = '',
      logo = null,
      illustration = null,
      navigationItems = [],
      action = null,
      content = null,
      variant = HEADER_CONTENT_VARIANT.grey
    } = blockData

    switch (__typename) {
      case 'ContentfulHeaderContentBlock':
        return (
          <HeaderContentBlock
            title={title}
            content={content}
            key={id}
            componentType="summary"
            variant={HEADER_CONTENT_VARIANT[variant]}
            action={{ label: action?.title || '', url: action ? getUrl(action, slugPrefix) : '' }}
          />
        )

      case 'ContentfulHeaderNavigationBlock':
        return (
          <MenuList
            logo={logo}
            title={title}
            illustration={illustration}
            description={description}
            items={
              navigationItems?.map((item) => ({
                id: `menu-item-${item.id}`,
                label: item.title || null,
                subtext: item.subtext || null,
                url: getUrl(item, slugPrefix),
                icon: item.icon,
                image: item.image,
                background: item.background,
                logo: item.logo
              })) || []
            }
            key={id}
          />
        )

      default:
        return <></>
    }
  }

  const mobileNav = (
    <MobileNav
      key={'mobile-nav'}
      isOpen={isNavOpen}
      actionTitle={getMicroCopy({ key: 'global.headerNavActionTitle', data: microCopyData })}
      action={primeAction}
      items={mobileNavigationItems}
      hideTopBar={hideTopBar}
      primaryAction={primeAction}
      secondaryAction={secAction}
      thirdAction={trdAction}
    />
  )

  const dropDowns = [
    ...mainNavigationItems
      .map((item) => {
        if (item.id) {
          return (
            <MenuDropdown
              key={item.id}
              visible={activeDropdown === item.id}
              bottomContent={item.dropdown?.dropdownBottomText || null}
            >
              {item.dropdown?.contentBlocks?.map((block) => createContentBlock(block)) || null}
            </MenuDropdown>
          )
        }
        return null
      })
      .filter((dropdown) => dropdown),
    mobileNav
  ]

  if (minimalHeader) {
    return (
      <HeaderLMR
        logo={{ url: slugPrefix.replace(/\/$/, '') }}
        activeDropdown={null}
        handleDropdown={() => {}}
        items={null}
        primaryAction={null}
        toggleNav={toggleIsNavOpen}
        isNavOpen={isNavOpen}
        slugPrefix={slugPrefix}
        langSlugs={langSlugs}
        allLangSlugs={allLangSlugs}
        marketsSlugs={marketsSlugs}
        hideTopBar={hideTopBar}
        secondaryAction={secAction}
        isMinimalHeader={minimalHeader}
        minimalHeaderAction={getButtonAction(minimalHeaderAction, slugPrefix)}
      >
        {[mobileNav]}
      </HeaderLMR>
    )
  }

  return mainNavigationItems.length ? (
    <HeaderLMR
      logo={{ url: slugPrefix.replace(/\/$/, '') }}
      activeDropdown={activeDropdown}
      handleDropdown={handleDropdown}
      items={mainNavigationItems}
      toggleNav={toggleIsNavOpen}
      slugPrefix={slugPrefix}
      langSlugs={langSlugs}
      allLangSlugs={allLangSlugs}
      marketsSlugs={marketsSlugs}
      isNavOpen={isNavOpen}
      hideTopBar={hideTopBar}
      primaryAction={primeAction}
      secondaryAction={secAction}
      thirdAction={trdAction}
      minimalHeaderAction={null}
    >
      {dropDowns}
    </HeaderLMR>
  ) : null
}

export default Header
