import React from 'react'

import Button from '../Button'

const FooterCallMenu = ({ callMenu, isEmptyCallMenu }) => {
  if (isEmptyCallMenu) return null

  return (
    <div className="l-grid__col:12/12 l-grid__col:6/12@at:viewport-4 l-grid__col:12/12@at:viewport-12">
      <div className="c-footer__language-call-mobile">
        <h4 className="c-footer-menu__title">{callMenu.title}</h4>

        {callMenu.tel &&
          (Array.isArray(callMenu.tel) ? (
            callMenu.tel.map((tel) => (
              <div
                key={`${tel.signpost}_${tel.tel}`.replace(' ', '')}
                className="c-footer__language-call-link__wrapper--mobile"
              >
                <p>{tel.signpost}</p>
                <Button
                  classes="c-footer__language-call-link"
                  url={`tel:${tel.tel?.replace(/\s/g, '')}`}
                  icon="interface/arrow-right"
                  secondary
                >
                  {tel.tel}
                </Button>
              </div>
            ))
          ) : (
            <Button
              classes="c-footer__language-call-link"
              url={`tel:${callMenu.tel?.replace(/\s/g, '')}`}
              icon="interface/arrow-right"
              secondary
            >
              {callMenu.tel}
            </Button>
          ))}
      </div>

      <div className="c-footer-menu c-footer__language-call-desktop">
        <h4 className="c-footer-menu__title">{callMenu.title}</h4>

        {callMenu.tel &&
          (Array.isArray(callMenu.tel) ? (
            callMenu.tel.map((tel) => (
              <div
                key={`${tel.signpost}_${tel.tel}`.replace(' ', '')}
                className="c-footer__language-call-link__wrapper"
              >
                <p>{tel.signpost}</p>
                <a href={`tel:${tel.tel?.replace(/\s/g, '')}`}>{tel.tel}</a>
              </div>
            ))
          ) : (
            <a href={`tel:${callMenu.tel?.replace(/\s/g, '')}`}>{callMenu.tel}</a>
          ))}
      </div>
    </div>
  )
}

export default FooterCallMenu
