import cn from 'classnames'
import React, { useContext } from 'react'

//
import { SiteDataContext } from '../../components'
import RichText from '../../components/rich-text'
import IconNew from '../IconNew'

import { LIST_TYPE } from './List.const'

const List = ({
  type = LIST_TYPE.ordered,
  icon,
  textLarge = false,
  inverted = false,
  items = [],
  classes = ''
}) => {
  const ListTag = type
  const { isTextRtl } = useContext(SiteDataContext)

  return (
    <div className="s-wysiwyg">
      <ListTag
        className={cn('c-list', classes, {
          'c-list--inverted': inverted,
          'c-list--rtl': isTextRtl
        })}
      >
        {items.map((item, i) => (
          <li
            key={item.id}
            className={cn('c-list__item', {
              'c-list__item--small-spacing': !icon && type !== LIST_TYPE.ordered
            })}
          >
            {(icon || type === LIST_TYPE.ordered) && (
              <div className="c-list__indicator">
                {type === LIST_TYPE.ordered ? i + 1 : <IconNew name={icon} size={20} />}
              </div>
            )}

            {item.text && (
              <div className="c-list__content">
                <RichText
                  classes={cn({
                    's-wysiwyg-editor--large': textLarge
                  })}
                  data={item.text}
                  tokens={item.textTokens}
                />
              </div>
            )}
          </li>
        ))}
      </ListTag>
    </div>
  )
}

export default List
