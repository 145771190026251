import cn from 'classnames'
import React, { useContext } from 'react'

//
import { SiteDataContext } from '../../components'
import RichText from '../../components/rich-text'
import ButtonNew from '../ButtonNew'
import { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
import Divider from '../Divider'
import RangeSlider from '../RangeSlider'
import { withAnchor } from '../hooks'

const PricingContent = ({
  title = '',
  preface = '',
  rangeSlider,
  dividerNumber,
  dividerText,
  price,
  priceText,
  description,
  descriptionTokens = {},
  actionLabel = '',
  actionProps = {},
  classes = ''
}) => {
  const { isTextRtl } = useContext(SiteDataContext)
  const dividerContent = {
    json: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'bold'
                }
              ],
              value: `${dividerNumber}`,
              nodeType: 'text'
            },
            {
              data: {},
              marks: [],
              value: ` ${dividerText}`,
              nodeType: 'text'
            }
          ],
          nodeType: 'paragraph'
        }
      ],
      nodeType: 'document'
    }
  }

  return (
    <div className={cn('c-pricing-content', classes, { 'c-pricing-content--rtl': isTextRtl })}>
      {rangeSlider && (
        <div className="c-pricing-content__container c-pricing-content__container-centered">
          <div className="c-pricing-content__container-slider c-pricing-content__container-centered">
            <p className="c-pricing-content__title">{title}</p>

            <RangeSlider
              min={rangeSlider.min}
              max={rangeSlider.max}
              defaultValue={rangeSlider.defaultValue}
              labels={rangeSlider.labels}
              getValue={rangeSlider.getValue}
            />
          </div>
        </div>
      )}

      {(dividerText || dividerNumber) && <Divider text={dividerContent} />}

      {price && (
        <div className="c-pricing-content__container">
          <div className="c-pricing-content__container-centered">
            {preface && <span className="c-pricing-content__preface">{preface}</span>}

            <h2 className="c-pricing-content__price">{price}</h2>

            <span className="c-pricing-content__price-text">{priceText}</span>
          </div>
        </div>
      )}

      {description && actionLabel && <Divider />}

      <div className="c-pricing-content__container">
        {description && (
          <RichText
            data={description}
            tokens={descriptionTokens}
            classes={cn('s-wysiwyg-editor', { 's-wysiwyg-editor--rtl': isTextRtl })}
          />
        )}

        {actionLabel && (
          <div className="c-pricing-content__action-container">
            <ButtonNew
              variant={BUTTON_VARIANT.GREEN}
              classes="c-pricing-content__action"
              {...actionProps}
            >
              {actionLabel}
            </ButtonNew>
          </div>
        )}
      </div>
    </div>
  )
}

export default withAnchor(PricingContent)
