import React from 'react'

//
import { FeatureSimpleBlock } from '../../../storybook'
import { getUrl } from '../../../utils/urls'
import { LocationContext } from '../../page-wrapper'

const BlockFeatureSimple = ({
  data: { title, preface, background, simpleContentBlocks = [], featureCardBlocks = [] }
}) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location
  let features = []

  if (!featureCardBlocks && simpleContentBlocks && simpleContentBlocks.length > 0) {
    features = simpleContentBlocks.map(
      ({ id, title, icon, description, action, bulletListColor }, i) => ({
        id: `${id}-${i}`,
        title: title || '',
        icon: icon || '',
        description: description || '',
        bulletListColor: bulletListColor || null,
        action: action
          ? {
              text: action.title || '',
              small: false,
              url: getUrl(action, slugPrefix)
            }
          : null
      })
    )
  }
  if (featureCardBlocks && featureCardBlocks.length > 0) {
    features = featureCardBlocks.map(
      ({ id, title, icon, image, description, action, bulletListColor }, i) => ({
        id: `${id}-${i}`,
        title: title || '',
        icon: icon || '',
        bulletListColor: bulletListColor || null,
        image: image || null,
        description: description || '',
        textTokens: {},
        action: action
          ? {
              text: action.title || '',
              small: false,
              url: getUrl(action, slugPrefix)
            }
          : null
      })
    )
  }

  return (
    <FeatureSimpleBlock
      title={title}
      eyebrow={preface}
      features={features}
      background={background}
    />
  )
}

export default BlockFeatureSimple
