import format from 'date-fns/format'
import fromUnixTime from 'date-fns/fromUnixTime'
import getUnixTime from 'date-fns/getUnixTime'
import de from 'date-fns/locale/de'
import enGB from 'date-fns/locale/en-GB'
import enUS from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'
import fr from 'date-fns/locale/fr'
import nl from 'date-fns/locale/nl'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useCallback, useEffect } from 'react'

//
import { Hero, HeroDual, Loader, Wistia, SelectedCategories, ButtonNew } from '../../../storybook'
import BlogFilters from '../../../storybook/BlogFilters'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { NavLink } from '../../../storybook/helpers'
import { getWistiaAspectRatio } from '../../../utils/common'
import { graphqlFetch } from '../../../utils/graphql-fetch'
import { ImageFluid } from '../../../utils/image'
import { getMicroCopy } from '../../../utils/microcopy'
import { renderRichTextLine } from '../../../utils/text'
import { LocationContext, MicroCopyContext } from '../../page-wrapper'

import ArticlesListing from './articles'

export const validLocales = { en: enGB, de: de, fr: fr, es: es, nl: nl, us: enUS }

const query = `
  query($locale: String!) {
    articleCollection(locale: $locale, limit: 250, order:articlePublishedDate_DESC) {
      items {
        sys {
          id
        }
        title
        articlePublishedDate
        description
        slug
        readingTime
        image {
          url
          height
          width
        }
        video {
          sys {
            id
          }
          videoId
          videoSource
        }
        categoriesCollection(locale: $locale, limit: 20) {
          items {
            sys {
              id
            }
            name
            slug
          }
        }
        enabledLocales
        enabledMarketsCollection(locale: $locale, limit: 20) {
          items {
            sys {
              id
            }
            title
            slugPrefix
          }
        }
      }
    }
  }
`

const BlockBlogTop = ({ data, data: { topTitle, topDescription, articleHighlight } }) => {
  const result = useStaticQuery(
    graphql`
      query AllArticlesCategories {
        allContentfulArticleCategory {
          group(field: node_locale) {
            locale: fieldValue
            edges {
              node {
                id: contentful_id
                slug
                name
                locale: node_locale
                order
              }
            }
          }
        }
      }
    `
  )

  const allCategories = []
  result?.allContentfulArticleCategory?.group?.forEach((group) => {
    if (group?.locale === data.extra.locale)
      group.edges
        .map((edge) => edge.node)
        .forEach((node) => {
          const { id, slug, name, locale, order } = node
          allCategories.push({ id, slug, name, locale, order, selected: false })
        })
  })

  const [isLoading, setIsLoading] = React.useState(true)
  const [articles, setArticles] = React.useState(null)
  const [filteredArticles, setFilteredArticles] = React.useState(null)
  const [categories, setCategories] = React.useState(allCategories)

  const microCopyData = React.useContext(MicroCopyContext)
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  const articleHighlightToShow =
    articleHighlight?.enabledLocales === null || articleHighlight?.enabledLocales === true
      ? articleHighlight
      : false

  const fetchArticles = (ignore) => {
    const variables = {
      locale: data.extra.locale
    }

    const currentSlugPrefix = (slugPrefix || '').replace(/\//gim, '')

    let locale = currentSlugPrefix.split('-')[0] || 'en'
    if (!validLocales[locale]) {
      locale = 'en'
    }

    graphqlFetch(query, variables)
      .then((res) => {
        const articlesFetched =
          res?.data?.articleCollection?.items
            ?.filter((node) => data.extra.allPublishedArticles[node.sys.id])
            ?.map((article) => {
              const timestamp = getUnixTime(new Date(article.articlePublishedDate))
              const date = fromUnixTime(timestamp)
              return {
                ...article,
                timestamp,
                date: format(date, 'MMMM d, y', { locale: validLocales[locale] }),
                categories: article?.categoriesCollection?.items
              }
            }) || []

        const articlesToSet = articlesFetched
          ?.filter((article) => article?.slug !== articleHighlightToShow?.slug)
          ?.filter((article) => {
            return article.enabledLocales === null || article.enabledLocales === true
          })
          ?.filter((article) => {
            if (
              article?.enabledMarketsCollection?.items === null ||
              (Array.isArray(article?.enabledMarketsCollection?.items) &&
                article?.enabledMarketsCollection?.items.length === 0)
            ) {
              return true
            }

            const slugPrefixes =
              article?.enabledMarketsCollection?.items?.map((i) => i.slugPrefix) || []
            return slugPrefixes.includes(currentSlugPrefix)
          })
        if (!ignore) {
          setArticles(articlesToSet)
          setIsLoading(false)
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }

  // PRE SELECT categories
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const categoriesString = urlParams.get('categories')

    const categoriesArray = categoriesString ? categoriesString.split(',') : []

    const updateCategories = categories.map((category) => {
      let selected = category.selected
      if (categoriesArray.includes(category.slug)) {
        selected = true
      }
      return { ...category, selected }
    })

    setCategories(updateCategories)
  }, [])

  useEffect(() => {
    let ignore = false
    fetchArticles(ignore)
    return () => {
      ignore = true
    }
  }, [])

  useEffect(() => {
    let selectedCategories

    if (categories) {
      selectedCategories = categories.filter((cat) => cat.selected)

      const newUrlParams = new URLSearchParams(window.location.search)
      let newUrl = `${window.location.pathname}`

      if (selectedCategories.length > 0) {
        newUrlParams.set('categories', selectedCategories.map((c) => c.slug).join(','))
        newUrl = `${window.location.pathname}?${newUrlParams.toString()}`
      }

      window.history.pushState({ path: newUrl }, '', newUrl)
    }

    const artCollection = articles?.filter((article) => {
      if (selectedCategories?.length) {
        return selectedCategories.some((sc) =>
          article?.categories.find((ac) => sc.slug === ac.slug)
        )
      } else {
        return true
      }
    })

    setFilteredArticles(artCollection || [])
  }, [categories, articles])

  const handleOnCategoryClick = useCallback(
    (categoryId) => {
      const selectedCategories = []
      const updateCategories = categories.map((category) => {
        let selected = category.selected
        if (category.id === categoryId) {
          selected = !category.selected

          if (selected === true) selectedCategories.push(category.slug)
        }
        return { ...category, selected }
      })
      setCategories(updateCategories)
    },
    [categories]
  )

  const handleClear = useCallback(() => {
    const updateCategories = categories.map((category) => {
      return { ...category, selected: false }
    })
    setCategories(updateCategories)
  }, [categories])

  return (
    <>
      <div className="c-blog-top__header">
        <Hero
          title={topTitle}
          background={'c-hero--bg-dark c-hero--bg-dark--no-img'}
          content={renderRichTextLine(topDescription)}
        />
        <BlogFilters categories={categories} setCategories={setCategories} />
      </div>
      <div className="c-blog-top__content">
        {categories.find((category) => category.selected === true) ? (
          <SelectedCategories
            totalArticles={(filteredArticles && filteredArticles.length) || 0}
            microCopyData={microCopyData}
            selectedCategories={categories.filter((category) => category.selected === true)}
            handleOnCategoryClick={handleOnCategoryClick}
            handleClear={handleClear}
          />
        ) : null}
        {articleHighlightToShow && (
          <HeroDual
            headingType="h3"
            classes="c-blogFilters__heroDual"
            title={articleHighlightToShow?.title}
            text={renderRichTextLine(articleHighlightToShow?.description?.description)}
            eyebrow={articleHighlightToShow?.categories?.map((cat) => cat.name)?.join(', ')}
            media={
              articleHighlightToShow?.video ? (
                <Wistia
                  videoId={articleHighlightToShow?.video?.videoId}
                  videoSource={articleHighlightToShow?.video?.videoSource}
                  thumbnail={
                    articleHighlightToShow?.video?.thumbnail || articleHighlight?.image || null
                  }
                  aspectRatioX={getWistiaAspectRatio(articleHighlightToShow?.video?.aspectRatio).x}
                  aspectRatioY={getWistiaAspectRatio(articleHighlightToShow?.video?.aspectRatio).y}
                  cover={articleHighlightToShow?.video?.cover}
                  autoPlay={articleHighlightToShow?.video?.autoPlay}
                  controlsVisibleOnLoad={articleHighlightToShow?.video?.controlsVisibleOnLoad}
                  muted={articleHighlightToShow?.video?.muted}
                  transparentBackground={articleHighlightToShow?.video?.transparentBackground}
                  popover={articleHighlightToShow?.video?.popover}
                  hideControls={articleHighlightToShow?.video?.hideControls}
                />
              ) : (
                <NavLink
                  to={`${slugPrefix}blog/${articleHighlight?.categories?.[0]?.slug}/${articleHighlight.slug}`}
                >
                  <ImageFluid
                    loading={'eager'}
                    image={articleHighlight?.image}
                    style={{ minHeight: '472px' }}
                    containImages
                  />
                </NavLink>
              )
            }
            greenEyebrow
          >
            <ButtonNew
              url={`${slugPrefix}blog/${articleHighlight?.categories?.[0]?.slug}/${articleHighlight.slug}`}
              variant={BUTTON_VARIANT.GREEN}
            >
              {getMicroCopy({ key: `global.readArticle`, data: microCopyData })}
            </ButtonNew>
          </HeroDual>
        )}
        {!isLoading ? (
          <>
            <ArticlesListing articles={filteredArticles} limit={12} slugPrefix={slugPrefix} />
          </>
        ) : (
          <section className="l-section">
            <div className="l-container:12/12 l-container--default-spacing">
              <Loader />
            </div>
          </section>
        )}
      </div>
    </>
  )
}

export default BlockBlogTop
