import React from 'react'

import { HeroHomeTwo } from '../index'

const HeroHomeBlockTwo = ({
  title = '',
  image = null,
  alternate = false,
  description = null,
  textTokens = {},
  children,
  wistiaVideo,
  customerTypesLabel,
  customerTypes,
  featureCardsCustomerTypes
}) => {
  return (
    <HeroHomeTwo
      title={title}
      description={description}
      textTokens={textTokens}
      alternate={alternate}
      image={image}
      wistiaVideo={wistiaVideo}
      customerTypesLabel={customerTypesLabel}
      customerTypes={customerTypes}
      featureCardsCustomerTypes={featureCardsCustomerTypes}
    >
      {children}
    </HeroHomeTwo>
  )
}

export default HeroHomeBlockTwo
