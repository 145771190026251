import React from 'react'

//
import { TeamMembersBlock } from '../../../storybook'
import { ImageFluid } from '../../../utils/image'

export const TeamMemberTextAlignment = Object.freeze({
  center: 'center',
  left: 'left',
  right: 'right'
})

const BlockTeamMembers = ({
  data: { title, preface, shortDescription, background, teamMembers, numberOfColumns, action }
}) => {
  const cards = teamMembers?.map(
    ({ id, name, role, profilePhoto, description, textAlignment }) => ({
      id,
      title: name || '',
      subtitle: role || '',
      image: profilePhoto && <ImageFluid image={profilePhoto} />,
      description: description?.description || '',
      textAlignment: textAlignment || TeamMemberTextAlignment.center
    })
  )

  return (
    <TeamMembersBlock
      title={title || ''}
      eyebrow={preface || ''}
      description={shortDescription || ''}
      background={background}
      inverted={background && background === 'dark'}
      items={cards}
      numberOfColumns={numberOfColumns || 4}
      action={action}
    />
  )
}

export default BlockTeamMembers
