import cn from 'classnames'
import React from 'react'

//
import ButtonNew from '../ButtonNew'
import { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
import HeroIntegrationVisual from '../HeroIntegrationVisual'
import { ALIGN, HEADING_TYPE } from '../SectionHeader'
import SectionHeader from '../SectionHeader/SectionHeader'
import { withAnchor } from '../hooks'

const ConditionalWrapper = ({ condition, wrapper, children }) => {
  if (condition) {
    return wrapper(children)
  }

  return children
}

const HeroIntegration = ({
  title = {},
  type = {},
  content = {},
  primaryButton,
  integrations = [],
  classes = {}
}) => {
  const HeroVisual = () => (
    <ConditionalWrapper
      condition={integrations.length === 0}
      wrapper={(children) => (
        <div className="l-grid l-grid--align:center l-grid--align:right@at:viewport-7">
          <div className="l-grid__col:12/12 l-grid__col:8/12@at:viewport-4 l-grid__col:12/12@at:viewport-7 l-grid__col:8/12@at:viewport-9 l-grid__col:6/12@at:viewport-12 l-grid__col--right-offset:1/12@at:viewport-7">
            {children}
          </div>
        </div>
      )}
    >
      <HeroIntegrationVisual integrations={integrations} />
    </ConditionalWrapper>
  )

  return (
    <section className={cn('l-section l-section--grey u-block-gap c-hero-integration', classes)}>
      <div className="l-container:12/12 l-container--default-spacing">
        <div className="l-grid l-grid--spacing:20 l-grid--align:middle">
          <div className="l-grid__col:12/12 l-grid__col:6/12@at:viewport-7">
            <SectionHeader
              eyebrow={type ? type.label : null}
              title={title || ''}
              text={content || ''}
              headingType={HEADING_TYPE.h1}
              align={ALIGN.center}
            >
              {primaryButton && (
                <div>
                  {primaryButton && (
                    <ButtonNew variant={BUTTON_VARIANT.GREEN} url={primaryButton.url}>
                      {primaryButton.label}
                    </ButtonNew>
                  )}
                </div>
              )}
            </SectionHeader>

            {integrations && integrations.length > 0 && (
              <div className="u-display:none@at:viewport-7 c-hero-integration__visual-mobile">
                <HeroVisual />
              </div>
            )}
          </div>

          <div className="u-display:none@to:viewport-7 l-grid__col:12/12 l-grid__col:6/12@at:viewport-7">
            {integrations && integrations.length > 0 && <HeroVisual />}
          </div>
        </div>
      </div>
    </section>
  )
}

export default withAnchor(HeroIntegration)
