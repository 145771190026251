import cn from 'classnames'
import React, { Children } from 'react'

//
import { SiteDataContext } from '../../components'
import RichText from '../../components/rich-text'

const HeroHome = ({
  title = '',
  visual = null,
  description = null,
  textTokens = {},
  alternate = false,
  children
}) => {
  const { isTextRtl } = React.useContext(SiteDataContext)

  return (
    <div
      className={cn('c-hero-home l-grid l-grid--align:middle', {
        'c-hero-home--alt': alternate,
        'c-hero-home--rtl': isTextRtl
      })}
    >
      <div className="l-grid__col:6/12 l-grid__col:12/12@to:viewport-9">
        <div className="l-container:12/12 l-container--default-spacing c-hero-home__content">
          <h1 className="c-hero-home__title">{title}</h1>

          <div className="c-hero-home__mobile-visual">{visual}</div>

          {description && (
            <div className="c-hero-home__description">
              <RichText data={description} tokens={textTokens} />
            </div>
          )}

          {children && (
            <div className="c-hero-home__actions">
              {Children.map(children, (child) => (
                <div className="c-hero-home__action">{child}</div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="l-grid__col:6/12 c-hero-home__desktop-visual">{visual}</div>
    </div>
  )
}

export default HeroHome
