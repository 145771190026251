import cn from 'classnames'
import React from 'react'

import { NavLink } from '../helpers'

function useScrollSpy(ids, options) {
  const [activeId, setActiveId] = React.useState()
  const observer = React.useRef()
  React.useEffect(() => {
    const elements = ids.map((id) => document.getElementById(id))
    observer.current?.disconnect()
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.id)
          return () => observer.current?.disconnect()
        }
      })
    }, options)
    elements.forEach((el) => {
      if (el) {
        observer.current?.observe(el)
      }
    })
    return () => observer.current?.disconnect()
  }, [ids, options])
  return activeId
}

const TableOfContent = ({ title, items }) => {
  const activeId = useScrollSpy(
    items.map(({ id }) => id),
    { rootMargin: '-20% 0% -25% 0%' }
  )

  return (
    <div className="c-table-content">
      {title ? (
        <>
          <span className="c-table-content__title">{title}</span>
          <hr></hr>
        </>
      ) : null}
      <ul>
        {items.map((item) => (
          <li
            key={item.id}
            className={cn('c-table-content__list-item', {
              'c-table-content__list-item--active': activeId === item.id
            })}
          >
            <NavLink
              to={`#${item.id}`}
              onClick={(e) => {
                e.preventDefault()
                document.querySelector(`#${item.id}`).scrollIntoView({
                  behavior: 'smooth'
                })
              }}
            >
              {item.text}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TableOfContent
