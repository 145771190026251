import React from 'react'

import { List, FormLayout1 as FormLayout, Loader, FormLayout2 } from '../../../storybook'
import useIsClient from '../../../storybook/hooks/is-client'
import useScript from '../../../utils/use-script'

export default function BlockHubSpotCalendar({
  data: {
    description,
    title = 'HubSpot Calendar',
    calendarUrl,
    jsScript = null,
    bulletPoints,
    headingType
  }
}) {
  const status = useScript(jsScript)
  const { isClient, key } = useIsClient()

  if (!isClient) {
    return <></>
  }

  if (!bulletPoints) {
    return (
      <FormLayout
        key={key}
        text={description}
        title={title}
        headingType={headingType || 'h2'}
        smallPadding
      >
        {calendarUrl && status === 'ready' ? (
          <iframe
            title={title}
            src={calendarUrl}
            width="100%"
            style={{ minWidth: 312, minHeight: 516, height: 756, border: 'none' }}
          ></iframe>
        ) : (
          <div className="l-container:12/12 l-container--default-spacing">
            <Loader />
          </div>
        )}
      </FormLayout>
    )
  }
  return (
    <FormLayout2
      key={key}
      text={description || ''}
      title={title || ''}
      headingType={headingType || 'h2'}
      smallPadding
      formPosition={'left'}
    >
      <FormLayout2.Form>
        {calendarUrl && status === 'ready' ? (
          <iframe
            title={title}
            src={calendarUrl}
            width="100%"
            style={{ minWidth: 312, minHeight: 516, height: 756, border: 'none' }}
          ></iframe>
        ) : (
          <div className="l-container:12/12 l-container--default-spacing">
            <Loader />
          </div>
        )}
      </FormLayout2.Form>
      <FormLayout2.Content>
        <List type="ol" items={bulletPoints.map((item) => ({ id: item.id, text: item.content }))} />
      </FormLayout2.Content>
    </FormLayout2>
  )
}
