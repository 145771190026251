import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useEffect } from 'react'

export default (el) => {
  useEffect(() => {
    // Add scrolltrigger plugin to gsap
    gsap.registerPlugin(ScrollTrigger)

    // Create trigger
    const trigger = ScrollTrigger.create({
      trigger: el.current,
      start: 'top bottom',
      toggleClass: 'is-playing'
    })

    // Remove trigger on cleanup
    return () => {
      trigger.kill()
    }
  }, [])
}
