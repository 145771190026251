import cn from 'classnames'
import React, { memo, useEffect } from 'react'

//
import { SiteDataContext } from '../../components'
import ButtonNew from '../ButtonNew'
import { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
import IconNew from '../IconNew'
import { NavLink } from '../helpers'

import SubNav from './MobileSubNav'

const MobileNav = ({
  items = [],
  actionTitle = '',
  primaryAction,
  secondaryAction,
  thirdAction,
  isOpen = false,
  hideTopBar,
  classes
}) => {
  const { isTextRtl } = React.useContext(SiteDataContext)
  const [isSubNavOpen, setSubNavOpen] = React.useState(false)
  const [subItems, setSubItems] = React.useState([])
  const [subItemsTitle, setSubItemsTitle] = React.useState('')

  useEffect(() => {
    if (!isOpen && isSubNavOpen) {
      resetSubNav()
    }
  }, [isOpen])

  const renderNavItem = (item) => {
    if (item.url) {
      return (
        <NavLink to={item.url}>
          <div className="c-mobile-nav__nav-item">
            <span className="c-mobile-nav__label">{item.label}</span>
            <IconNew name="tabler-chevron-right" classes={'c-mobile-nav__label--icon'} />
          </div>
        </NavLink>
      )
    }

    return (
      <div
        role="button"
        tabIndex={0}
        className="c-mobile-nav__nav-item"
        onClick={() => {
          if (item.items) {
            setSubNavData(item)
          }
        }}
        onKeyUp={() => {}}
      >
        <span className="c-mobile-nav__label">{item.label}</span>
        <IconNew name="tabler-chevron-right" classes={'c-mobile-nav__label--icon'} />
      </div>
    )
  }

  const setSubNavData = (data) => {
    setSubItems(data.items)
    setSubItemsTitle(data.label)
    setSubNavOpen(!isSubNavOpen)
  }

  const resetSubNav = () => {
    setSubNavOpen(false)
    setSubItems([])
    setSubItemsTitle('')
  }

  const renderAction = (item) => (
    <div className="l-container:12/12 l-container--default-spacing c-mobile-nav__item-content">
      {item.label}

      <ButtonNew
        url={item.action.url}
        icon="ti-arrow-narrow-right"
        variant={BUTTON_VARIANT.TERTIARY}
        size={'md'}
      >
        {item.action.label}
      </ButtonNew>
    </div>
  )

  return (
    <div
      className={cn('c-mobile-nav', hideTopBar && 'c-no-topbar', classes, {
        'is-open': isOpen,
        'c-mobile-nav--rtl': isTextRtl
      })}
    >
      <nav>
        <ul className="c-mobile-nav__list c-mobile-nav__item-container">
          {items.map((item, index) => (
            <li key={item.id}>
              {item && !item.action && renderNavItem(item, index)}
              {item.action && renderAction(item)}
            </li>
          ))}
        </ul>
        <div className="c-mobile-nav__actions">
          {primaryAction && (
            <ButtonNew fullWidth={true} variant="green" url={primaryAction.url}>
              <IconNew name={'tabler-circle-arrow-right'} />
              {primaryAction.label}
            </ButtonNew>
          )}
          {thirdAction && (
            <ButtonNew fullWidth={true} variant="secondary" url={thirdAction.url}>
              <IconNew name={'tabler-circle-arrow-right'} />
              {thirdAction.label}
            </ButtonNew>
          )}
        </div>
      </nav>
      <SubNav isOpen={isSubNavOpen} close={resetSubNav} subItems={subItems} title={subItemsTitle} />
    </div>
  )
}

export default memo(MobileNav)
