import cn from 'classnames'
import React, { useContext } from 'react'

import LogoBlackSvg from '../../../storybook-public/images/brand/deliverect_logo_black.svg'
import LogoGreenSvg from '../../../storybook-public/images/brand/deliverect_logo_green.svg'
import LogoWhiteSvg from '../../../storybook-public/images/brand/deliverect_logo_white.svg'
import { MicroCopyContext } from '../../components/page-wrapper'
import { getMicroCopy } from '../../utils/microcopy'
import { NavLink } from '../helpers'

const ConditionalWrapper = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : children
}

const getLogoVariant = (variant) => {
  switch (variant) {
    case 'black':
      return LogoBlackSvg
    case 'white':
      return LogoWhiteSvg
    default:
      return LogoGreenSvg
  }
}

const Logo = ({
  withText = false,
  inverted = false,
  size = 30,
  width = 168,
  anchorProps,
  link = '',
  classes = '',
  variant = 'green'
}) => {
  const microCopyData = useContext(MicroCopyContext)
  const logoPath = getLogoVariant(variant)

  const rootClasses = cn('c-logo', classes, {
    'c-logo--inverted': inverted,
    'c-logo--with-text': withText
  })

  const label = getMicroCopy({
    key: 'global.logo',
    fallback: 'Deliverect logo',
    data: microCopyData
  })

  return (
    <ConditionalWrapper
      condition={link}
      wrapper={(children) => (
        <NavLink to={link} {...anchorProps}>
          {children}

          <span className="c-logo__label">{label}</span>
        </NavLink>
      )}
    >
      {withText ? (
        <img className={rootClasses} height={size} width={width} src={logoPath} alt="Logo" />
      ) : (
        <svg
          className={rootClasses}
          height={size}
          width={width}
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.8816 3.7173V12.6592C20.8816 17.0777 17.3001 20.6592 12.8816 20.6592C8.46306 20.6592 4.88159 17.0777 4.88159 12.6592C4.88159 8.24066 8.46306 4.65919 12.8816 4.65919H13.7707V9.99279H12.8816C11.409 9.99279 10.2152 11.1866 10.2152 12.6592C10.2152 14.1318 11.409 15.3256 12.8816 15.3256C14.3542 15.3256 15.548 14.1318 15.548 12.6592V0.960694C14.6898 0.765652 13.7983 0.660004 12.8816 0.660004C6.2542 0.660004 0.881592 6.03262 0.881592 12.66C0.881592 19.2874 6.2542 24.66 12.8816 24.66C19.509 24.66 24.8816 19.2874 24.8816 12.66C24.8816 9.10698 23.3359 5.91559 20.8816 3.71811V3.7173Z"
            fill="currentColor"
          />
        </svg>
      )}
    </ConditionalWrapper>
  )
}

export default Logo
