import React from 'react'

import { BlockCrissCross } from '../..'
//
import { SectionHeader } from '../../../storybook'

const BlockSpotlight = ({
  data: { overlap, icon, title, preface, description, crisCrossItems }
}) => {
  return (
    <section className={`l-section ${overlap ? 'l-section--overlap' : ''}`}>
      {title && (
        <div className="l-section__header c-section-header--center">
          <div className="l-container:8/12 l-container:12/12@to:viewport-9 l-container--default-spacing">
            <SectionHeader
              icon={icon || ''}
              text={description || ''}
              title={title}
              eyebrow={preface}
              align="center"
            />
          </div>
        </div>
      )}

      <div className="l-section__content">
        {crisCrossItems &&
          crisCrossItems.map((item, idx) => (
            <BlockCrissCross key={`crisscross-${item.id}-${idx}`} data={item} />
          ))}
      </div>
    </section>
  )
}

export default BlockSpotlight
