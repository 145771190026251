import React from 'react'

//
import Filter from '../Filter'
import Summary from '../Summary'
import { withAnchor } from '../hooks'

const Category = ({ filters = [], itemsShown = 8, summary = null, children }) => {
  return (
    <div className="c-category">
      <div className="l-container:12/12 l-container--default-spacing@to:viewport-9">
        {filters.length > 0 &&
          filters.map((filter) => {
            const { id, title, type, items, showMoreLabel, showLessLabel } = filter

            return (
              <div key={`cat-item-${id}`} className="c-category__item">
                <Filter
                  itemsShown={itemsShown}
                  title={title}
                  showMoreLabel={showMoreLabel}
                  showLessLabel={showLessLabel}
                  type={type}
                  items={items}
                  inverted
                />
              </div>
            )
          })}

        {summary && (
          <div className="c-category__item">
            <Summary
              title={summary.title}
              classes={'c-category__summary'}
              description={summary.description}
              action={summary.action}
              inverted={summary.inverted}
            />
          </div>
        )}

        {children && <div className={summary.action ? 'c-category__item' : null}>{children}</div>}
      </div>
    </div>
  )
}

export default withAnchor(Category)
