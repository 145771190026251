import React from 'react'

//
import '../../scss/layouts/container.scss'
import '../../scss/layouts/flex.scss'
import '../../scss/layouts/grid.scss'
import '../../scss/main.scss'
import Footer from '../footer'
import Header from '../header'
import Metatags from '../metatags'

import CountrySwitcher from './country-switcher'

const Layout = ({
  children,
  metaTags,
  marketsSlugs = {},
  langSlugs = {},
  allLangSlugs = [],
  allMarkets = {},
  header = {},
  footerMainNavigation = [],
  footerSocialMedia = [],
  footerLegalNavigation = [],
  footerActions = [],
  marketPhoneNo = '',
  marketPhoneNo2 = '',
  minimalHeader = false,
  minimalFooter = false,
  footerText,
  countrySwitcher = true,
  isGlobalSite = true,
  minimalHeaderAction,
  location
}) => {
  return (
    <>
      <Metatags {...metaTags} langSlugs={langSlugs} location={location} />
      <Header
        headerData={header}
        minimalHeader={minimalHeader}
        isGlobalSite={isGlobalSite}
        marketsSlugs={marketsSlugs}
        allLangSlugs={allLangSlugs}
        langSlugs={langSlugs}
        hideTopBar={minimalFooter || minimalHeader}
        minimalHeaderAction={minimalHeaderAction}
      />
      <main>{children}</main>
      <Footer
        marketsSlugs={marketsSlugs}
        langSlugs={langSlugs}
        footerMainNavigation={footerMainNavigation}
        footerSocialMedia={footerSocialMedia}
        footerLegalNavigation={footerLegalNavigation}
        actions={footerActions}
        marketPhoneNo={marketPhoneNo}
        marketPhoneNo2={marketPhoneNo2}
        minimalFooter={minimalFooter}
        footerText={footerText}
      />
      {countrySwitcher ? (
        <CountrySwitcher marketsSlugs={marketsSlugs} allMarkets={allMarkets} />
      ) : null}
    </>
  )
}

export default Layout
