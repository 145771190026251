/**
 * @returns a result(number) greater/equal to min number or less than/equal to max number
 * i.e min >= result <= max
 */
export const getMinOrMaxValue = (max, min, value) => {
  if (value === '') {
    return ''
  }
  return Math.min(Math.max(+value, +min), +max)
}
