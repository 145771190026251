import cn from 'classnames'
import React from 'react'

import { IconNew } from '..'
import RichText from '../../components/rich-text'
import IconButton from '../IconButton'
import { NavLink } from '../helpers'
import { withAnchor } from '../hooks'

const Story = ({
  image,
  video,
  eyebrow = '',
  title = '',
  description = '',
  url = '',
  classes = '',
  date = '',
  readtime = '',
  icon = null
}) => {
  return (
    <NavLink to={url} className={cn('c-card', classes)}>
      {image && (
        <div className="c-card__image">
          {image}

          {video && (
            <div className="c-card__play">
              <IconButton icon="tabler-play" black />
            </div>
          )}
        </div>
      )}

      <div className="c-card__content">
        {eyebrow && <p className="c-card__content__eyebrow">{eyebrow}</p>}

        {title && (
          <div className="c-card__content__title-container">
            {icon}
            <h4 className="c-card__content__title">{title}</h4>
          </div>
        )}

        {description &&
          (typeof description === 'string' ? (
            <p className="c-card__description">{description}</p>
          ) : (
            <RichText data={description} />
          ))}
      </div>

      {(date || readtime || url) && (
        <div className="c-card__footer">
          <div className="c-card__footer__info">
            {readtime && <small>{readtime}</small>}
            {readtime && date ? <IconNew name={'ti-filled-point'} size={8} /> : null}
            {date && <small>{date}</small>}
          </div>
          <IconNew classes={'c-card__footer__arrow-icon'} name={'ti-arrow-narrow-right'} />
        </div>
      )}
    </NavLink>
  )
}

export default withAnchor(Story)
