export const IconsList = Object.freeze([
  'ti-brand-tabler',
  'ti-brand-facebook',
  'ti-brand-twitter',
  'ti-brand-instagram',
  'ti-message-circle',
  'ti-message-circle-2',
  'ti-message-circle-2-filled',
  'ti-message-circle-off',
  'ti-arrow-left',
  'ti-arrow-narrow-left',
  'ti-arrow-right',
  'ti-arrow-narrow-right',
  'ti-player-play-filled',
  'ti-player-stop-filled'
])
