export const filters = Object.freeze([
  {
    id: '0',
    title: 'Categories',
    showMoreLabel: 'Show more',
    showLessLabel: 'Show less',
    items: [
      {
        id: '0',
        icon: 'topic/ticket',
        label: 'POS systems',
        url: '#',
        active: false
      },
      {
        id: '1',
        icon: 'topic/online-ordering',
        label: 'Online ordering',
        url: '#',
        active: false
      }
    ]
  },
  {
    id: '1',
    title: 'Countries',
    showMoreLabel: 'Show more',
    showLessLabel: 'Show less',
    items: [
      {
        id: '1',
        img: '/images/icon-belgium.svg',
        label: 'Australia',
        url: '#',
        active: false
      },
      {
        id: '2',
        img: '/images/icon-belgium.svg',
        label: 'Belgium',
        url: '#',
        active: false
      },
      {
        id: '3',
        img: '/images/icon-belgium.svg',
        label: 'Canada',
        url: '#',
        active: false
      },
      {
        id: '4',
        img: '/images/icon-belgium.svg',
        label: 'Germany',
        url: '#',
        active: false
      },
      {
        id: '5',
        img: '/images/icon-belgium.svg',
        label: 'France',
        url: '#',
        active: false
      },
      {
        id: '6',
        img: '/images/icon-belgium.svg',
        label: 'Spain',
        url: '#',
        active: false
      },
      {
        id: '7',
        img: '/images/icon-belgium.svg',
        label: 'The Netherlands',
        url: '#',
        active: false
      },
      {
        id: '8',
        img: '/images/icon-belgium.svg',
        label: 'United states',
        url: '#',
        active: false
      },
      {
        id: '9',
        img: '/images/icon-belgium.svg',
        label: 'Canada',
        url: '#',
        active: false
      },
      {
        id: '10',
        img: '/images/icon-belgium.svg',
        label: 'Germany',
        url: '#',
        active: false
      }
    ]
  }
])
