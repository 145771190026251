import cn from 'classnames'
import React, { useContext } from 'react'

import { SiteDataContext } from '../../components'

const LogoGrid = ({ title = '', logos = [], classes }) => {
  const { isTextRtl } = useContext(SiteDataContext)
  return (
    <section className={cn('l-section c-logo-grid', classes, { 'c-logo-grid--rtl': isTextRtl })}>
      <div className="l-container:12/12 c-logo-grid__container">
        {title && (
          <div className="c-logo-grid__title-container">
            <h2 className="c-logo-grid__title">{title}</h2>
          </div>
        )}

        <div className="c-logo-grid__images">
          {logos.length > 0 &&
            logos.map((logo) => (
              <img
                loading="lazy"
                key={logo.id}
                src={logo.logo}
                title={logo.title}
                alt={logo.title}
                className="c-logo-grid__logo"
                width="72"
                height="72"
              />
            ))}
        </div>
      </div>
    </section>
  )
}

export default LogoGrid
