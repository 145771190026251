const GATSBY_NETLIFY_API = process.env.GATSBY_NETLIFY_API

export const graphqlFetch = (query, variables, env = null) => {
  const body = JSON.stringify({ query, variables, env })
  const fetchParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  }

  return fetch(GATSBY_NETLIFY_API, fetchParams).then((res) => {
    return res.json()
  })
}
