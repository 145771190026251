import cn from 'classnames'
import React, { forwardRef } from 'react'

//
import { SiteDataContext } from '../../components'
import { replaceSpaceWithDash } from '../../utils/replace-space-dash'
import Icon from '../Icon'
import { NavLink } from '../helpers'

const ConditionalWrapper = ({
  condition,
  videoCondition,
  trueWrapper,
  falseWrapper,
  videoWrapper,
  children
}) => {
  if (videoCondition) {
    return videoWrapper(children)
  }

  return condition ? trueWrapper(children) : falseWrapper(children)
}

const Button = (
  {
    url,
    icon = null,
    iconLeft = null,
    small = false,
    secondary = false,
    outline = false,
    black = false,
    inverted = false,
    linkedin = false,
    filter = false,
    fullWidth = false,
    opacity = false,
    border = false,
    spacingRight = false,
    spacingLeft = false,
    grey = false,
    transparent = false,
    neutral = false,
    videoId = '',
    anchorProps,
    buttonProps,
    classes = '',
    children,
    trackingId,
    ariaLabel,
    ...rest
  },
  ref
) => {
  const { isTextRtl } = React.useContext(SiteDataContext)

  const getTrackingId = () => {
    if (trackingId && typeof trackingId === 'string') return replaceSpaceWithDash(trackingId)
    return null
  }

  const rootClasses = cn('c-button', classes, {
    'c-button--small': small,
    'c-button--secondary': secondary,
    'c-button--outline': outline,
    'c-button--black': black,
    'c-button--inverted': inverted,
    'c-button--linkedin': linkedin,
    'c-button--filter-round': filter,
    'c-button--full-width': fullWidth,
    'c-button--disabled': rest?.disabled,
    'c-button--opacity': opacity,
    'c-button--border': border,
    'c-button--grey': grey,
    'c-button--spacing-right': spacingRight,
    'c-button--spacing-left': spacingLeft,
    'c-button--transparent': transparent,
    'c-button--neutral': neutral,
    'c-button--rtl': isTextRtl
  })

  const altIcon = icon && icon.includes('topic')

  return (
    <ConditionalWrapper
      condition={url}
      videoCondition={videoId}
      trueWrapper={(child) => (
        <NavLink
          trackingId={getTrackingId()}
          {...anchorProps}
          to={url}
          className={rootClasses}
          ref={ref}
        >
          {child}
        </NavLink>
      )}
      falseWrapper={(child) => (
        <button
          id={getTrackingId()}
          {...buttonProps}
          className={rootClasses}
          ref={ref}
          aria-label={ariaLabel}
          type="button"
          {...rest}
        >
          {child}
        </button>
      )}
    >
      {iconLeft && (
        <Icon
          classes={cn('c-button__icon', {
            'c-button__icon--alt': altIcon
          })}
          name={iconLeft}
        />
      )}
      {children}

      {icon && (
        <Icon
          classes={cn('c-button__icon', {
            'c-button__icon--alt': altIcon
          })}
          name={icon}
        />
      )}
    </ConditionalWrapper>
  )
}

export default forwardRef(Button)
