import React from 'react'
//

import { HeroHome } from '../HeroHome'

import { VisualComponent } from './VisualComponent'

const HeroHomeBlock = ({
  title = '',
  visualTitle = '',
  image = null,
  alternate = false,
  videoId = '',
  wistiaOptions = {},
  aspectRatio = {
    x: 16,
    y: 12
  },
  description = null,
  textTokens = {},
  systems = [],
  children,
  integrationsUrl = ''
}) => {
  const visualComponent = (
    <VisualComponent
      {...{ visualTitle, image, videoId, wistiaOptions, aspectRatio, systems, integrationsUrl }}
    />
  )

  return (
    <HeroHome
      title={title}
      description={description}
      textTokens={textTokens}
      alternate={alternate}
      visual={visualComponent}
    >
      {children}
    </HeroHome>
  )
}

export default HeroHomeBlock
