import cn from 'classnames'
import React from 'react'

import { SiteDataContext } from '../../components'
import SectionHeader from '../SectionHeader'
import Story from '../Story'
import { withAnchor } from '../hooks'
//

const Testimonials = ({ title, eyebrow = '', actionSlot, stories, footerSlot, classes = '' }) => {
  const { isTextRtl } = React.useContext(SiteDataContext)

  return (
    <section className={cn('l-section', classes, { 'c-testimonials--rtl': isTextRtl })}>
      <div className="l-container:12/12 l-container--default-spacing">
        {title && (
          <div className="l-section__header">
            <div className="l-container:8/12 u-margin-left:0">
              <SectionHeader title={title} eyebrow={eyebrow}>
                {actionSlot}
              </SectionHeader>
            </div>
          </div>
        )}

        {stories && (
          <div className="l-section__content">
            <div className="l-grid l-grid--default-spacing">
              {stories?.map((story) => {
                const { id, logo, image, title, actionLabel, name, type, url } = story

                return (
                  <Story
                    name={name}
                    type={type}
                    key={`story-${id}`}
                    logo={logo}
                    image={image}
                    title={title}
                    actionLabel={actionLabel}
                    url={url}
                    classes="c-story--spacing l-grid_col:12/12 l-grid__col:6/12@at:viewport-7 l-grid__col:4/12@at:viewport-9"
                  />
                )
              })}
            </div>
          </div>
        )}

        {footerSlot && <div className="l-section__footer">{footerSlot}</div>}
      </div>
    </section>
  )
}

export default withAnchor(Testimonials)
