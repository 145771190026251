import React from 'react'

//
import { ButtonNew, HeroHomeBlockTwo } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { getUrl } from '../../../utils/urls'
import { LocationContext } from '../../page-wrapper'

const BlockHeroHomeTwo = ({
  data: {
    title,
    description,
    image,
    wistiaVideo,
    alternate,
    primaryAction,
    secondaryAction,
    customerTypesLabel,
    customerTypes,
    featureCardsCustomerTypes
  }
}) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location
  return (
    <>
      <HeroHomeBlockTwo
        title={title || ''}
        description={description || ''}
        alternate={alternate}
        image={image || ''}
        wistiaVideo={wistiaVideo}
        customerTypesLabel={customerTypesLabel}
        customerTypes={customerTypes}
        featureCardsCustomerTypes={featureCardsCustomerTypes}
      >
        {primaryAction && (
          <ButtonNew
            trackingId={primaryAction?.trackingId}
            variant={BUTTON_VARIANT.GREEN}
            url={getUrl(primaryAction, slugPrefix)}
            subtext={primaryAction.subtext}
          >
            {primaryAction?.title || ''}
          </ButtonNew>
        )}
        {secondaryAction && (
          <ButtonNew
            trackingId={secondaryAction.trackingId}
            url={getUrl(secondaryAction, slugPrefix)}
            icon={secondaryAction?.icon || ''}
            variant={BUTTON_VARIANT.SECONDARY}
            subtext={secondaryAction.subtext}
          >
            {secondaryAction?.title || ''}
          </ButtonNew>
        )}
      </HeroHomeBlockTwo>
    </>
  )
}

export default BlockHeroHomeTwo
