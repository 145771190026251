import React from 'react'

import { getFeatureCards } from '../../../helpers/getFeatureCards'
//
import { ButtonNew, ThreeCardsWithImage } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { getUrl } from '../../../utils/urls'
import { LocationContext } from '../../page-wrapper'

const Block3CardsWithImage = ({ data: { title, preface, featureCards, action, theme, image } }) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  const features = getFeatureCards(featureCards, slugPrefix)

  return (
    <ThreeCardsWithImage
      {...{ title, eyebrow: preface, theme, features, image }}
      action={
        action && (
          <ButtonNew
            trackingId={action?.trackingId}
            url={getUrl(action, slugPrefix)}
            classes="c-cards-with-image__header-action"
            variant={theme === 'light' ? BUTTON_VARIANT.PRIMARY : BUTTON_VARIANT.GREEN}
            icon="ti-arrow-narrow-right"
          >
            {action?.title}
          </ButtonNew>
        )
      }
    ></ThreeCardsWithImage>
  )
}

export default Block3CardsWithImage
