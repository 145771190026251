import cn from 'classnames'
import React, { useEffect, useState, useRef } from 'react'

//
import Dropdown from '../Dropdown'
import { useResize } from '../hooks'

const ToggleGroup = ({ activeValue = '', items = [], handleToggle = () => null, classes }) => {
  const [indicatorStyles, setIndicatorStyles] = useState({})
  const [activeLabel, setActiveLabel] = useState(items[0].label)
  const rootEl = useRef(null)

  const handleIndicator = () => {
    const activeEl = rootEl.current.querySelector('.is-active')

    if (!activeEl) {
      return
    }

    const transformValue = activeEl.offsetLeft - 8

    setIndicatorStyles({
      transform: `translate3d(${transformValue < 0 ? 0 : transformValue}px, 0, 0)`
    })
    setActiveLabel(activeEl.innerText)
  }

  const handleClick = (value, label) => {
    handleToggle(value)

    setTimeout(() => {
      setActiveLabel(label)
    }, 125)
  }

  useResize(handleIndicator)

  useEffect(() => {
    setTimeout(() => {
      handleIndicator()
    }, 200)
  }, [])

  useEffect(() => {
    handleIndicator()
  }, [activeValue])

  return (
    <>
      <div ref={rootEl} className={cn('c-toggle-group__desktop', classes)}>
        {items.length > 0 &&
          items.map((item) => (
            <button
              key={item.id}
              type="button"
              onClick={() => handleClick(item.value, item.label)}
              className={cn('c-toggle-group__button', {
                'is-active': item.value === activeValue
              })}
            >
              {item.label}
            </button>
          ))}

        <button
          className="c-toggle-group__button c-toggle-group__indicator"
          style={indicatorStyles}
        >
          {activeLabel}
        </button>
      </div>

      <div className={cn('c-toggle-group__mobile', classes)}>
        <Dropdown items={items} value={activeValue} handleSelect={handleToggle} secondary />
      </div>
    </>
  )
}

export default ToggleGroup
