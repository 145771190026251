import { isEmpty } from 'lodash'

import { getCookie, setCookie } from './cookies'

export const UTM_PARAMS_COOKIE_NAME = 'utm'

export function getUTMQueryParamsFromURL(queryString) {
  const urlSearchParams = new URLSearchParams(queryString)
  const allParams = Object.fromEntries(urlSearchParams.entries())
  const allowedUTMParameters = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term'
  ]

  return Object.keys(allParams)
    .filter((param) => allowedUTMParameters.includes(param))
    .reduce((obj, param) => {
      return {
        ...obj,
        [param]: allParams[param]
      }
    }, {})
}

export function setUTMParamsAsCookie(queryString) {
  const utmParams = getUTMQueryParamsFromURL(queryString)

  if (!isEmpty(utmParams)) {
    setCookie(UTM_PARAMS_COOKIE_NAME, JSON.stringify(utmParams), {
      expires: 0.5,
      domain: '.deliverect.com'
    })
  }
}

export function extendUrlByUtmParams(url, sendUtmParams) {
  const utmParams = getCookie(UTM_PARAMS_COOKIE_NAME)

  if (!sendUtmParams || !url || !utmParams) {
    return url
  }

  const concatenateSymbol = url.includes('?') ? '&' : '?'

  return url.concat(concatenateSymbol, new URLSearchParams(utmParams).toString())
}
