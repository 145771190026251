import React from 'react'

import NewButton from '../ButtonNew'

export const HeaderAction = ({ action, secondary = true }) => {
  let buttonVariant = 'green'

  if (secondary) buttonVariant = 'secondary'

  if (action && action?.label)
    return (
      <>
        {action?.url && (
          <NewButton url={action?.url} variant={buttonVariant} size={'md'}>
            {action?.label}
          </NewButton>
        )}
      </>
    )
  return null
}
