import React from 'react'

//
import { Kpi, Button, List } from '../../../storybook/'
import { getUrl } from '../../../utils/urls'
import { LocationContext } from '../../page-wrapper'

const BlockTwoBlocksBanner = ({ data: { simpleContentBlocks } }) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  return (
    <section className="l-section">
      <div className="l-container:12/12 l-container--default-spacing">
        <div className="l-section__content">
          <div className="l-grid l-grid--default-spacing">
            {simpleContentBlocks?.map(
              ({ id, icon, title, description, bulletPoints, action }, idx1) => (
                <div
                  key={`${id}-${idx1}`}
                  className="l-grid_col:12/12 l-grid__col:6/12@at:viewport-7"
                >
                  <Kpi icon={icon || ''} title={title || ''} description={description || ''}>
                    {bulletPoints && (
                      <List
                        type="ul"
                        icon="interface/checkmark"
                        items={bulletPoints.map((bulletPoint, idx2) => ({
                          id: `${bulletPoint.id}-${idx2}`,
                          textTokens: {},
                          text: bulletPoint.content
                        }))}
                      />
                    )}

                    {action && (
                      <Button
                        url={getUrl(action, slugPrefix)}
                        icon="interface/arrow-right"
                        secondary
                        classes={bulletPoints && 'u-margin-top:24'}
                      >
                        {action.title}
                      </Button>
                    )}
                  </Kpi>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockTwoBlocksBanner
