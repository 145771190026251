import { navigate } from 'gatsby'
import React, { useEffect } from 'react'

import { getFilterIntegrations } from '../../../helpers/getFilterIntegrations'
//
import { ButtonNew, FilterOverview, Kpi, Search, SearchInput } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import Hero from '../../../storybook/Hero/Hero'
import { getMicroCopy } from '../../../utils/microcopy'
import { getUrl } from '../../../utils/urls'
import { LocationContext, MicroCopyContext, SiteDataContext } from '../../page-wrapper'
import BlockCrissCross from '../crissCross'
import BlockSectionHeader from '../sectionHeader'

import IntegrationsList from './IntegrationsList'

const Filter = ({ data }) => {
  const { isTextRtl } = React.useContext(SiteDataContext)
  const location = React.useContext(LocationContext)
  const microCopyData = React.useContext(MicroCopyContext)
  const integrations = data.extra.integrations

  const orderedCategories =
    data?.categories?.map((cat) => {
      return data?.extra?.categories?.find((extCat) => extCat.id === cat.id)
    }) || data?.extra?.categories

  // state for search / filtering
  const [value, setValue] = React.useState('')
  const [results, setResults] = React.useState('')
  const [categories] = React.useState(orderedCategories)

  const [filteredIntegrations, setFilteredIntegrations] = React.useState([])
  const [filters, setFilters] = React.useState([])
  const [categorySelected, setCategorySelected] = React.useState('')
  const [marketSelected, setMarketSelected] = React.useState('')

  const handleSelect = (val, id) => {
    const selectedFilterIndex = filters.findIndex((filter) => filter.id === id)

    const newActiveItem = filters[selectedFilterIndex].items.find((item) => item.value === val)
    if (newActiveItem.countryIsoCode) {
      setMarketSelected(newActiveItem.countryIsoCode)
      navigate(newActiveItem.url)
    }
    if (newActiveItem.categorySlug) {
      setCategorySelected(newActiveItem.categorySlug)
      navigate(newActiveItem.url)
    }
  }

  const updateFilters = () => {
    // defining filters array
    let selectedCategoryValue = ''
    let selectedCountryValue = ''
    const filtersArr = [
      {
        id: '0',
        title: getMicroCopy({ key: 'integration.categories', data: data.extra.microCopy }),
        showMoreLabel: getMicroCopy({ key: 'integration.showMore', data: data.extra.microCopy }),
        showLessLabel: getMicroCopy({ key: 'integration.showLess', data: data.extra.microCopy }),
        value: '',
        items: [
          {
            id: '0',
            label: getMicroCopy({ key: 'integration.allIntegrations', data: data.extra.microCopy }),
            url: `/${data.extra.slugPrefix}/integrations`,
            active: !data.extra.categorySlug || data.extra.categorySlug === 'integrations',
            categorySlug: 'integrations',
            value: getMicroCopy({ key: 'integration.allIntegrations', data: data.extra.microCopy })
          },

          ...categories
            .filter((node) => {
              return node.locale === data.extra.locale
            })
            .map((node) => {
              const active = node.slug === data.extra.categorySlug
              if (active) {
                selectedCategoryValue = node.title
              }
              return {
                id: node.id,
                label: node.title,
                url: `/${data.extra.slugPrefix}/integrations/${node.slug}${location.hash}`,
                active,
                categorySlug: node.slug,
                value: node.title
              }
            })
        ]
      },
      {
        id: '1',
        title: getMicroCopy({ key: 'integration.countries', data: data.extra.microCopy }),
        showMoreLabel: getMicroCopy({ key: 'integration.showMore', data: data.extra.microCopy }),
        showLessLabel: getMicroCopy({ key: 'integration.showLess', data: data.extra.microCopy }),
        value: '',
        items: [
          {
            id: '0',
            label: getMicroCopy({ key: 'integration.allCountries', data: data.extra.microCopy }),
            url: `${data.extra.currentUrl}`,
            countryIsoCode: 'ALL',
            active: !marketSelected,
            value: '0'
          },
          ...Object.keys(data.extra.allMarkets)
            .filter((key) => key !== 'ALL')
            .map((key) => {
              const active = key === marketSelected || false

              if (active) {
                selectedCountryValue = key
              }

              return {
                id: key,
                label: getMicroCopy({
                  key: `integration.market.${key.toLocaleLowerCase()}`,
                  data: microCopyData
                }),
                value: key,
                url: `${data.extra.currentUrl}#${key}`,
                countryIsoCode: key,
                active
              }
            })
        ]
      }
    ]

    filtersArr[0].value = selectedCategoryValue
    filtersArr[1].value = selectedCountryValue || '0'
    filtersArr[1].items.sort((a, b) =>
      a.label > b.label || a.countryIsoCode === 'XX' ? 1 : b.label > a.label ? -1 : 0
    )

    setFilters(filtersArr)

    if (integrations) {
      // filtering integrations
      const filtered = integrations
        .filter((node) => {
          // filtering out integrations that are not part of selected category
          const arrCategories =
            node.categoriesCollection &&
            Array.isArray(node.categoriesCollection.items) &&
            node.categoriesCollection.items.map((cat) => cat.slug)

          if (!data.extra.categorySlug || data.extra.categorySlug === 'integrations') {
            return true
          }

          return arrCategories.includes(data.extra.categorySlug)
        })
        .filter((node) => {
          // filtering out integrations that are not part of selected market (country)
          const arrCountries =
            node.marketsCollection &&
            Array.isArray(node.marketsCollection.items) &&
            node.marketsCollection.items.map((market) => market?.countryIsoCode)

          if (!marketSelected) {
            return true
          }

          return arrCountries.includes(marketSelected)
        })
      setFilteredIntegrations(filtered)
    }
  }

  // Fetching all integrations on load
  useEffect(() => {
    setMarketSelected(location.hash.replace('#', ''))

    setCategorySelected(
      data.extra.categorySlug && data.extra.categorySlug !== 'integrations'
        ? data.extra.categorySlug
        : ''
    )
  }, [location])

  useEffect(() => {
    updateFilters()
  }, [categorySelected, marketSelected])

  useEffect(() => {
    updateFilters()
  }, [integrations])

  useEffect(() => {
    if (!location.hash) {
      const autoSelectedCountry = location?.country?.toUpperCase()
      setMarketSelected(autoSelectedCountry)
      location.hash = '#' + autoSelectedCountry
    }
  }, [])

  const searchIntegration = (val) => {
    // search for integrations that contains the string typed
    const result = integrations.filter((node) => {
      const { title } = node

      // lowercasing strgins before comparing them
      const currentTitle = (title || '').toLowerCase()

      return currentTitle.includes((val || '').toLowerCase())
    })

    // sorting results, showing integrations that starts with the typed string first
    result.sort((a, b) => {
      const titleA = a?.title.toLowerCase()
      const titleB = b?.title.toLowerCase()

      if (titleA.startsWith(val.toLowerCase()) && titleB.startsWith(val.toLowerCase())) {
        return 0
      } else {
        if (titleA.startsWith(val.toLowerCase())) {
          return -1
        }
        if (titleB.startsWith(val.toLowerCase())) {
          return 1
        }
      }
      return 0
    })

    return result
  }

  const updateValue = (val) => {
    if (integrations === null) {
      return
    }
    // get list of integrations that match typed string
    const resultArr = searchIntegration(val)

    // update state with new string and results
    setValue(val)
    setResults(
      resultArr.map((node) => {
        const { id, logo, slug, title } = node

        return {
          label: title,
          id: id,
          url: `/${data.extra.slugPrefix}/integrations/${slug}`,
          logo: logo || ''
        }
      })
    )
  }

  // Get all integrations that have a 'comingSoon' or 'voteNow' label
  const notActiveIntegrations =
    integrations && Array.isArray(integrations)
      ? integrations.filter((node) => node.label === 'comingSoon' || node.label === 'voteNow')
      : []

  const slug = data?.extra?.categorySlug
  const getIntegrationsWithSlug = getFilterIntegrations(categories, slug, 'integrations')

  const heroTitle = getIntegrationsWithSlug('pageTitle', data?.title)
  const heroContent = getIntegrationsWithSlug('introCopy', data?.description)
  const bodyContent = getIntegrationsWithSlug('bodyCopy')
  const bodyTitle = getIntegrationsWithSlug('bodyTitle') || ''
  const crissCrosses = getIntegrationsWithSlug('crissCrosses')
  const titleCrissCrosses = getIntegrationsWithSlug('titleCrissCrosses')
  const variant = bodyContent ? 'small' : ''

  return (
    <>
      <Hero title={bodyTitle || heroTitle} content={heroContent}>
        <Search
          value={value}
          results={results}
          emptyState={
            <Kpi
              align={'center'}
              {...{
                title: data?.smallBlock?.title || '',
                description: data.smallBlock?.description || ''
              }}
            >
              <ButtonNew
                size={'md'}
                variant={BUTTON_VARIANT.TERTIARY}
                icon={'ti-arrow-narrow-right'}
                url={`/${data.extra.slugPrefix}/${data.smallBlock?.action?.content?.[0]?.slug}`}
              >
                {data?.smallBlock?.action?.title || ''}
              </ButtonNew>
            </Kpi>
          }
        >
          <SearchInput
            placeholder={getMicroCopy({
              key: 'integration.searchInputPlaceholder',
              data: data.extra.microCopy
            })}
            searchLabel={getMicroCopy({
              key: 'integration.searchInputLabel',
              data: data.extra.microCopy
            })}
            handleSearch={updateValue}
          />
        </Search>
      </Hero>
      <section
        dir={isTextRtl ? 'rtl' : 'ltr'}
        data-variant={variant}
        className="l-section filter-overview"
      >
        <div className="l-container:12/12 l-container--default-spacing">
          <div className="l-grid l-grid--default-spacing">
            <div className="l-grid__col:3/12 l-grid__col:12/12@to:viewport-7 l-grid__col:4/12@to:viewport-9">
              <FilterOverview filters={filters} handleSelect={handleSelect} />
            </div>

            <div className="l-grid__col:9/12 l-grid__col:12/12@to:viewport-7 l-grid__col:8/12@to:viewport-9">
              {!categorySelected && (
                <h5 style={{ marginBottom: '1.75rem' }}>
                  {getMicroCopy({
                    key: 'integration.activeIntegrations',
                    data: data.extra.microCopy
                  })}
                </h5>
              )}

              <div>
                {filteredIntegrations.length > 0 ? (
                  <IntegrationsList
                    integrations={
                      !categorySelected
                        ? filteredIntegrations.filter((node) => {
                            // filtering out integrations that have a label set to comingSoon or voteNow
                            return node.label !== 'comingSoon' && node.label !== 'voteNow'
                          })
                        : filteredIntegrations
                    }
                    slugPrefix={data.extra.slugPrefix}
                    labels={{
                      paginationLoadMore: getMicroCopy({
                        key: 'integration.paginationLoadMore',
                        data: data.extra.microCopy
                      }),
                      paginationOf: getMicroCopy({
                        key: 'integration.paginationOf',
                        data: data.extra.microCopy
                      })
                    }}
                  />
                ) : (
                  <p>
                    {getMicroCopy({
                      key: 'integration.noResultsMessage',
                      data: data.extra.microCopy
                    })}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {!categorySelected && (
        <>
          <div className="l-container:12/12 l-container--default-spacing">
            <hr />
          </div>

          <section className="l-section" dir={isTextRtl ? 'rtl' : 'ltr'}>
            <div className="l-container:12/12 l-container--default-spacing">
              <div className="l-grid l-grid--default-spacing">
                <div className="l-grid__col:3/12 l-grid__col:12/12@to:viewport-7 l-grid__col:4/12@to:viewport-9">
                  <Kpi
                    {...{
                      title: data?.smallBlock?.title || '',
                      description: data.smallBlock?.description || ''
                    }}
                  >
                    {data.smallBlock?.action ? (
                      <ButtonNew
                        size={'md'}
                        variant={BUTTON_VARIANT.TERTIARY}
                        icon={'ti-arrow-narrow-right'}
                        url={getUrl(data.smallBlock?.action, data.extra.slugPrefix)}
                      >
                        {data.smallBlock?.action?.title || ''}
                      </ButtonNew>
                    ) : null}
                  </Kpi>
                </div>

                <div className="l-grid__col:9/12 l-grid__col:12/12@to:viewport-7 l-grid__col:8/12@to:viewport-9">
                  <h5 style={{ marginBottom: '1.75rem' }}>
                    {getMicroCopy({
                      key: 'integration.soonOnDeliverect',
                      data: data.extra.microCopy
                    })}
                  </h5>

                  {notActiveIntegrations.length > 0 ? (
                    <IntegrationsList
                      integrations={notActiveIntegrations}
                      slugPrefix={data.extra.slugPrefix}
                      labels={{
                        paginationLoadMore: getMicroCopy({
                          key: 'integration.paginationLoadMore',
                          data: data.extra.microCopy
                        }),
                        paginationOf: getMicroCopy({
                          key: 'integration.paginationOf',
                          data: data.extra.microCopy
                        })
                      }}
                    />
                  ) : (
                    <p>
                      {getMicroCopy({
                        key: 'integration.noResultsMessage',
                        data: data.extra.microCopy
                      })}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      {bodyContent && <Hero content={bodyContent} title={bodyTitle} small textContent />}

      {titleCrissCrosses && <BlockSectionHeader data={titleCrissCrosses} />}

      {crissCrosses
        ? crissCrosses.map((crissCross) => (
            <BlockCrissCross key={crissCross.id} data={crissCross} />
          ))
        : null}
    </>
  )
}

export default Filter
