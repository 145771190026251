export default (url) => {
  // This check can be adjusted accordingly, if we want to add http(s):// optionally for example
  if (url.includes(process.env.GATSBY_SITE_URL)) {
    return false
  }

  const regex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

  return regex.test(url)
}
