/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react'
import { createRoot } from 'react-dom/client'

//
import { PageWrapper } from './src/components'
import { setUTMParamsAsCookie } from './src/utils/utm'

/**
 * Implements Gatsby wrapPageElement(params).
 */
export const wrapPageElement = ({ element, props }) => {
  if (process.env.PREVIEW_MODE === 'true') {
    return element
  }

  return <PageWrapper {...props}>{element}</PageWrapper>
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state)
    location.state.referrer = prevLocation ? prevLocation.pathname : null
}

export const onPreRouteUpdate = ({ location }) => {
  if (location.search) {
    setUTMParamsAsCookie(location.search)
  }
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }

  if (window.location.pathname === '/en/app-store-redirect') {
    const userAgent =
      typeof navigator !== 'undefined'
        ? navigator.userAgent || navigator.vendor || window.opera
        : ''
    let targetUrl = '/en/app-store-redirect' // Default redirection URL

    if (/android/i.test(userAgent)) {
      targetUrl = 'https://play.google.com/store/apps/details?id=com.deliverect.operations' // URL for Android users
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      targetUrl = 'https://apps.apple.com/us/app/deliverect-for-managers/id6477866067' // URL for iOS users
    }

    if (window.location.pathname !== targetUrl) {
      window.location.replace(targetUrl)
    }
  }
}

export const replaceHydrateFunction = () => {
  return (element, container) => {
    createRoot(container).render(element)
  }
}
