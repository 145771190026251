export const getSlug = (content, slugPrefix) => {
  if (!content) {
    return `/${slugPrefix}`
  }

  const TypeName = '__typename'

  if (content[TypeName] === 'WistiaVideo' || content[TypeName] === 'ContentfulWistiaVideo') {
    return {
      videoId: content.videoId
    }
  }

  let slug = ''
  const integrationType =
    content[TypeName] === 'ContentfulIntegration' ||
    content[TypeName] === 'Integration' ||
    content[TypeName] === 'ContentfulIntegrationCategory' ||
    content[TypeName] === 'IntegrationCategory'

  if (integrationType) {
    slug = `/${slugPrefix}/integrations/${content?.slug}`
  } else if (content[TypeName] === 'ContentfulCustomer' || content[TypeName] === 'Customer') {
    slug = `/${slugPrefix}/customers/${content?.slug}`
  } else if (content[TypeName] === 'ContentfulArticle' || content[TypeName] === 'Article') {
    const categories = content?.categoriesCollection?.items || content?.categories || []
    if (categories?.length && content?.slug) {
      slug = `/${slugPrefix}/blog/${categories?.[0]?.slug}/${content?.slug}`
    }
  } else if (content[TypeName] === 'ContentfulCareersPage' || content[TypeName] === 'CareersPage') {
    slug = `/en/careers/${content?.slug || ''}`
  } else if (content[TypeName] === 'ContentfulNavigationItem') {
    if (content['externalUrl']) slug = content['externalUrl']
    if (content['relativePath'])
      slug = `/${slugPrefix}${content['relativePath']}`.replace(/\/\//g, '/')
  } else {
    slug = `/${slugPrefix}/${content?.slug || ''}`
  }

  return slug.replace(/\/{2,3}/g, '/')
}

export const buildLangSlug = (slug, slugPrefix) => {
  return `/${slugPrefix}/${slug}`.replace(/\/\//g, '/')
}
