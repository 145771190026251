import { useEffect, useRef } from 'react'

function useSharedRef(forwardedRef) {
  // final ref that will share value with forward ref. this is the one we will attach to components
  const innerRef = useRef(null)

  useEffect(() => {
    if (!forwardedRef) {
      return
    }
    if (typeof forwardedRef === 'function') {
      forwardedRef(innerRef.current)
    } else {
      forwardedRef.current = innerRef.current
    }
  })

  return innerRef
}

export default useSharedRef
