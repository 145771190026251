import cn from 'classnames'
import React from 'react'

//

import ButtonNew from '../ButtonNew/ButtonNew'

const DesktopFilters = ({
  visibleCategories,
  handleOnCategoryClick,
  nrOfVisibleCategories,
  categories,
  selectedHiddenCategories,
  open
}) => {
  return (
    <div className="c-blogFilters__container-desktop">
      <div className="c-blogFilters__wrapper-desktop">
        {categories.map((category) => (
          <ButtonNew
            key={category.id}
            classes={cn('c-blogFilters__button', category.selected && 'active')}
            buttonProps={{ onClick: () => handleOnCategoryClick(category.id) }}
            filter
            small
          >
            {category.name}
          </ButtonNew>
        ))}
      </div>
    </div>
  )
}

export default DesktopFilters
