import cn from 'classnames'
import React from 'react'

import { ImageFluid } from '../../utils/image'

const BG_COLORS = {
  GREEN: 'green',
  YELLOW: 'yellow',
  BLUE: 'blue',
  WHITE: 'white',
  PINK: 'pink',
  ORANGE: 'orange',
  BLACK: 'black'
}

const SectionImage = ({ image, imageBackgroundColor }) => {
  return (
    <div
      className={cn('c-section-image', {
        'c-section-image--green': !imageBackgroundColor || imageBackgroundColor === BG_COLORS.GREEN,
        'c-section-image--yellow': imageBackgroundColor === BG_COLORS.YELLOW,
        'c-section-image--blue': imageBackgroundColor === BG_COLORS.BLUE,
        'c-section-image--white': imageBackgroundColor === BG_COLORS.WHITE,
        'c-section-image--black': imageBackgroundColor === BG_COLORS.BLACK,
        'c-section-image--pink': imageBackgroundColor === BG_COLORS.PINK,
        'c-section-image--orange': imageBackgroundColor === BG_COLORS.ORANGE
      })}
    >
      <ImageFluid image={image} className={'c-section-image__img'} />
    </div>
  )
}

export default SectionImage
