import React, { useRef } from 'react'
import { createPortal } from 'react-dom'

import { useOverlay } from '../hooks'

const Modal = ({
  children,
  isOpen = false,
  onOverlayClick,
  elementId = 'root',
  close = () => null
}) => {
  const ref = useRef(null)
  useOverlay(isOpen, close, ref)

  if (isOpen === false) {
    return null
  }
  return createPortal(
    <div role="dialog" aria-modal className="c-modal-wrapper">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className="c-modal-overlay" onClick={onOverlayClick} />
      <div ref={ref} className="c-modal-container">
        {children}
      </div>
    </div>,
    document.getElementById(elementId)
  )
}

export default Modal
