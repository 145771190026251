import cn from 'classnames'
import React from 'react'

const IntegrationLabel = ({ text = '', type = '', classes = '' }) => {
  return (
    <div
      className={cn('c-integration-label', classes, {
        [`c-integration-label--${type}`]: type
      })}
    >
      {text}
    </div>
  )
}

export default IntegrationLabel
