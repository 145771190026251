import React from 'react'

export default (Component) => {
  const componentWitchAnchor = (props) => {
    const anchor = props?.anchor || props?.data?.anchor
    return (
      <>
        {anchor && <span id={anchor}> </span>}
        <Component {...props} />
      </>
    )
  }
  componentWitchAnchor.displayName = `${Component?.name}ComponentWitchAnchor`
  return componentWitchAnchor
}
