import React from 'react'

import { HomeVisual, OrderIntegrationVisual } from '../HeroHome'

export const VisualComponent = ({
  visualTitle = '',
  image = null,
  videoId = '',
  wistiaOptions = {},
  aspectRatio,
  systems = [],
  integrationsUrl = ''
}) => {
  if (videoId)
    return (
      <HomeVisual
        videoId={videoId}
        wistiaOptions={{
          ...wistiaOptions,
          loop: true,
          muted: true,
          autoPlay: true,
          controlsVisibleOnLoad: false,
          hideControls: true
        }}
        aspectRatio={aspectRatio}
        title={visualTitle}
        systems={systems}
        image={image}
        integrationsUrl={integrationsUrl}
      />
    )

  if (image) return <OrderIntegrationVisual image={image || ''} />

  return null
}
