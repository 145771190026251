import cn from 'classnames'
import React, { useContext } from 'react'

import { SiteDataContext } from '../../components'
import { ImageFluid } from '../../utils/image'
import { Icon, SectionHeader } from '../index'

const AnimatedImage = ({
  title,
  action,
  label,
  footer,
  animationFile,
  mobileTextItems,
  mobileImage
}) => {
  const { isTextRtl } = useContext(SiteDataContext)

  return (
    <section className={cn('l-section c-animated-image', { 'c-animated-image--rtl': isTextRtl })}>
      <div className="l-container:8/12 l-container--default-spacing c-animated-image__header">
        <SectionHeader
          eyebrow={label}
          title={title}
          headingType="h2"
          align="center"
        ></SectionHeader>
      </div>

      <div className="c-animated-image__mobile-image-wrapper">
        <ImageFluid image={mobileImage} />
      </div>

      {animationFile?.file?.url && (
        <video className="c-animated-image__video" autoPlay loop muted>
          <source src={animationFile.file.url} type="video/mp4" />
        </video>
      )}

      <ul className="l-container:8/12 l-container--default-spacing c-animated-image__mobile-footer">
        {mobileTextItems.map((item) => (
          <li key={item}>
            <Icon name="topic/checkmark-two" />
            <p>{item}</p>
          </li>
        ))}
      </ul>

      <p className="c-animated-image__footer">{footer}</p>
    </section>
  )
}

export default AnimatedImage
