import React from 'react'

//
import { MicroCopyContext } from '../../../components/page-wrapper'
import { LoadMore, IntegrationListing } from '../../../storybook'
import { IntegrationTypes } from '../../../storybook/Integration'
import { useWindowWidth } from '../../../storybook/hooks'
import { getIntegrationLabel, getIntegrationLabelColor } from '../../../utils/common'
import { getMicroCopy } from '../../../utils/microcopy'

const VIEWPORT_7 = 720

const BlockIntegrationListing = ({
  data: { title, preface, description, integrations, extra, primaryAction, contentList },
  limit = 9
}) => {
  const microCopyData = React.useContext(MicroCopyContext)
  const [offsetToShow, setOffsetToShow] = React.useState(limit)

  const windowWidth = useWindowWidth()

  const integrationSize =
    windowWidth > VIEWPORT_7 ? IntegrationTypes.medium : IntegrationTypes.large

  const integrationsToShow = [...integrations]
  integrationsToShow.sort((a, b) => (a.label ? -1 : 0))
  integrationsToShow.sort((a, b) => {
    if (a.label === 'new' && b.label !== 'new') {
      return -1
    }
    if (a.label === 'new' && b.label === 'new') {
      return 0
    }
    if (a.label !== 'new' && b.label === 'new') {
      return 1
    }
    return -1
  })

  const cards = integrationsToShow.slice(0, offsetToShow).map((node) => {
    const { id, logo, slug, title, label, categories } = node

    return {
      id,
      title,
      type: categories.map((cat) => cat.title).join(', '),
      size: integrationSize,
      label: getIntegrationLabel(label),
      labeltype: getIntegrationLabelColor(label),
      url: `/${extra.slugPrefix}/integrations/${slug}`.replace('//', '/'),
      logo: logo?.file?.url || ''
    }
  })

  return (
    <IntegrationListing
      title={title || ''}
      preface={preface || null}
      description={description || ''}
      items={cards}
      primaryAction={primaryAction || null}
      contentList={contentList || []}
    >
      {offsetToShow < integrationsToShow.length && (
        <LoadMore
          current={offsetToShow}
          total={integrationsToShow.length}
          loadLabel={getMicroCopy({ key: `integration.paginationLoadMore`, data: microCopyData })}
          separationLabel={getMicroCopy({ key: `integration.paginationOf`, data: microCopyData })}
          handleLoadMore={() => setOffsetToShow(offsetToShow + limit)}
        />
      )}
    </IntegrationListing>
  )
}

export default BlockIntegrationListing
