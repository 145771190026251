import { action } from '@storybook/addon-actions'

export const filterTypes = Object.freeze({
  countries: 'countries',
  tags: 'tags'
})

export const categories = Object.freeze({
  id: '0',
  title: 'Categories',
  showMoreLabel: 'Show more',
  showLessLabel: 'Show less',
  items: [
    {
      id: '0',
      label: 'All integrations',
      buttonProps: {
        onClick: action('Button click')
      },
      active: true
    },
    {
      id: '1',
      label: 'Delivery channels',
      url: '#',
      buttonProps: {
        onClick: action('Button click')
      },
      active: false
    },
    {
      id: '2',
      label: 'POS systems',
      url: '#',
      buttonProps: {
        onClick: action('Button click')
      },
      active: false
    },
    {
      id: '3',
      label: 'Online ordering',
      url: '#',
      buttonProps: {
        onClick: action('Button click')
      },
      active: false
    },
    {
      id: '4',
      label: 'in-house dining app',
      url: '#',
      buttonProps: {
        onClick: action('Button click')
      },
      active: false
    }
  ]
})

export const categoriesWithIcon = Object.freeze({
  id: '1',
  title: 'Categories',
  showMoreLabel: 'Show more',
  showLessLabel: 'Show less',
  items: [
    {
      id: '0',
      icon: 'topic/ticket',
      label: 'POS systems',
      url: '#',
      buttonProps: {
        onClick: action('Button click')
      },
      active: true
    },
    {
      id: '1',
      icon: 'topic/online-ordering',
      label: 'Online ordering',
      url: '#',
      buttonProps: {
        onClick: action('Button click')
      },
      active: false
    }
  ]
})

export const countries = Object.freeze({
  id: '2',
  title: 'Countries',
  showMoreLabel: 'Show more',
  showLessLabel: 'Show less',
  type: filterTypes.countries,
  items: [
    {
      id: '1',
      img: '/images/icon-belgium.svg',
      label: 'Australia',
      url: '#',
      buttonProps: {
        onClick: action('Button click')
      },
      active: true
    },
    {
      id: '2',
      img: '/images/icon-belgium.svg',
      label: 'Belgium',
      url: '#',
      buttonProps: {
        onClick: action('Button click')
      },
      active: false
    },
    {
      id: '3',
      img: '/images/icon-belgium.svg',
      label: 'Canada',
      url: '#',
      buttonProps: {
        onClick: action('Button click')
      },
      active: false
    },
    {
      id: '4',
      img: '/images/icon-belgium.svg',
      label: 'Germany',
      url: '#',
      buttonProps: {
        onClick: action('Button click')
      },
      active: false
    },
    {
      id: '5',
      img: '/images/icon-belgium.svg',
      label: 'France',
      url: '#',
      buttonProps: {
        onClick: action('Button click')
      },
      active: false
    },
    {
      id: '6',
      img: '/images/icon-belgium.svg',
      label: 'Spain',
      url: '#',
      buttonProps: {
        onClick: action('Button click')
      },
      active: false
    },
    {
      id: '7',
      img: '/images/icon-belgium.svg',
      label: 'The Netherlands',
      url: '#',
      buttonProps: {
        onClick: action('Button click')
      },
      active: false
    },
    {
      id: '8',
      img: '/images/icon-belgium.svg',
      label: 'United states',
      url: '#',
      buttonProps: {
        onClick: action('Button click')
      },
      active: false
    },
    {
      id: '9',
      img: '/images/icon-belgium.svg',
      label: 'Canada',
      url: '#',
      buttonProps: {
        onClick: action('Button click')
      },
      active: false
    },
    {
      id: '10',
      img: '/images/icon-belgium.svg',
      label: 'Germany',
      url: '#',
      buttonProps: {
        onClick: action('Button click')
      },
      active: false
    }
  ]
})
