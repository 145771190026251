import cn from 'classnames'
import React, { useRef, useMemo } from 'react'

import { SiteDataContext } from '../../components'
import { ImageFluid } from '../../utils/image'
import Icon from '../IconNew'
import { useLockBodyScroll, useOnClickOutside } from '../hooks'

export default function SideDrawer({ isOpen, languageLocationMenu, handleIsOpen }) {
  const { isTextRtl } = React.useContext(SiteDataContext)
  const rootEl = useRef(null)

  const [openMarketId, setOpenMarketId] = React.useState(null)

  const handleMarketClick = (marketId, e) => {
    if (marketId === openMarketId) {
      setOpenMarketId(null)
      return
    }

    setOpenMarketId(marketId)
  }

  const { languageLabel, title, marketItems, handleSelectMarket } = languageLocationMenu

  useOnClickOutside(rootEl, (e) => {
    if (isOpen) {
      e.stopImmediatePropagation()
    }
    handleIsOpen(false)
  })

  useLockBodyScroll(isOpen)

  const renderLanguageLocation = useMemo(() => (
    <div className="c-sidedrawer__dropdown__container">
      <ul className="c-sidedrawer__markets-list">
        {marketItems.map(({ id, title, languages, slugPrefix, svgFlag }) => {
          return (
            <li key={`market-${id}`}>
              <MarketSelectionItem
                id={id}
                title={title}
                languages={languages}
                svgFlag={svgFlag}
                isLanguagesOpen={openMarketId === id}
                handleClick={handleMarketClick}
                languagesTitle={languageLabel}
                slugPrefix={slugPrefix}
                handleSelectMarket={handleSelectMarket}
              />
            </li>
          )
        })}
      </ul>
    </div>
  ))

  return (
    <div
      ref={rootEl}
      className={cn('c-sidedrawer', {
        'is-open': isOpen,
        'c-sidedrawer--rtl': isTextRtl
      })}
    >
      <div className="c-sidedrawer__location-container">
        <div className="c-sidedrawer__header ">
          <button
            className={'c-sidedrawer__header__icon'}
            onClick={() => {
              handleIsOpen(false)
            }}
          >
            <Icon name={'tabler-arrow-left'} classes={''} />
          </button>
          {title}
        </div>
        {renderLanguageLocation}
      </div>
    </div>
  )
}

const MarketSelectionItem = ({
  id,
  title,
  svgFlag,
  languagesTitle,
  languages,
  isLanguagesOpen,
  handleClick,
  handleSelectMarket
}) => {
  return (
    <div
      role="button"
      onClick={() => {
        handleClick(id)
      }}
      onKeyPress={() => {}}
      tabIndex="0"
    >
      <div
        className={cn('c-sidedrawer__dropdown__container__item', {
          'c-sidedrawer__dropdown__container__item--active': isLanguagesOpen
        })}
      >
        <div className={'c-sidedrawer__market-image'}>
          <ImageFluid
            width={40}
            height={40}
            image={{
              url: svgFlag.file.url
            }}
          />
        </div>
        <span className="c-sidedrawer__dropdown__container__item-title">{title}</span>
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <ul
        className={cn('c-sidedrawer__dropdown__container__item__languages-wrapper', {
          open: isLanguagesOpen
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <li className="c-sidedrawer__dropdown__container__item__languages">
          <div className="c-sidedrawer__dropdown__container__language-label">{languagesTitle}</div>
          {languages.map((lang) => (
            <div
              key={`market-${id}-${lang.label}`}
              role="button"
              onClick={(e) => {
                handleSelectMarket(lang, e)
              }}
              onKeyPress={() => {}}
              tabIndex="0"
              className="c-sidedrawer__dropdown__container__item c-sidedrawer__dropdown__container__item__language"
            >
              {lang.label}
            </div>
          ))}
        </li>
      </ul>
    </div>
  )
}
