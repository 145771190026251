import * as React from 'react'
import { Helmet } from 'react-helmet'

//

const Metatags = ({
  siteName,
  title,
  description,
  canonical,
  alternate = [],
  nofollow,
  nosnippet,
  noarchive,
  maxPreviewImage,
  noindex,
  image,
  pageType,
  preload,
  keywords = false,
  pageLocale = 'en',
  schemaOrg
}) => {
  const metaLinks = []
  const metaTags = []

  if (canonical) {
    metaLinks.push({
      rel: 'canonical',
      href: canonical
    })
  }

  if (Array.isArray(preload)) {
    preload.forEach((preloadLik) => {
      metaLinks.push(preloadLik)
    })
  }

  if (Array.isArray(alternate)) {
    // add default lang
    alternate.push({
      rel: 'alternate',
      hrefLang: 'x-default',
      href: `${process.env.GATSBY_SITE_URL}/en`
    })

    alternate.forEach((a) => {
      metaLinks.push(a)
    })
  }

  if (image) {
    metaTags.push({
      name: 'og:image',
      content: image
    })

    metaTags.push({
      name: 'twitter:image',
      content: image
    })
  }

  // Handle robots: nofollow, noindex, noarchive and nosnippet
  const robots = []

  if (nofollow) {
    robots.push('NOFOLLOW')
  }

  if (noindex) {
    robots.push('NOINDEX')
  }

  if (nosnippet) {
    robots.push('NOSNIPPET')
  }

  if (noarchive) {
    robots.push('NOARCHIVE')
  }

  if (maxPreviewImage) {
    robots.push('max-image-preview:large')
  }

  if (robots.length) {
    metaTags.push({
      name: 'robots',
      content: robots.join(', ')
    })
  }

  metaTags.push({
    property: 'og:title',
    content: title
  })

  metaTags.push({
    property: 'twitter:title',
    content: title
  })

  metaTags.push({
    name: 'twitter:card',
    content: 'summary'
  })

  metaTags.push({
    property: 'og:type',
    content: 'website'
  })

  metaTags.push({
    name: 'twitter:card',
    content: 'summary_large_image'
  })

  metaTags.push({
    name: 'og:site_name',
    content: siteName
  })

  if (description) {
    metaTags.push({
      name: 'description',
      content: description
    })
    metaTags.push({
      property: 'og:description',
      content: description
    })
    metaTags.push({
      name: 'twitter:description',
      content: description
    })
  }

  if (keywords) {
    metaTags.push({
      name: 'keywords',
      content: keywords
    })
  }

  const scripts = []

  return (
    <Helmet>
      <title>{title}</title>
      {metaTags.map((tag) => (
        <meta key={tag.name || tag.property} {...tag} />
      ))}
      {metaLinks.map((tag) => (
        <link key={tag.rel} {...tag} />
      ))}
      {scripts.map((script, i) => (
        <script key={`script-${i}`} type={script.type}>
          {JSON.stringify(script.content.schemaOrg, null, 2)}
        </script>
      ))}
      <meta name="theme-color" content="#00d369" />
      <meta name="page-title" data-type="string" content={title} />
      <meta name="page-lang" data-type="string" content={pageLocale} />
      <meta name="page-type" data-type="string" content={pageType} />
    </Helmet>
  )
}

export default Metatags
