import React from 'react'

//
import ButtonNew from '../ButtonNew'

const CareersLinkedinSection = ({
  handleOnClick,
  buttonLabel,
  disclaimerText,
  disclaimerLinkText
}) => (
  <div className="c-careers__linkedin">
    <ButtonNew linkedin buttonProps={{ onClick: handleOnClick }}>
      {buttonLabel}
    </ButtonNew>
    <p>
      {disclaimerText}{' '}
      <a
        target="_blank"
        rel="noreferrer nofollow"
        href="https://www.linkedin.com/help/linkedin/answer/72063"
      >
        {disclaimerLinkText}
      </a>
    </p>
  </div>
)

export default CareersLinkedinSection
