import TablerIconsSVG from '@tabler/icons-sprite/dist/tabler-sprite.svg'
import React, { Suspense, memo, lazy } from 'react'

const Icon = ({ name, size = 24, classes, social = false, ...rest }) => {
  if (!name || typeof name !== 'string') return null

  if (!name.startsWith('ti-') && social) {
    const SocialIcon = lazy(() => import(`../Icon/icons/social/${name}.svg`))
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <SocialIcon width={size} height={size} className={classes} />
      </Suspense>
    )
  }

  const iconName = name.startsWith('ti-') ? name.replace('ti-', 'tabler-') : name

  return (
    <svg width={size} height={size} className={classes} {...rest}>
      <use xlinkHref={`${TablerIconsSVG}#${iconName}`} />
    </svg>
  )
}

export default memo(Icon)
