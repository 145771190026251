export const MAX_NUMBER_OF_LOGOS = 6

export const getLogoUrl = (logo = {}) => {
  if (logo?.svgIcon?.file) return logo?.svgIcon?.file?.url
  return logo?.svgIcon?.url
}

export const getFilterLogos = (logo, extra) => {
  const isSlugPrefix = logo?.markets?.some((logo) => logo.slugPrefix === extra.slugPrefix)
  let isFiltered = true

  if (!logo.nonAutomated) {
    isFiltered = false
  }

  if (isSlugPrefix) {
    isFiltered = false
  }

  if (getLogoUrl(logo)) {
    isFiltered = false
  }

  return isFiltered
}
