import cn from 'classnames'
import React, { useContext, useReducer } from 'react'

import { IconNew } from '..'
import { SiteDataContext } from '../../components'
import {
  NUMBER_OF_LOCATIONS,
  ORDER_ESTIMATE_PLAN_SIZE_OPTIONS
} from '../../components/hubspot/hubspot-form'
import { usePricingContext } from '../../context/pricingContext'
import { PRICING } from '../../utils/common'
import { onKeyDownHandler } from '../../utils/keyboard-event'
import { getMicroCopy } from '../../utils/microcopy'
import { getUrl } from '../../utils/urls'
import ButtonNew from '../ButtonNew'
import { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
import { useEstimatedPrice } from '../hooks/use-estimated-price'

export const ANNUAL_KEY = PRICING.BILLING_TIME_ANNUALLY
export const MONTHLY_KEY = PRICING.BILLING_TIME_MONTHLY
export const MONTH_KEY = PRICING.BILLING_TIME_MONTH
export const YEAR_KEY = PRICING.BILLING_TIME_YEAR

const PricingCalculatorEstimations = () => {
  const { market, isTextRtl } = useContext(SiteDataContext)
  const currency = market.currencySymbol

  const billingTime = ANNUAL_KEY
  const [toggle, setToggle] = useReducer((state) => !state, false)

  const {
    pricingEstimations,
    microCopy,
    slugPrefix,
    orders,
    sliderRef,
    maxOrders,
    minOrders,
    pricingTiers,
    monthlySavingsPrice,
    annualSavingsPrice,
    locationValue
  } = usePricingContext()

  const {
    estimatedPricePlan,
    totalPrice,
    setUpFee,
    isMonthlyBillingTime,
    orderPlan,
    extraCharged
  } = useEstimatedPrice(orders, billingTime, maxOrders, minOrders, pricingTiers, sliderRef)

  const toggledLabel = toggle ? pricingEstimations?.hideLabel : pricingEstimations?.showLabel
  const toggledIcon = toggle ? 'ti-chevron-up' : 'ti-chevron-down'

  const waivedLabel = !isMonthlyBillingTime && pricingEstimations?.waivedLabel

  const priceItems = [
    { id: '0', text: pricingEstimations?.onlineOrdersLabel, result: orders },
    { id: '1', text: pricingEstimations?.maxOrdersPlanLabel, result: orderPlan.maxNoOfOrders },
    { id: '2', text: pricingEstimations?.extraChargeLabel, result: `${currency}${extraCharged}` },
    {
      id: '3',
      text: pricingEstimations?.setupFeeLabel,
      result: waivedLabel,
      subText: `${currency}${setUpFee}`
    }
  ]

  const getUrlWithEstimations = (action) => {
    const url = getUrl(action, slugPrefix)

    // Transform order value to the plan input value
    let plan = ORDER_ESTIMATE_PLAN_SIZE_OPTIONS.find(({ value }) => {
      return orders < value
    })
    if (!plan) plan = ORDER_ESTIMATE_PLAN_SIZE_OPTIONS[ORDER_ESTIMATE_PLAN_SIZE_OPTIONS.length - 1]

    // Transform locations value to the input value
    let location = NUMBER_OF_LOCATIONS.find(({ max }) => {
      return locationValue <= max
    })
    if (!location) location = NUMBER_OF_LOCATIONS[NUMBER_OF_LOCATIONS.length - 1]

    const params = [
      `monthly_potential_savings=${monthlySavingsPrice}`,
      `yearly_potential_savings=${annualSavingsPrice}`,
      `number_of_locations=${location.value}`
    ].join('&')

    return `${url}?${params}`
  }

  return (
    <div className={cn('c-pricing-calculator__estimations')} dir={isTextRtl ? 'rtl' : 'ltr'}>
      <div className="c-pricing-calculator__card-estimation c-pricing-calculator__card-estimation--green">
        <div className="c-pricing-calculator__card-estimation__header">
          {getMicroCopy({ key: `pricing.${MONTHLY_KEY}`, data: microCopy })}{' '}
          <span>{pricingEstimations?.savingsLabel}</span>
        </div>
        <div className="c-pricing-calculator__card-estimation__value">
          {currency}
          {monthlySavingsPrice.toLocaleString()}
        </div>
      </div>
      <div className="c-pricing-calculator__card-estimation c-pricing-calculator__card-estimation--green">
        <div className="c-pricing-calculator__card-estimation__header">
          {getMicroCopy({ key: `pricing.${ANNUAL_KEY}`, data: microCopy })}{' '}
          {pricingEstimations?.savingsLabel}
        </div>
        <div className="c-pricing-calculator__card-estimation__value">
          {currency}
          {annualSavingsPrice.toLocaleString()}
        </div>
      </div>
      <div className="c-pricing-calculator__card-estimation c-pricing-calculator__card-estimation--black">
        <div className="c-pricing-calculator__card-estimation__value-container">
          <div className="c-pricing-calculator__card-estimation__header">
            {pricingEstimations?.preface}
          </div>
          <div className="c-pricing-calculator__card-estimation__value">
            {currency}
            {estimatedPricePlan}{' '}
            <span className="c-pricing-calculator-estimations__plan-billing-time__period">
              /{getMicroCopy({ key: `pricing.${MONTH_KEY}`, data: microCopy })}
            </span>
          </div>
        </div>
        <div>
          <button
            icon={toggledIcon}
            className="c-pricing-calculator-estimations__dropdown-action"
            onClick={() => setToggle()}
            onKeyDown={() => onKeyDownHandler(['Enter'], setToggle)}
          >
            {toggledLabel} <IconNew name={toggledIcon} size={16} />
          </button>
        </div>
        <div
          className={cn('c-pricing-calculator-estimations__dropdown-list', {
            'c-pricing-calculator-estimations__dropdown-list--visible': toggle
          })}
        >
          <ul className={cn('c-pricing-calculator-estimations__dropdown-items', {})}>
            {priceItems.map((item) => (
              <li key={item.id} className="c-pricing-calculator-estimations__dropdown-grid">
                <p className="c-pricing-calculator-estimations__dropdown-text">{item.text}</p>
                <p className="c-pricing-calculator-estimations__dropdown-result">
                  <span>{item.subText}</span> {item.result}
                </p>
              </li>
            ))}
            <li className="c-pricing-calculator-estimations__dropdown-total">
              <p className="c-pricing-calculator-estimations__dropdown-grid">
                <span className="c-pricing-calculator-estimations__dropdown-text">
                  {pricingEstimations?.totalLabel}
                </span>
                <span className="c-pricing-calculator-estimations__dropdown-result">
                  {currency}
                  {totalPrice}{' '}
                  <span>/{getMicroCopy({ key: `pricing.${YEAR_KEY}`, data: microCopy })}</span>
                </span>
              </p>
            </li>
          </ul>
        </div>

        {pricingEstimations?.action && (
          <ButtonNew
            variant={BUTTON_VARIANT.SECONDARY}
            url={getUrlWithEstimations(pricingEstimations?.action)}
            classes="c-pricing-calculator-estimations__plan-action"
            fullWidth
          >
            {pricingEstimations?.action?.title}
          </ButtonNew>
        )}
      </div>
    </div>
  )
}

export default PricingCalculatorEstimations
