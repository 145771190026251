import cn from 'classnames'
import React, { Children } from 'react'

//
import RichText from '../../components/rich-text'

import { HEADING_TYPE } from './FormLayout.const'

const FormLayout = ({
  title = '',
  text = null,
  textTokens = {},
  subHeading = null,
  children,
  smallPadding = false,
  headingType = HEADING_TYPE.h2
}) => {
  const HeadingTag = headingType

  return (
    <section
      className={cn('c-form-layout l-section', { 'c-form-layout--small-padding': smallPadding })}
    >
      <div className="l-container:8/12 l-container--default-spacing">
        {title && <HeadingTag className="c-form-layout__title-center">{title}</HeadingTag>}

        {text && (
          <div className="c-form-layout__text-center">
            <RichText data={text} tokens={textTokens} />
          </div>
        )}

        {subHeading ? <div className="c-form-layout__text-center">{subHeading}</div> : null}

        <div className="c-form-layout__content">
          {Children.map(children, (child) => (
            <div className="c-form-layout__content-item">{child}</div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FormLayout
