import React, { memo } from 'react'

import RichText from '../../components/rich-text'
import { PricingContextProvider } from '../../context/pricingContext'
import PricingCalculatorEstimations from '../PricingCalculatorEstimations'
import PricingCalculatorOrders from '../PricingCalculatorOrders'
import SectionHeader from '../SectionHeader'

const PricingCalculator = ({ data }) => {
  return (
    <PricingContextProvider data={data}>
      <section className="l-section l-section--grey c-pricing-calculator u-block-gap ">
        <div className="l-container:12/12 l-container--default-spacing">
          <SectionHeader
            title={data?.title}
            text={data?.description}
            headingType="h2"
            align="center"
            classes="c-pricing-calculator__header l-container:6/12 l-container--default-spacing"
          />
          <div className="c-pricing-calculator__container">
            <PricingCalculatorOrders />
            <PricingCalculatorEstimations />
          </div>

          <RichText
            data={data?.footerLabel}
            tokens=""
            classes="c-pricing-calculator__footer l-container:12/12"
          />
        </div>
      </section>
    </PricingContextProvider>
  )
}

export default memo(PricingCalculator)
