import React from 'react'

//
import HubSpotForm from '../../../components/hubspot'
import { List, FormLayout2 as FormLayoutWithLeftContent } from '../../../storybook'

const BlockRequestForm = ({
  data: { title, description, bulletPoints, hubSpotForm, headingType, formPosition }
}) => {
  return (
    <FormLayoutWithLeftContent
      text={description || ''}
      title={title || ''}
      headingType={headingType || 'h2'}
      formPosition={formPosition || 'center'}
    >
      <FormLayoutWithLeftContent.Content>
        {bulletPoints ? (
          <List
            type="ol"
            items={bulletPoints.map((item) => ({ id: item.id, text: item.content }))}
          />
        ) : null}
      </FormLayoutWithLeftContent.Content>

      <FormLayoutWithLeftContent.Form>
        <HubSpotForm
          formId={hubSpotForm.formId}
          mode="desktop"
          portalId={hubSpotForm.portalId}
          countryRedirect={hubSpotForm.countryRedirect}
          productPageRedirect={hubSpotForm.productPageRedirect}
          jsScript={hubSpotForm.jsScript}
        />
      </FormLayoutWithLeftContent.Form>
    </FormLayoutWithLeftContent>
  )
}

export default BlockRequestForm
