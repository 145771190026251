/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import cn from 'classnames'
import React, { useState } from 'react'

const YoutubeWrapper = ({ children, videoId }) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const handleIsPlaying = () => {
    setIsPlaying(!isPlaying)
  }

  return (
    <>
      {React.cloneElement(children, {
        onClick: () => {
          handleIsPlaying()
        }
      })}
      {isPlaying ? (
        <YoutubeModalPlayer videoId={videoId} autoPlay={true} closeModal={handleIsPlaying} />
      ) : null}
    </>
  )
}

const YoutubeModalPlayer = ({ videoId, autoPlay, closeModal = () => {} }) => {
  return (
    <div
      className={cn('c-media__yt-full-width', {
        'c-media__yt-full-width--isOpen': true
      })}
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
        closeModal()
      }}
    >
      <iframe
        title="YouTube video player"
        allowFullScreen
        height="315px"
        src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1${
          autoPlay ? `&autoplay=1` : ''
        }`}
        width="560px"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      />
    </div>
  )
}

export default YoutubeWrapper
