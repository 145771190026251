const getCategorySlugIntegrations = (slug, typeName, categories = [], categoriesKey = '') => {
  const categorySlug = categories.find((category) => category?.slug === slug)

  if (slug && slug !== typeName) {
    if (categoriesKey === 'pageTitle') {
      return categorySlug?.['pageTitle'] || categorySlug?.['title']
    }
    return categorySlug?.[categoriesKey]
  }

  return ''
}

export const getFilterIntegrations = (categories = [], slug, typeName) => {
  return (categoriesKey = '', content) => {
    const integration = getCategorySlugIntegrations(slug, typeName, categories, categoriesKey)

    if (slug === typeName || !integration) {
      return content
    }

    return integration
  }
}
