export const DropdownIntegrations = Object.freeze([
  {
    id: '0',
    title: 'Lightspeed',
    type: 'Point of sale',
    url: '#',
    logo: '/images/logo-lightspeed.svg'
  },
  {
    id: '1',
    title: 'Lightspeed',
    type: 'Point of sale',
    url: '#',
    logo: '/images/logo-lightspeed.svg'
  },
  {
    id: '2',
    title: 'Lightspeed',
    type: 'Point of sale',
    url: '#',
    logo: '/images/logo-lightspeed.svg'
  },
  {
    id: '3',
    title: 'Lightspeed',
    type: 'Point of sale',
    url: '#',
    logo: '/images/logo-lightspeed.svg'
  },
  {
    id: '4',
    title: 'Lightspeed',
    type: 'Point of sale',
    url: '#',
    logo: '/images/logo-lightspeed.svg'
  },
  {
    id: '5',
    title: 'Lightspeed',
    url: 'Point of sale',
    logo: '/images/logo-lightspeed.svg'
  }
])

export const FormIntegrations = Object.freeze([
  {
    id: '0',
    title: 'Lightspeed',
    type: 'Point of sale',
    url: '#',
    logo: '/images/logo-lightspeed.svg'
  },
  {
    id: '1',
    title: 'Lightspeed',
    type: 'Point of sale',
    url: '#',
    logo: '/images/logo-lightspeed.svg'
  }
])
