import cn from 'classnames'
import React from 'react'

import { TeamMemberTextAlignment } from '../../components/blocks/teamMembers/teamMembers'
import RichText from '../RichText/RichText'
import { withAnchor } from '../hooks'

const BoardMemberCard = ({
  title = '',
  subtitle = '',
  description,
  textAlignment,
  image,
  classes = '',
  inverted = false,
  action
}) => {
  return (
    <div
      className={cn('c-board-member-card', classes, {
        'c-board-member-card--inverted': inverted === true
      })}
    >
      {image && <div className="c-board-member-card__image">{image}</div>}

      {title || subtitle || description ? (
        <div
          className={cn('c-board-member-card__text-container', {
            'u-text-align:left': textAlignment === TeamMemberTextAlignment.left,
            'u-text-align:right': textAlignment === TeamMemberTextAlignment.right,
            'u-text-align:center':
              !textAlignment || textAlignment === TeamMemberTextAlignment.center
          })}
        >
          {title && <h4 className="c-board-member-card__title">{title}</h4>}

          {subtitle && <p className="c-board-member-card__subtitle">{subtitle}</p>}

          {description ? (
            typeof description === 'string' ? (
              <p className={cn('c-board-member-card__description')}>{description}</p>
            ) : (
              <RichText content={description} />
            )
          ) : null}

          {action || null}
        </div>
      ) : null}
    </div>
  )
}

export default withAnchor(BoardMemberCard)
