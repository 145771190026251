import { navigate } from 'gatsby'
import React from 'react'

//
import { Footer as FooterLMR } from '../../storybook'
import { getMicroCopy } from '../../utils/microcopy'
import { getUrl } from '../../utils/urls'
import { SiteDataContext, MicroCopyContext, LocationContext } from '../page-wrapper'

const Footer = ({
  marketsSlugs = {},
  langSlugs = {},
  footerMainNavigation,
  footerSocialMedia,
  footerLegalNavigation,
  actions,
  marketPhoneNo = '',
  marketPhoneNo2 = '',
  minimalFooter = false,
  footerText = undefined
}) => {
  const {
    market: { title: market },
    locale
  } = React.useContext(SiteDataContext)
  const microcopyData = React.useContext(MicroCopyContext)

  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  const slugToLang = {}
  const langToSlug = {}
  Object.keys(langSlugs).forEach((lang) => {
    slugToLang[lang] = getMicroCopy({ key: `footer.languages.${lang}`, data: microcopyData })
    langToSlug[slugToLang[lang]] = lang
  })

  const activeLanguage = slugToLang[locale]
  const activeCountry = market

  const changeCountry = (marketsSlugs, e) => {
    navigate(marketsSlugs[e])
  }

  const changeLanguage = (langSlugs, langToSlug, e) => {
    navigate(langSlugs[langToSlug[e]])
  }

  const createFooterMenuGroup = (group) => {
    return {
      title: group.title,
      items: group.navigationItems.map((navigation) => ({
        label: navigation.title,
        url: getUrl(navigation, slugPrefix),
        id: navigation.title
      })),
      id: group.id
    }
  }

  const createFooterMainNav = (footerMainNavigation) => {
    const columns = [
      { id: 0, menus: [] },
      { id: 1, menus: [] },
      { id: 2, menus: [] },
      { id: 3, menus: [] }
    ]
    footerMainNavigation.forEach((group) =>
      columns[group.column - 1 || 0].menus.push(createFooterMenuGroup(group))
    )
    return columns
  }

  const createFooterSocial = (footerSocialMedia) => {
    const items = []
    footerSocialMedia.forEach((item) => {
      const url = getUrl(item, slugPrefix)
      url.toLowerCase().includes('twitter') &&
        items.push({ label: 'twitter', url: url, id: item.id, icon: item.icon })
      url.toLowerCase().includes('facebook') &&
        items.push({ label: 'facebook', url: url, id: item.id, icon: item.icon })
      url.toLowerCase().includes('instagram') &&
        items.push({ label: 'instagram', url: url, id: item.id, icon: item.icon })
      url.toLowerCase().includes('linkedin') &&
        items.push({ label: 'linkedin', url: url, id: item.id, icon: item.icon })
      url.toLowerCase().includes('youtube') &&
        items.push({ label: 'youtube', url: url, id: item.id, icon: item.icon })
    })

    return {
      title: getMicroCopy({ key: 'footer.social', data: microcopyData }),
      items
    }
  }

  const createFooterLegalMenu = (footerLegalNavigation) => {
    const items = []
    footerLegalNavigation.forEach((item) =>
      items.push({ label: item.title, url: getUrl(item, slugPrefix), id: item.id })
    )
    return {
      items
    }
  }

  const createCountryList = (marketsSlugs) => {
    const items = []
    Object.keys(marketsSlugs).forEach((market) => {
      items.push({ label: market, url: marketsSlugs[market], id: market })
    })
    items.sort((a, b) => {
      if (a?.label > b?.label) {
        return 1
      }
      if (a?.label < b?.label) {
        return -1
      }
      return 0
    })
    return items
  }

  const createLanguageList = (langSlugs, slugToLang) => {
    const items = []

    Object.keys(langSlugs).forEach((lang) => {
      items.push({
        label: slugToLang[lang] || lang,
        url: langSlugs[lang],
        id: slugToLang[lang] || lang
      })
    })
    return items
  }

  return (
    <>
      <FooterLMR
        footerMenus={createFooterMainNav(footerMainNavigation)}
        legalMenu={createFooterLegalMenu(footerLegalNavigation)}
        socialMenu={createFooterSocial(footerSocialMedia)}
        actions={actions}
        languageCountryMenu={{
          title: getMicroCopy({ key: 'footer.languageAndCountry', data: microcopyData }),
          languageItems: createLanguageList(langSlugs, slugToLang),
          countryItems: createCountryList(marketsSlugs)
        }}
        language={activeLanguage}
        country={activeCountry}
        handleSelectCountry={(e) => changeCountry(marketsSlugs, e)}
        handleSelectLanguage={(e) => changeLanguage(langSlugs, langToSlug, e)}
        callMenu={
          marketPhoneNo || marketPhoneNo2
            ? {
                title: getMicroCopy({ key: 'footer.callUs', data: microcopyData }),
                tel: [
                  ...(marketPhoneNo
                    ? [
                        {
                          signpost: getMicroCopy({
                            key: 'footer.phoneSignpost1',
                            data: microcopyData
                          }),
                          tel: marketPhoneNo
                        }
                      ]
                    : []),
                  ...(marketPhoneNo2
                    ? [
                        {
                          signpost: getMicroCopy({
                            key: 'footer.phoneSignpost2',
                            data: microcopyData
                          }),
                          tel: marketPhoneNo2
                        }
                      ]
                    : [])
                ]
              }
            : {}
        }
        footerText={
          footerText || {
            companyInfo: getMicroCopy({ key: 'footer.companyInfo', data: microcopyData }),
            copyright: getMicroCopy({
              key: 'footer.copyright',
              tokens: { year: new Date().getFullYear() },
              data: microcopyData
            })
          }
        }
        minified={minimalFooter}
      />
    </>
  )
}

export default Footer
