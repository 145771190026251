import { useLocation } from '@gatsbyjs/reach-router'
import cn from 'classnames'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import React, { memo, useEffect, useRef, useState } from 'react'

//
import { SiteDataContext } from '../../components'
import Hamburger from '../Hamburger'
import NewIcon from '../IconNew'
import Logo from '../Logo'
import TopBar from '../TopBar'
import { NavLink } from '../helpers'
import { useLockBodyScroll, useWindowWidth } from '../hooks'

import { HeaderAction } from './HeaderAction'
const MOBILE_NAV_BREAKPOINT = 1200

const Header = ({
  primaryAction,
  secondaryAction,
  thirdAction,
  logo = {},
  items = [],
  activeDropdown,
  handleDropdown = () => null,
  toggleNav = () => null,
  isNavOpen,
  children,
  classes = '',
  slugPrefix = '',
  langSlugs = {},
  allLangSlugs = [],
  marketsSlugs = {},
  notifications = [],
  hideTopBar = false,
  isMinimalHeader,
  minimalHeaderAction
}) => {
  const { isTextRtl } = React.useContext(SiteDataContext)
  const rootEl = useRef(null)
  const location = useLocation()
  const [trigger, setTrigger] = useState()

  const widowWidth = useWindowWidth()

  const renderChild = (id) => {
    return children.find((child) => child.key === id)
  }

  useLockBodyScroll(isNavOpen)

  useEffect(() => {
    // Add scrolltrigger plugin to gsap
    gsap.registerPlugin(ScrollTrigger)

    // Create trigger

    return () => {
      setTrigger(null)
    }
  }, [])

  useEffect(() => {
    if (trigger) {
      setTimeout(() => {
        trigger.refresh(true)
      }, 800)
    }
  }, [location, isNavOpen, trigger])

  const getMinimalHeaderAction = () => {
    if (minimalHeaderAction?.label && minimalHeaderAction?.url) return minimalHeaderAction
  }

  const headerAction = isMinimalHeader ? getMinimalHeaderAction() : secondaryAction

  return (
    <div className="c-header-container">
      <header
        className={cn('c-header', hideTopBar && 'c-no-topbar', classes, {
          'c-header--rtl': isTextRtl
        })}
        ref={rootEl}
        style={{}}
      >
        {!hideTopBar ? (
          <TopBar
            headerAction={headerAction}
            slugPrefix={slugPrefix}
            storybookNotifications={notifications}
            langSlugs={langSlugs}
            allLangSlugs={allLangSlugs}
            marketsSlugs={marketsSlugs}
          />
        ) : null}
        <div className="c-header__container">
          <Logo
            withText
            link={logo?.url}
            external={logo?.external}
            anchorProps={logo?.anchorProps}
            classes="c-header__logo"
          />
          {(!widowWidth || widowWidth > MOBILE_NAV_BREAKPOINT) && (
            <nav className="c-header__content">
              {items &&
                items.length > 0 &&
                items.map((item) => {
                  if (item.url) {
                    return (
                      <NavLink
                        key={item.id}
                        to={item.url}
                        className="c-header__item"
                        activeClassName="is-active"
                      >
                        <span className="c-header__item-trigger">{item.label}</span>
                      </NavLink>
                    )
                  } else {
                    if (item.navigationItem) {
                      return (
                        <div
                          key={item.id}
                          onMouseEnter={() => handleDropdown(item.id)}
                          onMouseLeave={() => handleDropdown(null)}
                          onFocus={() => handleDropdown(item.id)}
                          onBlur={() => handleDropdown(null)}
                          className={cn('c-header__item c-header__item--dropdown', {
                            'is-active': item.id === activeDropdown
                          })}
                        >
                          <NavLink to={item.navigationItem}>
                            <span className="c-header__item-trigger">
                              {item.label}

                              <NewIcon
                                name="ti-chevron-down"
                                classes="c-header__item-trigger__icon"
                                size={16}
                                weight="700"
                              />
                            </span>
                          </NavLink>

                          {renderChild(item.id)}
                        </div>
                      )
                    }

                    return (
                      <div
                        key={item.id}
                        onMouseEnter={() => handleDropdown(item.id)}
                        onMouseLeave={() => handleDropdown(null)}
                        onFocus={() => handleDropdown(item.id)}
                        onBlur={() => handleDropdown(null)}
                        className={cn('c-header__item c-header__item--dropdown', {
                          'is-active': item.id === activeDropdown
                        })}
                      >
                        <span className="c-header__item-trigger">
                          {item.label}

                          <NewIcon
                            name="ti-chevron-down"
                            classes="c-header__item-trigger__icon"
                            size={16}
                            weight="700"
                          />
                        </span>

                        {renderChild(item.id)}
                      </div>
                    )
                  }
                })}
            </nav>
          )}

          {
            <div className="c-header__actions">
              {hideTopBar && <HeaderAction action={headerAction} actionModal={thirdAction} />}
              {!hideTopBar && <HeaderAction action={thirdAction} />}
              <HeaderAction action={primaryAction} secondary={false} />
            </div>
          }

          {<Hamburger isOpen={isNavOpen} toggleIsOpen={toggleNav} classes="c-header__hamburger" />}
        </div>
      </header>

      {(!widowWidth || widowWidth <= MOBILE_NAV_BREAKPOINT) && renderChild('mobile-nav')}
    </div>
  )
}

export default memo(Header)
