import { useCallback, useEffect, useRef, useState } from 'react'

import { PRICING } from '../../utils/common'
import { Map } from '../helpers'

import useSharedRef from './use-shared-ref'

const defaultValue = 0
export const useSlider = (
  ref,
  orders,
  MIN,
  MAX,
  isFocus,
  getValue,
  isStoredValue,
  setStoredSliderValue
) => {
  const [sliderValue, setSliderValue] = useState(defaultValue)
  const [thumbValue, setThumbValue] = useState(defaultValue)
  const sliderInput = useSharedRef(ref)
  const sliderThumb = useRef(null)

  const isSliderInputFocus = Object.is(isFocus, null)

  // return the particular value for the input focused (orders-input/slider-input)
  const isFocusHandler = useCallback((activeLabel, ratio, index) => {
    const value = orders

    const orderValue =
      Math.abs(+value - +sliderInput?.current?.value) === PRICING.DEFAULT_VALUE
        ? sliderInput?.current?.value
        : value

    if (isSliderInputFocus) return sliderInput?.current?.value
    return orderValue
  })

  const handleChange = () => {
    const value = isFocusHandler() || defaultValue
    const { width: sliderWidth } = sliderInput?.current?.getBoundingClientRect() || 0

    setSliderValue(Map(value, MIN, MAX, 0, 1))
    setThumbValue(Map(value, MIN, MAX, 0, sliderWidth))

    if (isSliderInputFocus) getValue(value)

    if (isStoredValue) setStoredSliderValue(value)
  }

  const calculatedThumbPosition = () => {
    if (sliderInput.current) {
      const { width: sliderWidth } = sliderInput.current.getBoundingClientRect()
      const { width: thumbWidth } = sliderThumb.current.getBoundingClientRect()

      if (thumbValue >= sliderWidth - thumbWidth / 2) return sliderWidth - thumbWidth
      else if (thumbValue <= thumbWidth) return thumbValue
      else return thumbValue - thumbWidth / 2
    }
  }

  useEffect(() => {
    // Strictly run on orders-input change
    if (!isSliderInputFocus) {
      setTimeout(() => {
        handleChange()
      }, 1)
    }
  }, [isFocusHandler])

  useEffect(() => {
    setTimeout(() => {
      handleChange()
    }, 200)
  }, [])

  return {
    sliderValue,
    sliderInput,
    sliderThumb,
    thumbPosition: calculatedThumbPosition(),
    handleChange
  }
}
