import cn from 'classnames'
import React, { useContext } from 'react'

//
import { IconNew } from '..'
import { LocationContext, SiteDataContext } from '../../components/page-wrapper'
import RichText from '../../components/rich-text'
import { ImageFluid } from '../../utils/image'
import { getUrl } from '../../utils/urls'
import { NavLink } from '../helpers'

const HeroHomeTwo = ({ customerTypesLabel, featureCardsCustomerTypes = [] }) => {
  const location = useContext(LocationContext)
  const { isTextRtl } = useContext(SiteDataContext)
  const { slugPrefix } = location
  return (
    <section className={cn('c-feature-cards l-section', { 'c-feature-cards--rtl': isTextRtl })}>
      <div className="c-hero-customer-types l-container:12/12 l-container--default-spacing">
        <h2 className="c-hero-customer-types__label">{customerTypesLabel}</h2>
        <div className="l-grid l-grid--spacing:6 c-hero-customer-types__container">
          {featureCardsCustomerTypes?.map((customerType) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
            <NavLink
              to={getUrl(customerType.action, slugPrefix)}
              className="c-hero-customer-types__article l-grid__col:12/12 l-grid__col:6/12@at:viewport-7 l-grid__col:3/12@at:viewport-12"
              key={customerType.id}
            >
              <div className="c-hero-customer-types__title">
                <h3>{customerType.title}</h3>
                <IconNew name={'tabler-arrow-right'} />
              </div>
              <div className="c-hero-customer-types__image">
                <ImageFluid image={customerType.image} />
              </div>
              <div className="c-hero-customer-types__text-container">
                {customerType.preface ? (
                  <p className="c-hero-customer-types__preface">{customerType.preface}</p>
                ) : null}
                <RichText
                  classes="c-hero-customer-types__description"
                  data={customerType.description}
                />
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    </section>
  )
}

export default HeroHomeTwo
