import cn from 'classnames'
import React from 'react'

//
import ButtonNew, { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'

const LoadMore = ({
  current = 30,
  total = 120,
  separationLabel = 'of',
  loadLabel = 'Load more',
  handleLoadMore,
  classes
}) => {
  const resultCountLabel = `${current} ${separationLabel} ${total}`
  return (
    <div className={cn('c-load-more', classes)}>
      <span className="c-load-more__text">{resultCountLabel}</span>

      <div className="c-load-more__progress-bar">
        <div
          className="c-load-more__progress"
          style={{
            transform: `translate3d(-${100 - (current / total) * 100}%, 0, 0)`
          }}
        />
      </div>

      <ButtonNew
        size={'md'}
        variant={BUTTON_VARIANT.TERTIARY}
        buttonProps={{
          onClick: handleLoadMore
        }}
        classes="c-load-more__action"
      >
        {loadLabel}
      </ButtonNew>
    </div>
  )
}

export default LoadMore
