import React from 'react'

//
import { ButtonNew, List, UspListBlock } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { getUrl } from '../../../utils/urls'
import { LocationContext } from '../../page-wrapper'

const BlockUspList = ({
  data: { title, preface, description, simpleContentBlocks, primaryAction, backgroundColor }
}) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  const items = simpleContentBlocks.map(
    ({ title, id, icon, description, action, bulletPoints, anchor, bulletListColor }) => ({
      description: description || '',
      icon: icon || '',
      anchor: anchor || '',
      id,
      title: title || '',
      bulletListColor: bulletListColor || null,
      children: (
        <>
          {bulletPoints && (
            <List
              type="ul"
              icon="interface/checkmark"
              items={bulletPoints.map((bulletPoint, i) => ({
                id: `${bulletPoint.id}-${i}`,
                textTokens: {},
                text: bulletPoint.content
              }))}
            />
          )}
          {action && (
            <ButtonNew
              url={getUrl(action, slugPrefix)}
              variant={BUTTON_VARIANT.SECONDARY}
              icon="ti-arrow-narrow-right"
              classes={bulletPoints && 'u-margin-top:24'}
            >
              {action.title}
            </ButtonNew>
          )}
        </>
      )
    })
  )

  const action = primaryAction ? (
    <ButtonNew
      variant={BUTTON_VARIANT.GREEN}
      url={getUrl(primaryAction, slugPrefix)}
      icon={primaryAction?.icon || ''}
    >
      {primaryAction?.title || ''}
    </ButtonNew>
  ) : null

  return (
    <UspListBlock
      items={items}
      description={description}
      title={title || ''}
      preface={preface || ''}
      classes={backgroundColor === 'gray' ? 'l-section--grey' : ''}
      action={action}
    />
  )
}

export default BlockUspList
