import { withAnchor } from '../storybook/hooks'

import * as BlockAnimatedImageComponent from './blocks/animatedImage'
import * as BlockBenefitsListingComponent from './blocks/benefitsListing'
import * as BlockBlogTopComponent from './blocks/blogTop'
import * as BlockCrissCrossComponent from './blocks/crissCross'
import * as BlockCtaComponent from './blocks/cta'
import * as BlockCustomersTopComponent from './blocks/customersTop'
import * as BlockFaqTopComponent from './blocks/faqTop'
import * as BlockFeatureContactComponent from './blocks/featureContact'
import * as BlockFeatureListComponent from './blocks/featureList'
import * as BlockFeatureSimpleComponent from './blocks/featureSimple'
import * as BlockFilterComponent from './blocks/filter'
import * as BlockHeroComponent from './blocks/hero'
import * as BlockHeroDualComponent from './blocks/heroDual'
import {
  BlockHeroHome as BlockHeroHomeComponent,
  BlockHeroHomeTwo as BlockHeroHomeTwoComponent
} from './blocks/heroHome'
import * as BlockHeroMediaComponent from './blocks/heroMedia'
import * as BlockHubSpotCalendarComponent from './blocks/hubSpotCalendar'
import * as BlockIntegrationHeroComponent from './blocks/integrationHero'
import * as BlockIntegrationListingComponent from './blocks/integrationListing'
import * as BlockIntegrationsComponent from './blocks/integrations'
import * as BlockLogosComponent from './blocks/logos'
import * as BlockMatchMakerComponent from './blocks/matchMaker'
import * as BlockOfficesComponent from './blocks/offices'
import * as BlockPricingCalculatorComponent from './blocks/pricingCalculator'
import * as BlockPricingCardsComponent from './blocks/pricingCards'
import * as BlockRelatedArticlesComponent from './blocks/relatedArticles'
import * as BlockRequestFormComponent from './blocks/requestForm'
import * as BlockRichTextComponent from './blocks/richtext'
import * as BlockSectionHeaderComponent from './blocks/sectionHeader'
import * as BlockSpotlightComponent from './blocks/spotlight'
import * as BlockSummaryComponent from './blocks/summary'
import * as BlockTeamMembersComponent from './blocks/teamMembers'
import * as Block3CardsWithImageComponent from './blocks/threeCardsWithImage'
import * as BlockTwoBlocksBannerComponent from './blocks/twoBlocksBanner'
import * as Block2ColumnsIntegrationListComponent from './blocks/twoColumnsIntegrationList'
import * as BlockUspListComponent from './blocks/uspList'
import * as BlockWistiaVideoComponent from './blocks/wistiaVideo'
import * as HubSpotFormComponent from './hubspot'

export { default as Layout } from './layout'
export { default as PageWrapper, LocationContext, SiteDataContext } from './page-wrapper'
export const BlockSpotlight = withAnchor(BlockSpotlightComponent.default)
export const BlockCrissCross = withAnchor(BlockCrissCrossComponent.default)
export const BlockCta = withAnchor(BlockCtaComponent.default)
export const BlockFeatureSimple = withAnchor(BlockFeatureSimpleComponent.default)
export const BlockFeatureList = withAnchor(BlockFeatureListComponent.default)
export const Block2ColumnsIntegrationList = withAnchor(
  Block2ColumnsIntegrationListComponent.default
)
export const BlockHero = withAnchor(BlockHeroComponent.default)
export const BlockHeroHome = withAnchor(BlockHeroHomeComponent)
export const BlockHeroHomeTwo = withAnchor(BlockHeroHomeTwoComponent)
export const BlockHeroMedia = withAnchor(BlockHeroMediaComponent.default)
export const BlockIntegrationHero = withAnchor(BlockIntegrationHeroComponent.default)
export const BlockIntegrationListing = withAnchor(BlockIntegrationListingComponent.default)
export const BlockMatchMaker = withAnchor(BlockMatchMakerComponent.default)
export const Block3CardsWithImage = withAnchor(Block3CardsWithImageComponent.default)
export const BlockOffices = withAnchor(BlockOfficesComponent.default)
export const BlockPricingCards = withAnchor(BlockPricingCardsComponent.default)
export const BlockPricingCalculator = withAnchor(BlockPricingCalculatorComponent.default)
export const BlockRequestForm = withAnchor(BlockRequestFormComponent.default)
export const BlockSectionHeader = withAnchor(BlockSectionHeaderComponent.default)
export const BlockSummary = withAnchor(BlockSummaryComponent.default)
export const BlockTeamMembers = withAnchor(BlockTeamMembersComponent.default)
export const BlockUspList = withAnchor(BlockUspListComponent.default)
export const BlockWistiaVideo = withAnchor(BlockWistiaVideoComponent.default)
export const HubSpotForm = withAnchor(HubSpotFormComponent.default)
export const BlockIntegrations = withAnchor(BlockIntegrationsComponent.default)
export const BlockRichText = withAnchor(BlockRichTextComponent.default)
export const BlockBlogTop = withAnchor(BlockBlogTopComponent.default)
export const BlockBenefitsListing = withAnchor(BlockBenefitsListingComponent.default)
export const BlockCustomersTop = withAnchor(BlockCustomersTopComponent.default)
export const BlockFaqTop = withAnchor(BlockFaqTopComponent.default)
export const BlockFeatureContact = withAnchor(BlockFeatureContactComponent.default)
export const BlockFilter = withAnchor(BlockFilterComponent.default)
export const BlockLogos = withAnchor(BlockLogosComponent.default)
export const BlockTwoBlocksBanner = withAnchor(BlockTwoBlocksBannerComponent.default)
export const BlockHeroDual = withAnchor(BlockHeroDualComponent.default)
export const BlockHubSpotCalendar = withAnchor(BlockHubSpotCalendarComponent.default)
export const BlockAnimatedImage = withAnchor(BlockAnimatedImageComponent.default)
export const BlockRelatedArticles = withAnchor(BlockRelatedArticlesComponent.default)
