import cn from 'classnames'
import React, { useContext } from 'react'

import { SiteDataContext } from '../../components'
//
import { ImageFluid } from '../../utils/image'
import ButtonNew, { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
import { FeatureCard } from '../FeatureCard'
import SectionHeader from '../SectionHeader'
import { NavLink } from '../helpers'
import { withAnchor } from '../hooks'

const ThreeCardsWithImage = ({
  title,
  eyebrow = '',
  features = [],
  classes = '',
  theme = 'light',
  cardsContainerTheme = 'light',
  action = null,
  image
}) => {
  const { isTextRtl } = useContext(SiteDataContext)

  return (
    <section
      className={cn('l-section c-cards-with-image', classes, {
        'c-cards-with-image--rtl': isTextRtl,
        'l-section--black': theme === 'dark',
        'l-section--grey': theme === 'grey'
      })}
    >
      <div className="l-container:12/12 l-container--default-spacing">
        {title && (
          <div className="l-grid l-grid--align:middle c-cards-with-image__header">
            <div className="l-container:8/12 c-cards-with-image__header__title">
              <SectionHeader title={title} eyebrow={eyebrow} inverted={theme === 'dark'} />
            </div>
            <div>{action}</div>
          </div>
        )}
        <div
          className={`c-cards-with-image__body ${!image && 'c-cards-with-image__body--withImage'}`}
        >
          {image ? (
            <ImageFluid
              className={'c-cards-with-image__image'}
              image={image}
              containImages
              width={400}
              height={400}
            />
          ) : null}
          <div
            className={`c-cards-with-image__cards-container ${
              cardsContainerTheme && `c-cards-with-image__cards-container--${cardsContainerTheme}`
            }`}
          >
            {features?.map((feature) => {
              const { id, action, title, description, icon, bulletListColor } = feature
              return (
                <NavLink key={`story-${id}`} to={action ? action.url : null}>
                  <FeatureCard
                    classes={`c-cards-with-image__card ${
                      cardsContainerTheme && `c-cards-with-image__card--${cardsContainerTheme}`
                    }`}
                    description={description}
                    title={title}
                    icon={icon}
                    bulletListColor={bulletListColor}
                  >
                    {action && (
                      <ButtonNew
                        icon="ti-arrow-right"
                        variant={BUTTON_VARIANT.SECONDARY}
                        {...action}
                        size="md"
                      >
                        {action.text}
                      </ButtonNew>
                    )}
                  </FeatureCard>
                </NavLink>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default withAnchor(ThreeCardsWithImage)
