import cn from 'classnames'
import React from 'react'

import BackgroundImage from '../../../static/images/cta-background.png'
import { SiteDataContext } from '../../components'
import RichText from '../../components/rich-text'
import { ImageFluid } from '../../utils/image'
import { withAnchor } from '../hooks'

const Cta = ({ title = '', text = null, textTokens = {}, classes = '', children }) => {
  const { isTextRtl } = React.useContext(SiteDataContext)

  return (
    <section className={cn('l-section--small c-cta', classes, { 'c-cta--rtl': isTextRtl })}>
      <ImageFluid
        className={'c-cta__bg'}
        image={{
          url: BackgroundImage
        }}
      />
      <div className="c-cta__content l-container:8/12 l-container--default-spacing">
        <h2 className="c-cta__title">{title}</h2>

        {text && (
          <div className="c-cta__text">
            <RichText data={text} tokens={textTokens} />
          </div>
        )}

        {children && <div className="c-cta__actions">{children}</div>}
      </div>
    </section>
  )
}

export default withAnchor(Cta)
