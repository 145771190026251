import cn from 'classnames'
import React, { useState } from 'react'

//
import { ButtonNew, IconNew } from '..'
import { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
import NavLink from '../helpers/nav-link'

import { filterTypes } from './index'

const Filter = ({
  itemsShown = 4,
  title = '',
  showMoreLabel = '',
  showLessLabel = '',
  items = [],
  type = '',
  inverted = false,
  selectOnClick = false,
  handleSelect = () => null
}) => {
  const [isShown, setIsShown] = useState(false)
  const handleShowMore = () => setIsShown((state) => !state)

  const LinkComponent = ({ item }) => {
    const { icon, img, label, url, active } = item

    return (
      <span className="c-filter__link-wrapper">
        <NavLink
          to={url}
          className={cn('c-filter__link', {
            'is-active': active
          })}
        >
          {icon && <IconNew classes="c-filter__link-icon" name={icon} size={28} />}

          {img ? (
            type === filterTypes.countries ? (
              <div className="c-filter__country-img-container">
                <img loading="lazy" src={img} alt={label} title={label} width="50" height="50" />
              </div>
            ) : (
              <img
                loading="lazy"
                className="c-filter__link-image"
                src={img}
                alt={label}
                title={label}
                width="32"
                height="32"
              />
            )
          ) : null}
          {label}
        </NavLink>
      </span>
    )
  }

  const getFilterItems = (items, itemsShown = items.length) => {
    return items.map((item, i) => {
      const { id, icon, img, label, value, url, buttonProps, active } = item
      return (
        <li
          key={`filter-${id}-${i}`}
          className={cn('c-filter__list-item', {
            'is-hidden': i >= itemsShown,
            'c-filter__list-item--tag': type === filterTypes.tags
          })}
        >
          {url ? (
            <LinkComponent item={item} />
          ) : (
            <ButtonNew
              classes={cn('c-filter__button c-button--filter', {
                'is-active': active
              })}
              buttonProps={{
                ...buttonProps,
                onClick: selectOnClick
                  ? () => {
                      handleSelect(value, id)
                    }
                  : undefined
              }}
              size={'md'}
              variant={BUTTON_VARIANT.TERTIARY}
            >
              {icon && <IconNew classes="c-filter__link-icon" name={icon} size={28} />}

              {img ? (
                type === filterTypes.countries ? (
                  <div className="c-filter__country-img-container">
                    <img
                      loading="lazy"
                      className="c-filter__link-image"
                      src={img}
                      alt={label}
                      title={label}
                      width="50"
                      height="50"
                    />
                  </div>
                ) : (
                  <img
                    loading="lazy"
                    className="c-filter__link-image"
                    src={img}
                    alt={label}
                    title={label}
                    width="32"
                    height="32"
                  />
                )
              ) : null}

              {label}
            </ButtonNew>
          )}
        </li>
      )
    })
  }

  return (
    <div
      className={cn('c-filter', {
        'c-filter--inverted': inverted
      })}
    >
      <h5 className="c-filter__title">{title}</h5>

      {items.length > 0 && (
        <ul
          className={cn('c-filter__list', {
            'c-filter__list--countries': type === filterTypes.countries,
            'c-filter__list--tags': type === filterTypes.tags
          })}
        >
          {isShown ? getFilterItems(items) : getFilterItems(items, itemsShown)}
        </ul>
      )}

      {itemsShown > 0 && (
        <ButtonNew
          classes={cn(
            'c-filter__action',
            inverted && 'c-filter__action--inverted',
            items.length < itemsShown ? 'is-hidden' : ''
          )}
          icon={isShown ? 'ti-chevron-up' : 'ti-chevron-down'}
          buttonProps={{
            onClick: handleShowMore
          }}
          size="md"
          variant={'tertiary'}
        >
          {isShown ? showLessLabel : showMoreLabel}
        </ButtonNew>
      )}
    </div>
  )
}

export default Filter
