import cn from 'classnames'
import React, { useContext } from 'react'

import { SiteDataContext } from '../../components'
//
import RichText from '../../components/rich-text'

const Statistic = ({
  number = null,
  unit = '',
  title = '',
  text,
  textTokens = {},
  classes = ''
}) => {
  const { isTextRtl } = useContext(SiteDataContext)

  return (
    <div className={cn('c-statistic', classes, { 'c-statistic--rtl': isTextRtl })}>
      <div className="c-statistic__titles-container">
        {number && (
          <span className="c-statistic__subtitle">
            <span>{number}</span>

            {unit}
          </span>
        )}

        {title && <h3 className="c-statistic__title">{title}</h3>}
      </div>

      {text && (
        <div className="c-statistic__text">
          <RichText data={text} tokens={textTokens} />
        </div>
      )}
    </div>
  )
}

export default Statistic
