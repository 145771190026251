import React from 'react'

//
import { ButtonNew, IntegrationSection } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { getUrl } from '../../../utils/urls'
import { LocationContext } from '../../page-wrapper'

const Block2ColumnsIntegrationList = ({
  data: {
    title,
    description,
    action,
    column1Title,
    column2Title,
    column1Integrations,
    column2Integrations
  }
}) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  const getIntegrationsList = (integrations) => {
    if (integrations?.length > 0) {
      return integrations.map(({ id, title, categories, logo, slug }) => ({
        id,
        title,
        type: categories.map((cat) => cat.title)[0],
        url: `${slugPrefix}/integrations/${slug}`.replace('//', '/'),
        logo: logo?.file?.url || '',
        size: 'medium'
      }))
    }
    return []
  }

  return (
    <IntegrationSection
      title={title || ''}
      description={description || ''}
      posTitle={column1Title || ''}
      posIntegrations={getIntegrationsList(column1Integrations)}
      channelsTitle={column2Title || ''}
      channelsIntegrations={getIntegrationsList(column2Integrations)}
    >
      {action && (
        <ButtonNew url={getUrl(action, slugPrefix)} variant={BUTTON_VARIANT.SECONDARY}>
          {action?.title || ''}
        </ButtonNew>
      )}
    </IntegrationSection>
  )
}

export default Block2ColumnsIntegrationList
