export const contentMock = {
  json: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [
              {
                type: 'bold'
              }
            ],
            value: 'Salutantibus',
            nodeType: 'text'
          },
          {
            data: {},
            marks: [],
            value: ' vitae elit libero, a pharetra augue. ',
            nodeType: 'text'
          },
          {
            data: {},
            marks: [
              {
                type: 'italic'
              }
            ],
            value: 'Quae',
            nodeType: 'text'
          },
          {
            data: {},
            marks: [],
            value:
              ' vero auctorem tractata ab fiducia dicuntur. Ab illo tempore, ab est sed immemorabili. Phasellus laoreet lorem vel dolor tempus vehicula. ',
            nodeType: 'text'
          }
        ],
        nodeType: 'paragraph'
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text'
          }
        ],
        nodeType: 'paragraph'
      }
    ],
    nodeType: 'document'
  }
}
