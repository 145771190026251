export const getContentForMarket = (contents, slugPrefixRaw) => {
  let contentToUse

  const slugPrefix = slugPrefixRaw.replace(/\//g, '')

  // iterate through all items in contents
  contents.forEach((content) => {
    // get the enabled markets for current iterated content
    const { markets } = content

    // check if any of the enabled markets match current one
    const anyMatch = markets?.some((market) => market.slugPrefix === slugPrefix) || null

    // if current content is enabled for current the market
    // and a contentToUse hasn't been set yet
    if (anyMatch && !contentToUse) {
      contentToUse = content // set current content to be the one used
    }
  })

  // if a match has been found, return it
  if (contentToUse) {
    return contentToUse
  }

  // otherwise return the first item
  return contents[0]
}
