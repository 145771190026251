import cn from 'classnames'
import React from 'react'

//
import { SiteDataContext } from '../../components'
import IconNew from '../IconNew'
import { NavLink, ConditionalWrapper } from '../helpers'

const IconLabel = ({
  url,
  icon = null,
  secondary = false,
  inverted = false,
  classes = '',
  children
}) => {
  const { isTextRtl } = React.useContext(SiteDataContext)

  const rootClasses = cn('c-icon-label', classes, {
    'c-icon-label--link': url,
    'c-icon-label--grey': secondary,
    'c-icon-label--inverted': inverted,
    'c-icon-label--rtl': isTextRtl
  })

  return (
    <ConditionalWrapper
      condition={url}
      trueWrapper={(child) => (
        <NavLink to={url} className={rootClasses}>
          {child}
        </NavLink>
      )}
      falseWrapper={(child) => <div className={rootClasses}>{child}</div>}
    >
      {icon && <IconNew classes="c-icon-label__icon" name={icon} />}

      {children}
    </ConditionalWrapper>
  )
}

export default IconLabel
