import cn from 'classnames'
import React from 'react'

export const CHIP_COLORS = Object.freeze({
  GREEN: 'green',
  PINK: 'pink',
  ORANGE: 'orange',
  YELLOW: 'yellow',
  BLUE: 'blue'
})

const Chip = ({ text, color = CHIP_COLORS.GREEN }) => {
  return <div className={cn('c-chip', `c-chip--${color}`)}>{text}</div>
}

export default Chip
