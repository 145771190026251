import cn from 'classnames'
import React from 'react'

//
import { IconNew } from '..'
import { SiteDataContext } from '../../components'
import ButtonNew, { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
import { FeatureCard } from '../FeatureCard'
import SectionHeader from '../SectionHeader'
import { withAnchor } from '../hooks'

const FEATURE_SIMPLE_BACKGROUND = Object.freeze({
  BLACK: 'black',
  GREY: 'grey'
})

const FeatureSimpleBlock = ({
  title,
  eyebrow = '',
  features,
  bulletListColor = null,
  classes = '',
  background
}) => {
  const { isTextRtl } = React.useContext(SiteDataContext)

  return (
    <section
      className={cn('c-feature-simple l-section', classes, {
        'c-feature-simple--rtl': isTextRtl,
        'l-section--grey': background === FEATURE_SIMPLE_BACKGROUND.GREY,
        'l-section--black': !background || background === FEATURE_SIMPLE_BACKGROUND.BLACK
      })}
    >
      <div className="l-container:12/12 l-container--default-spacing">
        {title && (
          <div className="l-section__header">
            <div className="l-container:6/12 u-margin-left:0">
              <SectionHeader
                title={title}
                eyebrow={eyebrow}
                inverted={background !== FEATURE_SIMPLE_BACKGROUND.GREY}
                headingType="h2"
              />
            </div>
          </div>
        )}

        <div className="l-section__content">
          <div className="l-grid l-grid--default-spacing">
            {features.map((feature) => {
              const { id, title, icon, description, textTokens, action, image, bulletListColor } =
                feature

              return (
                <FeatureCard
                  key={`story-${id}`}
                  classes="l-grid_col:12/12 l-grid__col:6/12@at:viewport-7"
                  title={title}
                  bulletListColor={bulletListColor}
                  icon={image ? null : icon}
                  description={description}
                  textTokens={textTokens}
                  image={image}
                  url={action?.url || null}
                >
                  {action && (
                    <ButtonNew variant={BUTTON_VARIANT.GREEN} size={'md'} {...action}>
                      {action.text} <IconNew name={'ti-arrow-narrow-right'} />{' '}
                    </ButtonNew>
                  )}
                </FeatureCard>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default withAnchor(FeatureSimpleBlock)
