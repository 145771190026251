import React from 'react'
//

const CareersHeadingSectionItem = ({ label, value }) => (
  <div className="c-careers__item">
    <p className="c-careers__label">{label}</p>
    <p className="c-careers__value">{value}</p>
  </div>
)

export default CareersHeadingSectionItem
