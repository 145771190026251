import React from 'react'

//
import { MicroCopyContext } from '../components/page-wrapper'

export const getMicroCopy = ({ key, tokens = {}, fallback = null, data = {} }) => {
  if (!data[key] && !fallback) {
    return key
  } else if (!data[key] && fallback) {
    return fallback
  }

  const { value } = data[key]

  const replacements = {}
  Object.keys(tokens).forEach((key) => {
    replacements[`{${key}}`] = tokens[key]
  })

  if (value && Object.keys(replacements).length) {
    const re = new RegExp(Object.keys(replacements).join('|'), 'gi')
    const result = value.replace(re, (matched) => {
      return replacements[matched]
    })

    return result
  }

  if (value) {
    return value
  }

  return fallback
}

export const MicroCopy = ({ name, tokens, fallback }) => {
  const data = React.useContext(MicroCopyContext) || {}
  const value = getMicroCopy({ key: name, tokens, fallback, data })

  return <React.Fragment>{value}</React.Fragment>
}
