import { documentToReactComponents as contentFulDocumentToReactComponents } from '@contentful/rich-text-react-renderer'
import { INLINES, BLOCKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import React, { useContext } from 'react'

import { LocationContext, SiteDataContext } from '../../components/page-wrapper'
import { getUserAgentVariableText } from '../../helpers/getUserAgentVariableText'
import { NavLink } from '../../storybook/helpers'
//
import { ImageFluid } from '../../utils/image'
import { getSlug } from '../../utils/slugs'

const processRenderText = (text, hasTokens, tokens, market) => {
  let textItem = ''

  if (hasTokens) {
    const replacements = {}
    Object.keys(tokens).forEach((key) => {
      replacements[`{${key}}`] = tokens[key]
    })

    const re = new RegExp(Object.keys(replacements).join('|'), 'gi')
    textItem = text.replace(re, (matched) => {
      return replacements[matched]
    })
  } else {
    const newText = getUserAgentVariableText(text, market)
    textItem = newText || text
  }

  return inlineText(textItem)
    .split('\n')
    .reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br />, textSegment]
    }, [])
    .map((child) => {
      if (child.type === 'br') {
        return React.cloneElement(child, { key: `${Math.random()}` })
      }

      return child
    })
}

const inlineHyperlink = (hyperlink) => {
  const { uri } = hyperlink.data
  const { value } = hyperlink?.content[0]

  if (!uri || !value) {
    return null
  }

  if (uri.indexOf('youtube.com') !== -1) {
    return (
      <div className="u-text-align:center">
        <iframe
          title={value}
          id="article-Yyt-player"
          src={uri}
          type="text/html"
          width="640"
          height="360"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture ; fullscreen"
        />
      </div>
    )
  }

  return <NavLink to={uri}>{value}</NavLink>
}

const getAsset = (locale, fields) => {
  if (fields.url) {
    const asset = { title: fields.title, file: { url: fields.url } }
    return asset
  }
  const asset = {
    title: fields.title[locale] || fields.title['en'],
    file: fields.file[locale] || fields.file['en']
  }
  return asset
}

const inlineEntryBlock = (node) => {
  // @todo: handle intl to get correct asset id
  // const intl = React.useContext(IntlContext)

  const { sys, fields } = node.data.target
  const id = sys?.contentType?.sys?.id

  if (!id || !sys || !fields) {
    return null
  }

  if (id === 'image') {
    const asset = getAsset('en', fields)

    if (!asset) {
      return null
    }

    const src = `${asset.file.url}?w=1920&q=80`
    const srcSet = `${asset.file.url}?w=480&q=80 480w,${asset.file.url}?w=960&q=80 960w,${asset.file.url}?w=1920&q=80 1920w`

    const image = {
      fluid: {
        aspectRatio: 21 / 9,
        sizes: '(max-width: 1920px) 100vw, 1920px',
        src,
        srcSet
      }
    }

    return (
      <div className="image-container">
        <ImageFluid image={image} />
      </div>
    )
  }

  return null
}

const entryAsset = (node) => {
  const fields = node?.data?.target?.fields

  if (!fields) {
    return null
  }

  if (fields.contentType && fields.contentType === 'video/mp4') {
    return (
      <video loop playsInline autoPlay muted aria-label="Video Player" style={{ width: '100%' }}>
        <source src={fields.url} type="video/mp4" />
        <track kind="captions" />
      </video>
    )
  }

  const asset = getAsset('en', fields)

  if (!asset) {
    return null
  }

  return (
    <div className="image-container">
      <ImageFluid image={asset.file} height={fields.height} width={fields.width} />
    </div>
  )
}

const gatsbyEntryAsset = (node) => {
  if (!node?.data?.target) {
    return null
  }

  return (
    <div className="image-container">
      <ImageFluid image={node?.data?.target} />
    </div>
  )
}

const contentfulEntryHyperlink = (hyperlink, entriesLinks) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  if (!hyperlink || !entriesLinks || !entriesLinks.length) {
    return null
  }

  const { value } = hyperlink?.content[0]
  const { data } = hyperlink

  const id = data?.target?.sys?.id
  const link = entriesLinks.find((entry) => entry?.sys?.id === id)

  if (!link) {
    return null
  }

  let { slug, __typename: type, ...rest } = link

  // homepage link
  if (!slug && type === 'Page') {
    slug = slug || '/'
  }

  if (!value || !slug || !type || !id) {
    return null
  }

  const content = {
    __typename: type,
    id,
    slug,
    ...rest
  }

  const path = getSlug(content, slugPrefix)

  if (!path) {
    return null
  }

  return <NavLink to={path}>{value}</NavLink>
}

const gatsbyEntryHyperlink = (hyperlink) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location
  const { data, content } = hyperlink

  if (!data?.target || !content?.[0]?.value) {
    return null
  }

  const value = content?.[0]?.value
  const { slug, __typename } = data?.target
  const id = data?.target?.sys?.id

  const entry = {
    __typename,
    id,
    slug,
    ...data?.target
  }

  const path = getSlug(entry, slugPrefix)

  if (!path) {
    return null
  }

  return <NavLink to={path}>{value}</NavLink>
}

const inlineText = (text) => {
  return /^\s+$/.test(text) ? '' : text
}

const defaultNodeRenderers = {
  [INLINES.HYPERLINK]: (node) => inlineHyperlink(node),
  [INLINES.ENTRY_HYPERLINK]: (node) => contentfulEntryHyperlink(node),
  [BLOCKS.EMBEDDED_ENTRY]: (node) => inlineEntryBlock(node),
  [BLOCKS.EMBEDDED_ASSET]: (node) => entryAsset(node)
}

const defaultMarkRenderers = {}

export function documentToReactComponents(richTextDocument, options = {}, assets, entriesLinks) {
  if (!richTextDocument) {
    return null
  }

  if (assets) {
    const content = richTextDocument?.content || []
    content.forEach((node) => {
      if (node.nodeType === 'embedded-asset-block') {
        const id = node.data?.target?.sys?.id
        assets.forEach((asset) => {
          if (asset.sys?.id === id) {
            node.data.target.fields = asset
          }
        })
      }
    })
  }

  const { tokens } = options
  const hasTokens = Object.keys(tokens).length && tokens.constructor === Object

  return contentFulDocumentToReactComponents(richTextDocument, {
    renderNode: {
      ...defaultNodeRenderers,
      [INLINES.ENTRY_HYPERLINK]: (node) => contentfulEntryHyperlink(node, entriesLinks),
      ...options.renderNode
    },
    renderMark: {
      ...defaultMarkRenderers,
      ...options.renderMark
    },
    renderText: (text) => {
      return processRenderText(text, hasTokens, tokens)
    }
  })
}

const renderRichTextToReactComponents = (data, options = {}, market) => {
  const { tokens } = options
  const hasTokens = Object.keys(tokens).length && tokens.constructor === Object

  const richTextOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => gatsbyEntryAsset(node),
      [INLINES.ENTRY_HYPERLINK]: (node) => gatsbyEntryHyperlink(node),
      [INLINES.HYPERLINK]: (node) => inlineHyperlink(node)
    },
    renderText: (text) => processRenderText(text, hasTokens, tokens, market)
  }

  return renderRichText(data, richTextOptions)
}

const RichText = ({ classes, data, tokens = {}, assets, entriesLinks, itemProp }) => {
  const settings = useContext(SiteDataContext)
  const { market } = settings

  const marketTitle = market?.localName || market?.title

  if (data?.json) {
    return (
      <div className={classes} itemProp={itemProp}>
        {documentToReactComponents(data.json, { tokens }, assets, entriesLinks)}
      </div>
    )
  } else if (data?.raw) {
    // @todo: check tokens and other embedded items
    return (
      <div className={classes} itemProp={itemProp}>
        {renderRichTextToReactComponents(data, { tokens }, marketTitle)}
      </div>
    )
  } else {
    // @todo: check if there will be any other scenarios
    return (
      (data && (
        <div className={classes} itemProp={itemProp}>
          {documentToReactComponents(data, { tokens }, assets)}
        </div>
      )) ||
      ''
    )
  }
}

export default RichText
