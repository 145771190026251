import de from 'date-fns/locale/de'
import enGB from 'date-fns/locale/en-GB'
import enUS from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'
import fr from 'date-fns/locale/fr'
import nl from 'date-fns/locale/nl'
import React from 'react'

//
import { LocationContext } from '../../../components/page-wrapper'
import RelatedArticles from '../../articles/related-articles'

const validLocales = { en: enGB, de: de, fr: fr, es: es, nl: nl, us: enUS }

const BlockRelatedArticles = ({ data }) => {
  if (!data) return null

  const getArticleCollection = () => {
    if (data?.articles) return data?.articles
    return data?.articlesCollection?.items
  }

  const { title, preface } = data

  const articles = getArticleCollection()

  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  const currentSlugPrefix = (slugPrefix || '').replace(/\//gim, '')

  let locale = currentSlugPrefix.split('-')[0] || 'en'
  if (!validLocales[locale]) {
    locale = 'en'
  }

  if (!articles?.length) return null

  return <RelatedArticles {...{ articles, slugPrefix, title, preface, locales: locale }} />
}

export default BlockRelatedArticles
