import cn from 'classnames'
import React, { useEffect, useState } from 'react'

//
import { SiteDataContext } from '../../components'
import IconNew from '../IconNew'

const Dropdown = ({
  customId = '',
  icon = null,
  items = [],
  value = '',
  label = '',
  secondary = false,
  inverted = false,
  fullWidth = false,
  rounded = false,
  handleSelect = () => null,
  classes = ''
}) => {
  const { isTextRtl } = React.useContext(SiteDataContext)
  const [dropdownActive, setDropdownActive] = useState(false)

  const handleChange = (e) => {
    if (e.target.value !== value) {
      handleSelect(e.target.value)
    }
  }

  useEffect(() => {
    if (value) {
      setDropdownActive(true)
    } else {
      setDropdownActive(false)
    }
  }, [value])

  return (
    <div
      className={cn('c-dropdown', classes, {
        'c-dropdown--secondary': secondary,
        'c-dropdown--inverted': inverted,
        'c-dropdown--has-icon': icon,
        'c-dropdown__full-width': fullWidth,
        'c-dropdown--rtl': isTextRtl
      })}
    >
      {icon && (
        <div className="c-dropdown__icon-container">
          <IconNew classes="c-dropdown__icon" name={icon} size={16} />
        </div>
      )}

      {label && (
        <label htmlFor={customId || label} className="c-dropdown__label">
          {label}
        </label>
      )}

      <select
        className={cn('c-dropdown__select', {
          'is-active': dropdownActive,
          'c-dropdown__select--rounded': rounded
        })}
        value={value}
        onChange={handleChange}
        onBlur={handleChange}
        id={customId || label}
      >
        {items.length &&
          items.map((item, index) => (
            <option
              className="c-dropdown__select-option"
              value={item.value}
              key={`${item.id}-${index}`}
            >
              {item.label}
            </option>
          ))}
      </select>

      <IconNew classes="c-dropdown__icon" name="tabler-chevron-down" />
    </div>
  )
}

export default Dropdown
