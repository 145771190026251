import cn from 'classnames'
import React from 'react'

//
import RichText from '../../components/rich-text'

const Hero = ({
  preface = '',
  title = '',
  background = null,
  content = null,
  textTokens = {},
  small = false,
  large = false,
  noVerticalPadding = false,
  textContent = false,
  align = 'center',
  children
}) => {
  return (
    <section
      className={cn('c-hero', background, {
        'c-hero--has-append': children,
        'c-hero--small': small,
        'c-hero--large': large,
        'c-hero--no-padding': noVerticalPadding
      })}
    >
      <div
        className={cn('l-container--default-spacing', {
          'l-container:10/12': large || textContent,
          'l-container:8/12': !large && !small,
          'l-container:6/12': small
        })}
      >
        {title && (
          <header
            className={cn('c-hero__header', {
              'c-hero__header--left': align === 'left'
            })}
          >
            {preface ? <p className="c-section-header__eyebrow">{preface}</p> : null}
            <h1 className="c-hero__title">{title}</h1>
          </header>
        )}

        {content && (
          <div
            className={cn('c-hero__content l-container:8/12', {
              'c-hero__content--left': align === 'left'
            })}
          >
            <RichText data={content} tokens={textTokens} />
          </div>
        )}

        {children && <div className="c-hero__append">{children}</div>}
      </div>
    </section>
  )
}

export default Hero
