import cn from 'classnames'
import React, { useContext } from 'react'

import { SiteDataContext } from '../../components'
//
import RichText from '../../components/rich-text'

const Divider = ({ text, textTokens = {} }) => {
  const { isTextRtl } = useContext(SiteDataContext)
  return (
    <>
      {text ? (
        <div className="c-divider">
          <div className={cn('c-divider__label', { 'c-divider__label--rtl': isTextRtl })}>
            <RichText data={text} tokens={textTokens} />
          </div>
        </div>
      ) : (
        <hr />
      )}
    </>
  )
}

export default Divider
