import cn from 'classnames'
import React, { memo } from 'react'

import { getMicroCopy } from '../../utils/microcopy'
import ButtonNew, { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
import IconButton from '../IconButton'

const ModalBodyMobile = ({
  microCopyData,
  categories,
  handleOnCategoryClick,
  handleClear,
  close,
  selectedCategories,
  handleSelectAll
}) => {
  return (
    <div className="c-blogFilters__modal--container--mobile">
      <div className="c-blogFilters__modal--container--mobile--header">
        <div className="c-blogFilters__modal--container--mobile--title">
          <h4>{getMicroCopy({ key: `blog.filter.categories.select`, data: microCopyData })}</h4>
        </div>
        <IconButton
          classes="c-blogFilters__modal--closeBtn"
          icon="tabler-x"
          handleClick={() => close()}
          size={24}
        />
      </div>
      <div className="c-blogFilters__modal--container--mobile--body">
        {/* <Checkbox
          textLabel={getMicroCopy({
            key: `blog.filter.categories.all`,
            data: microCopyData,
            fallback: 'All'
          })}
          checked={selectedCategories.length === categories.length}
          handleChange={() => handleSelectAll(selectedCategories.length !== categories.length)}
        />
        {categories.map((cat) => (
          <Checkbox
            key={cat.id}
            textLabel={cat.name}
            checked={cat.selected}
            handleChange={() => handleOnCategoryClick(cat.id)}
          />
        ))} */}
        <ButtonNew
          classes={cn(
            'c-blogFilters__button modal',
            selectedCategories.length === categories.length && 'active'
          )}
          buttonProps={{
            onClick: () => handleSelectAll(selectedCategories.length !== categories.length)
          }}
          fullWidth
        >
          {getMicroCopy({
            key: `blog.filter.categories.all`,
            data: microCopyData,
            fallback: 'All'
          })}
        </ButtonNew>
        {categories.map((category) => (
          <ButtonNew
            key={category.id}
            classes={cn('c-blogFilters__button modal', category.selected && 'active')}
            buttonProps={{ onClick: () => handleOnCategoryClick(category.id) }}
            fullWidth
          >
            {category.name}
          </ButtonNew>
        ))}
      </div>
      <div className="c-blogFilters__modal--container--mobile--footer">
        <ButtonNew
          variant={BUTTON_VARIANT.GREEN}
          size={'md'}
          classes="c-blogFilters__apply__button"
          buttonProps={{ onClick: () => close() }}
        >
          <span>{getMicroCopy({ key: `blog.filter.categories.apply`, data: microCopyData })}</span>
          {selectedCategories && selectedCategories.length ? (
            <span>{` (${selectedCategories.length})`}</span>
          ) : null}
        </ButtonNew>
        <ButtonNew
          classes="c-blogFilters__clear-all__button"
          variant={BUTTON_VARIANT.SECONDARY}
          size={'md'}
          fullWidth
          buttonProps={{ onClick: () => handleClear() }}
        >
          {getMicroCopy({ key: `blog.filter.categories.clear`, data: microCopyData })}
        </ButtonNew>
      </div>
    </div>
  )
}

export default memo(ModalBodyMobile)
