import React, { memo, useCallback, useMemo } from 'react'

import { MicroCopyContext } from '../../components/page-wrapper'
//
import { useModal } from '../hooks'

import DesktopFilters from './DesktopFilters.jsx'
import MobileFilters from './MobileFilters.jsx'
import ModalBodyDesktop from './ModalBodyDesktop.jsx'
import ModalBodyMobile from './ModalBodyMobile.jsx'

const NR_OF_VISIBLE_CATEGORIES = 6

const getCategories = (categories) => {
  if (!categories) {
    return {}
  }
  categories.sort((a, b) => a.order - b.order)

  const visibleCategories = categories?.slice(0, NR_OF_VISIBLE_CATEGORIES)
  const hiddenCategories = categories?.slice(NR_OF_VISIBLE_CATEGORIES)
  const selectedCategories = categories.filter((category) => category.selected)
  const selectedHiddenCategories = hiddenCategories.filter((r) => selectedCategories.includes(r))

  return { visibleCategories, hiddenCategories, selectedCategories, selectedHiddenCategories }
}

const BlogFilters = ({ categories, setCategories }) => {
  const microCopyData = React.useContext(MicroCopyContext)
  const [Modal, open, close] = useModal({
    preventScroll: false,
    closeOnOverlayClick: true
    // hideHeader: true
  })

  const handleOnCategoryClick = useCallback(
    (categoryId) => {
      const updateCategories = categories.map((category) => {
        let selected = category.selected
        if (category.id === categoryId) {
          selected = !category.selected
        }
        return { ...category, selected }
      })

      setCategories(updateCategories)
    },
    [categories]
  )

  const handleSelectAll = useCallback(
    (state) => {
      const updateCategories = categories.map((category) => {
        return { ...category, selected: state }
      })

      setCategories(updateCategories)
    },
    [categories]
  )

  const handleClear = useCallback(() => {
    const updateCategories = categories.map((category) => {
      return { ...category, selected: false }
    })
    setCategories(updateCategories)
  }, [categories])

  const { visibleCategories, selectedHiddenCategories, selectedCategories } = useMemo(
    () => getCategories(categories),
    [categories]
  )

  const sortCategoriesAlphabetically = () => {
    return [...categories].sort((a, b) => a.name.localeCompare(b.name))
  }

  if (!categories) {
    return null
  }

  return (
    <>
      <div className="c-blogFilters">
        <DesktopFilters
          visibleCategories={visibleCategories}
          handleOnCategoryClick={handleOnCategoryClick}
          nrOfVisibleCategories={NR_OF_VISIBLE_CATEGORIES}
          categories={categories}
          selectedHiddenCategories={selectedHiddenCategories}
          open={open}
        />
        <MobileFilters
          microCopyData={microCopyData}
          selectedCategories={selectedCategories}
          open={open}
        />

        {/* {selectedCategories.length ? (
          <SelectedCategories
            totalArticles={totalArticles}
            microCopyData={microCopyData}
            selectedCategories={selectedCategories}
            handleOnCategoryClick={handleOnCategoryClick}
            handleClear={handleClear}
          />
        ) : null} */}
      </div>
      <Modal>
        <ModalBodyDesktop
          microCopyData={microCopyData}
          selectedCategories={selectedCategories}
          handleOnCategoryClick={handleOnCategoryClick}
          handleClear={handleClear}
          categories={sortCategoriesAlphabetically()}
          close={close}
          handleSelectAll={handleSelectAll}
        />
        <ModalBodyMobile
          microCopyData={microCopyData}
          handleOnCategoryClick={handleOnCategoryClick}
          handleClear={handleClear}
          categories={sortCategoriesAlphabetically()}
          close={close}
          selectedCategories={selectedCategories}
          handleSelectAll={handleSelectAll}
        />
      </Modal>
    </>
  )
}

export default memo(BlogFilters)
