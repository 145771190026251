import cn from 'classnames'
import { gsap } from 'gsap'
import React, { Children, memo, useEffect, useRef, useState, useContext } from 'react'

import { SiteDataContext } from '../../components'
import ButtonNew, { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
import { ALIGN, HEADING_TYPE } from '../SectionHeader'
import SectionHeader from '../SectionHeader/SectionHeader'
import { withAnchor } from '../hooks'
//

const Matchmaker = ({
  title = '',
  subtitle = '',
  matchTitle = '',
  matchSubtitle = '',
  matchAction = {},
  matched = false,
  children,
  classes = ''
}) => {
  const { isTextRtl } = useContext(SiteDataContext)
  const [isMatched, setIsMatched] = useState(false)
  const tween = useRef(null)

  useEffect(() => {
    if (isMatched && matched) {
      tween.current = gsap.to('.c-matchmaker__header', {
        opacity: 1,
        duration: 0.4
      })
    } else if (!matched) {
      tween.current = gsap.timeline()

      tween.current
        .to(['.c-matchmaker__logo', '.c-matchmaker__integrations'], {
          autoAlpha: 1,
          duration: 0.4
        })
        .to(
          '.c-matchmaker__header',
          {
            opacity: 1,
            duration: 0.4
          },
          '>-0.2'
        )
        .to(
          '.c-matchmaker__background-circle',
          {
            scale: 0.5,
            duration: 0.5,
            ease: 'back.out(2)'
          },
          0
        )
    }

    return () => {
      if (tween) {
        tween.current.kill()
      }
    }
  }, [isMatched])

  useEffect(() => {
    tween.current = gsap.timeline({
      onComplete: () => {
        setIsMatched(matched)
      }
    })

    if (matched && !isMatched) {
      tween.current
        .to(['.c-matchmaker__logo', '.c-integration-dropdown', '.c-matchmaker__middle-logo'], {
          autoAlpha: 0,
          duration: 0.4,
          display: 'none'
        })
        .to(
          '.c-matchmaker__header',
          {
            opacity: 0,
            duration: 0.4
          },
          '>-0.2'
        )
        .to('.c-matchmaker__background-circle', {
          scale: 1,
          duration: 0.5,
          ease: 'back.out(2)'
        })
        .to(
          '.c-matchmaker__circle',
          {
            scale: '+=2',
            duration: 0.5,
            stagger: {
              each: 0.08,
              from: 'end'
            },
            ease: 'back.out(2)'
          },
          '<'
        )
    } else if (isMatched) {
      tween.current
        .to(
          '.c-matchmaker__circle',
          {
            scale: '-=2',
            duration: 0.5,
            stagger: {
              each: 0.08,
              from: 'end'
            },
            ease: 'back.out(2)'
          },
          '<'
        )
        .to(
          '.c-matchmaker__header',
          {
            opacity: 0,
            duration: 0.4
          },
          '>-0.2'
        )
    }

    return () => {
      if (tween) {
        tween.current.kill()
      }
    }
  }, [matched])

  return (
    <section
      className={cn('c-matchmaker l-section l-section--black u-block-gap', classes)}
      dir={isTextRtl ? 'rtl' : 'lfr'}
    >
      <div className="c-matchmaker__overflow">
        <div
          className={cn('c-matchmaker-bg c-matchmaker-bg__top-left', {
            'c-matchmaker-bg--match c-matchmaker-bg__top-left--match': isMatched
          })}
        ></div>
        <div
          className={cn('c-matchmaker-bg c-matchmaker-bg__top-right', {
            'c-matchmaker-bg--match c-matchmaker-bg__top-right--match': isMatched
          })}
        ></div>
        <div
          className={cn('c-matchmaker-bg c-matchmaker-bg__bottom-left', {
            'c-matchmaker-bg--match c-matchmaker-bg__bottom-left--match': isMatched
          })}
        ></div>
        <div
          className={cn('c-matchmaker-bg c-matchmaker-bg__bottom-right', {
            'c-matchmaker-bg--match c-matchmaker-bg__bottom-right--match': isMatched
          })}
        ></div>
      </div>

      <div className="l-container:6/12 l-container--default-spacing c-matchmaker__header">
        <SectionHeader
          eyebrow={isMatched ? matchSubtitle : subtitle}
          title={isMatched ? matchTitle : title}
          headingType={HEADING_TYPE.h2}
          align={ALIGN.center}
          inverted
        />
      </div>

      <div
        className={cn('c-matchmaker__integrations l-container:10/12 l-container--default-spacing')}
      >
        {Children.toArray(children)[0]}

        {isMatched ? (
          <ButtonNew
            variant={BUTTON_VARIANT.GREEN}
            classes="c-matchmaker__button"
            url={matchAction.url || null}
          >
            {matchAction.label}
          </ButtonNew>
        ) : (
          Children.toArray(children)[1]
        )}

        {Children.toArray(children)[2]}
      </div>
    </section>
  )
}

export default withAnchor(memo(Matchmaker))
