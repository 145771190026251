import React from 'react'

import { getMicroCopy } from '../../utils/microcopy'
//
import ButtonNew from '../ButtonNew'
import { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'

const MobileFilters = ({ microCopyData, selectedCategories, open }) => {
  return (
    <div className="c-blogFilters__container-mobile">
      <ButtonNew
        iconLeft="ti-layout-2"
        classes="c-blogFilters__button categories"
        onClick={() => open()}
        size={'md'}
        fullWidth
        variant={BUTTON_VARIANT.SECONDARY}
      >
        <span>{getMicroCopy({ key: `blog.filter.categories.label`, data: microCopyData })}</span>
        {selectedCategories.length ? (
          <span className="c-blogFilters__numberTip">{selectedCategories.length}</span>
        ) : null}
      </ButtonNew>
    </div>
  )
}

export default MobileFilters
