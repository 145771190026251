import cn from 'classnames'
import React, { useRef, memo, useState, useContext } from 'react'

//
import { SiteDataContext } from '../../components'
import IconNew from '../IconNew'
import { useOnClickOutside } from '../hooks'

const IntegrationDropdown = ({
  value = null,
  placeholder = '',
  isOpen = false,
  handleIsOpen = () => null,
  children,
  classes = ''
}) => {
  const { isTextRtl } = useContext(SiteDataContext)
  const rootEl = useRef(null)
  const [itemsLoaded, setItemsLoaded] = useState(false)

  useOnClickOutside(rootEl, (e) => {
    if (isOpen) {
      e.stopImmediatePropagation()
    }
    handleIsOpen(false)
  })

  return (
    <div
      ref={rootEl}
      className={cn('c-integration-dropdown', classes, {
        'is-open': isOpen,
        'c-integration-dropdown--rlt': isTextRtl
      })}
    >
      <button
        type="button"
        onClick={() => {
          if (!itemsLoaded && isOpen) {
            setItemsLoaded(true)
          }
          handleIsOpen(!isOpen)
        }}
        className="c-integration-dropdown__trigger"
      >
        {value ? (
          <div className="c-integration-dropdown__value">
            <img
              loading="lazy"
              className="c-integration-dropdown__value-logo"
              src={value.logo}
              alt={value.label}
              title={value.label}
              width="28"
              height="28"
            />

            {value.label}
          </div>
        ) : (
          <p className="c-integration-dropdown__placeholder">{placeholder}</p>
        )}

        <IconNew classes="c-integration-dropdown__arrow" name="tabler-chevron-down" size={40} />
      </button>

      <div className="c-integration-dropdown__results">{(isOpen || itemsLoaded) && children}</div>
    </div>
  )
}

export default memo(IntegrationDropdown)
