import cn from 'classnames'
import React, { forwardRef } from 'react'

//
import { SiteDataContext } from '../../components'
import { replaceSpaceWithDash } from '../../utils/replace-space-dash'
import Icon from '../IconNew'
import YoutubeWrapper from '../Media/YoutubeWrapper'
import { NavLink } from '../helpers'

export const BUTTON_VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  GREEN: 'green'
}

const ConditionalWrapper = ({
  condition,
  videoCondition,
  trueWrapper,
  falseWrapper,
  videoWrapper,
  children
}) => {
  if (videoCondition) {
    return videoWrapper(children)
  }

  return condition ? trueWrapper(children) : falseWrapper(children)
}

const ButtonNew = (
  {
    url,
    icon = null,
    iconLeft = null,
    fullWidth = false,
    size = 'bg',
    variant = 'primary',
    videoId = '',
    anchorProps,
    buttonProps,
    classes = '',
    children,
    trackingId,
    subtext = null,
    ...rest
  },
  ref
) => {
  const { isTextRtl } = React.useContext(SiteDataContext)

  const getTrackingId = () => {
    if (trackingId && typeof trackingId === 'string') return replaceSpaceWithDash(trackingId)
    return null
  }

  const rootClasses = cn('new-c-button', classes, {
    'new-c-button--small': size === 'md',
    'new-c-button--secondary': variant === 'secondary',
    'new-c-button--tertiary': variant === 'tertiary',
    'new-c-button--green': variant === 'green',
    'new-c-button--full-width': fullWidth,
    'c-button--rtl': isTextRtl
  })

  const altIcon = icon && icon.includes('topic')

  const isVideo = typeof url === 'object'

  return (
    <ConditionalWrapper
      condition={url}
      videoCondition={isVideo && url.videoId}
      trueWrapper={(child) =>
        subtext ? (
          <NavLink
            to={!isVideo ? url : null}
            trackingId={getTrackingId()}
            {...anchorProps}
            ref={ref}
          >
            <button className={rootClasses}>{child}</button>
            {subtext ? (
              <div className="u-action-subtext">
                <div> {subtext} </div>
              </div>
            ) : null}
          </NavLink>
        ) : (
          <NavLink
            trackingId={getTrackingId()}
            {...anchorProps}
            to={!isVideo ? url : null}
            className={rootClasses}
            ref={ref}
          >
            {child}
          </NavLink>
        )
      }
      falseWrapper={(child) => (
        <button
          id={getTrackingId()}
          {...buttonProps}
          className={rootClasses}
          ref={ref}
          type="button"
          {...rest}
        >
          {child}
        </button>
      )}
      videoWrapper={(child) => (
        <YoutubeWrapper videoId={url.videoId}>
          <button
            id={getTrackingId()}
            {...buttonProps}
            className={rootClasses}
            ref={ref}
            type="button"
            {...rest}
          >
            {child}
          </button>
        </YoutubeWrapper>
      )}
    >
      {iconLeft && (
        <Icon
          classes={cn('new-c-button__icon', {
            'new-c-button__icon--alt': altIcon
          })}
          name={iconLeft}
        />
      )}
      <span>{children}</span>

      {icon && (
        <Icon
          classes={cn('new-c-button__icon', {
            'new-c-button__icon--alt': altIcon
          })}
          name={icon}
        />
      )}
    </ConditionalWrapper>
  )
}

export default forwardRef(ButtonNew)
