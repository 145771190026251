import { getMinOrMaxValue } from '../utils/min-max-value'

/**
 * @param {*} event = nativeEvent (InputEvent)
 * @param {*} cb = function returning updated value (setState())
 * @param {*} min = min input value
 * @param {*} max = max input value
 * @returns setState(value) where returned value are only numeric digits
 */
export const getNumberInputValue = (event, cb, min = 0, max) => {
  const value = event.target.value.replace(/\D/g, '')
  const result = getMinOrMaxValue(max, min, value)

  if (min !== undefined && max !== undefined) return cb(result)

  return cb(value)
}
