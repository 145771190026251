import React, { memo } from 'react'

import { IconNew } from '..'
import { getMicroCopy } from '../../utils/microcopy'
import ButtonNew, { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
//

const SelectedCategories = ({
  totalArticles,
  microCopyData,
  selectedCategories,
  handleOnCategoryClick,
  handleClear
}) => {
  return (
    <div className="c-blogFilters__container-selected l-container:12/12 l-container--default-spacing">
      <div className="c-blogFilters__container-selected__wrapper">
        <div className="c-blogFilters__container-selected__results">
          <span className="c-blogFilters__container-selected__results__total">{totalArticles}</span>
          <span className="c-blogFilters__container-selected__results__label">
            {getMicroCopy({ key: `blog.filter.results`, data: microCopyData })}
          </span>
        </div>
        <div className="c-blogFilters__container-selected__categories">
          {selectedCategories.map((sc) => (
            <div className="c-blogFilters__selected--category" key={sc.id}>
              <span className="c-blogFilters__selected--category-label">{sc.name}</span>
              <IconNew
                classes="c-blogFilters__selected--category_close"
                name="tabler-x"
                onClick={() => handleOnCategoryClick(sc.id)}
                weight="700"
                size={16}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="c-blogFilters__clear-all">
        <ButtonNew
          classes="c-blogFilters__clear-all__button"
          size={'md'}
          variant={BUTTON_VARIANT.TERTIARY}
          buttonProps={{ onClick: () => handleClear() }}
        >
          {getMicroCopy({ key: `blog.filter.clearAll`, data: microCopyData })}
        </ButtonNew>
      </div>
    </div>
  )
}

export default memo(SelectedCategories)
