import React from 'react'

//
import IconNew from '../IconNew'

const Socials = ({ title = '', socials = [] }) => {
  return (
    <div className="c-socials">
      {title && <span className="c-socials__title">{title}</span>}

      <ul className="c-socials__list">
        {socials.map((item) => {
          const socialIcon = `${item.label.toLowerCase()}`

          return (
            <li key={item.id} className="c-socials__list-item">
              <a
                className="c-socials__list-item-link"
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                title={item.label}
              >
                <IconNew name={socialIcon} size={24} social={true} />
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Socials
