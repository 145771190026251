import PropTypes from 'prop-types'
import React from 'react'

import { LocationContext } from '../../components'
import { ImageFluid } from '../../utils/image'
import { getUrl } from '../../utils/urls'
import Icon from '../IconNew'
import { NavLink } from '../helpers'

const SubNav = ({ isOpen, close, subItems, title, ...props }) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  return (
    <div className={`c-subnav ${isOpen ? 'is-open' : ''}`}>
      <div className="c-subnav__header ">
        <button
          className={'c-subnav__header__icon'}
          aria-label={'close button'}
          onClick={() => {
            close()
          }}
        >
          <Icon name={'tabler-arrow-left'} classes={''} />
        </button>
        {title}
      </div>
      <div>
        {subItems.map((dropdown) => (
          <div key={dropdown.id}>
            <div className="c-subnav__preface">{dropdown.title}</div>
            <div className="c-subnav__navContainer">
              {dropdown.navigationItems
                ? dropdown.navigationItems.map((item) => {
                    return (
                      <NavLink key={item.id} to={getUrl(item, slugPrefix)}>
                        <div className="c-subnav__subItem">
                          <div className="c-subnav__subItem__label">
                            {item.icon ? (
                              <Icon name={item.icon} classes={'c-subnav__subItem__label-icon'} />
                            ) : item.logo ? (
                              <ImageFluid
                                image={{
                                  ...item.logo.file
                                }}
                                width={28}
                                height={28}
                                containImages={true}
                              />
                            ) : null}
                            {item.title}
                          </div>
                          <Icon name={'tabler-chevron-right'} classes={'c-subnav__subItem__icon'} />
                        </div>
                      </NavLink>
                    )
                  })
                : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SubNav

SubNav.propTypes = {
  isOpen: PropTypes.bool,
  subItems: PropTypes.array,
  title: PropTypes.string,
  close: PropTypes.func
}
