import cn from 'classnames'
import React from 'react'

//
import { SiteDataContext } from '../../components'
import CFRichText from '../../components/rich-text'

const RichText = ({
  content = null,
  assets = null,
  entriesLinks = null,
  textTokens = {},
  classes = ''
}) => {
  const { isTextRtl } = React.useContext(SiteDataContext)

  return (
    <div className={cn('s-wysiwyg-editor', classes, { 's-wysiwyg-editor--rtl': isTextRtl })}>
      <CFRichText data={content} tokens={textTokens} assets={assets} entriesLinks={entriesLinks} />
    </div>
  )
}

export default RichText
