export const HEADING_TYPE = Object.freeze({
  h1: 'h1',
  h2: 'h2',
  h3: 'h3'
})

export const ALIGN = Object.freeze({
  left: 'left',
  center: 'center'
})
