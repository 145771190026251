import cn from 'classnames'
import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useRef, useState } from 'react'

//
import { ButtonNew, IconNew } from '..'
import { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'

const SearchInput = ({
  placeholder = '',
  searchLabel = 'Search',
  defaultValue = '',
  handleSearch = () => null,
  instantSearch = true,
  debounceDelay = 400,
  showButton = true,
  onButtonClick = () => null,
  classes
}) => {
  const [value, setValue] = useState(defaultValue)
  const [buttonWidth, setButtonWidth] = useState(100)

  const buttonEl = useRef(null)

  const delayedSearch = useCallback(
    debounce((val) => handleSearch(val), debounceDelay),
    [debounceDelay]
  )

  useEffect(() => {
    if (showButton) {
      const width = buttonEl.current.clientWidth
      setButtonWidth(width)
    }
  }, [searchLabel])

  const handleChange = (e) => {
    setValue(e.target.value)
    if (instantSearch) {
      delayedSearch(e.target.value)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    handleSearch(value)
  }

  return (
    <form className={cn('c-search-input', classes)} onSubmit={handleSubmit}>
      <IconNew name="ti-search" classes="c-search-input__icon" />

      <input
        type="text"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className="c-search-input__field"
        aria-label={searchLabel}
        style={{
          paddingRight: `${(showButton ? buttonWidth : 10) + 20}px`
        }}
      ></input>

      {showButton ? (
        <ButtonNew
          ref={buttonEl}
          buttonProps={{
            type: 'submit',
            onClick: (e) => onButtonClick(e, value)
          }}
          variant={BUTTON_VARIANT.GREEN}
          size={'md'}
          classes="c-search-input__submit c-button--round"
        >
          {searchLabel}
        </ButtonNew>
      ) : null}
    </form>
  )
}

export default SearchInput
