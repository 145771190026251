import React, { useContext, useState } from 'react'

import { MicroCopyContext } from '../../components/page-wrapper'
import { getMicroCopy } from '../../utils/microcopy'
import ButtonNew, { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
import IconNew from '../IconNew'
import { NavLink } from '../helpers'

export default function NotificationBar({ notifications, slugPrefix }) {
  const microCopyData = useContext(MicroCopyContext)
  const [currentMessageNr, setCurrentMessageNr] = useState(1)

  const handleSetCurrentMessageNr = (nextNr) => {
    if (!notifications) return

    if (nextNr > notifications.length) {
      setCurrentMessageNr(1)
      return
    }
    if (nextNr < 1) {
      setCurrentMessageNr(notifications.length)
      return
    }

    setCurrentMessageNr(nextNr)
  }

  const sliderBtnAriaLabel = getMicroCopy({
    key: 'global.notificationsBar',
    fallback: 'Notification bar button',
    data: microCopyData
  })

  const setClass = (num) => {
    const classArr = ['c-notification-bar__notification-message']
    if (num === currentMessageNr) classArr.push('present')
    if (num > currentMessageNr) classArr.push('next')
    if (num < currentMessageNr) classArr.push('previous')
    return classArr.join(' ')
  }

  if (!notifications) {
    return null
  }

  return (
    <div className="c-notification-bar">
      <ButtonNew
        className="c-notification-bar__slider-button"
        onClick={() => handleSetCurrentMessageNr(currentMessageNr - 1)}
        variant={BUTTON_VARIANT.TERTIARY}
        size={'md'}
        aria-label={sliderBtnAriaLabel}
      >
        <IconNew
          name="tabler-chevron-down"
          classes="c-notification-bar__slider-button__icon left"
          size={24}
        />
      </ButtonNew>
      <div className="c-notification-bar__notifications-container">
        {notifications.map(({ notificationMessage, url, accessibleText }, index) =>
          url && url !== '#' ? (
            <NavLink key={`notification-${index}`} to={url} className={setClass(index + 1)}>
              <span>{notificationMessage || ''}</span>
              {accessibleText && <span className="sr-only">{accessibleText}</span>}
            </NavLink>
          ) : (
            <div key={`notification-${index}`} className={setClass(index + 1)}>
              <span>{notificationMessage || ''}</span>
            </div>
          )
        )}
      </div>
      <ButtonNew
        className="c-notification-bar__slider-button"
        onClick={() => handleSetCurrentMessageNr(currentMessageNr + 1)}
        aria-label={sliderBtnAriaLabel}
        variant={BUTTON_VARIANT.TERTIARY}
        size={'md'}
      >
        <IconNew
          name="tabler-chevron-down"
          classes="c-notification-bar__slider-button__icon right"
          size={24}
        />
      </ButtonNew>
    </div>
  )
}
