import React from 'react'

//
import { ButtonGroup, ButtonNew, SectionHeader } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { ALIGN, HEADING_TYPE } from '../../../storybook/SectionHeader'
import { getUrl } from '../../../utils/urls'
import { LocationContext } from '../../page-wrapper'

const BlockHero = ({ data: { title, description, primaryAction, secondaryAction } }) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  return (
    <section data-variant="narrow" className="l-section">
      <div className="l-container:8/12 l-container--default-spacing">
        <SectionHeader
          title={title || ''}
          text={description || ''}
          headingType={HEADING_TYPE.h1}
          align={ALIGN.center}
        >
          {(primaryAction || secondaryAction) && (
            <ButtonGroup center>
              {primaryAction && (
                <ButtonNew
                  url={getUrl(primaryAction, slugPrefix)}
                  icon={primaryAction?.icon || ''}
                  subtext={primaryAction.subtext}
                  variant={BUTTON_VARIANT.GREEN}
                >
                  {primaryAction?.title || ''}
                </ButtonNew>
              )}
              {secondaryAction && (
                <ButtonNew
                  url={getUrl(secondaryAction, slugPrefix)}
                  icon={secondaryAction?.icon || ''}
                  variant={BUTTON_VARIANT.SECONDARY}
                  subtext={secondaryAction.subtext}
                >
                  {secondaryAction?.title || ''}
                </ButtonNew>
              )}
            </ButtonGroup>
          )}
        </SectionHeader>
      </div>
    </section>
  )
}

export default BlockHero
