import cn from 'classnames'
import React, { lazy, Suspense, memo } from 'react'

import useIsClient from '../hooks/is-client'

const Icon = ({ name = 'interface/arrow-right', size = 24, classes = '', ...rest }) => {
  const { isClient, key } = useIsClient()

  if (!isClient) {
    return <></>
  }

  const ClientSideOnlyIcon = lazy(() => import(`./icons/${name}.svg`))

  return (
    <Suspense fallback="" key={key}>
      <ClientSideOnlyIcon
        {...rest}
        className={cn(classes, {
          'c-icon--interface': name.includes('interface'),
          'c-icon--social': name.includes('social')
        })}
        width={size}
        height={size}
      />
    </Suspense>
  )
}

export default memo(Icon)
