import { gsap } from 'gsap'
import React, { useEffect } from 'react'

import { ImageFluid } from '../../../utils/image'
import { Wistia } from '../../Media'
import { NavLink } from '../../helpers'

//
import ipad from './ipad-img.png'

const HomeVisual = ({
  videoId = '',
  aspectRatio = {},
  wistiaOptions = {},
  title = '',
  systems = [],
  image = {},
  integrationsUrl
}) => {
  const integrations = systems.length || title

  useEffect(() => {
    const tl = gsap.timeline({ delay: 1 })
    tl.fromTo(
      '.c-home-visual__meta',
      {
        x: '100%',
        y: '-50%'
      },
      {
        x: 0,
        duration: 0.6,
        ease: 'power2.out'
      }
    ).fromTo(
      '.c-home-visual__image',
      {
        opacity: 0,
        x: 30
      },
      {
        opacity: 1,
        x: 0,
        duration: 0.5,
        stagger: 0.12
      },
      '<-0.2'
    )

    return () => {
      tl.kill()
    }
  }, [])

  return (
    <div className="c-home-visual">
      <div className="c-home-visual__container">
        <div className="c-home-visual__device">
          <img src={ipad} alt="iPad" width="680" height="500" />
        </div>
        {image && (image?.fluid || image?.url) && (
          <div className="c-home-visual__overlay">
            <ImageFluid image={image} />
          </div>
        )}
        <div className="c-home-visual__video">
          <Wistia
            key={`wistia-${videoId}`}
            videoId={videoId}
            aspectRatioX={aspectRatio.x}
            aspectRatioY={aspectRatio.y}
            {...wistiaOptions}
          />
        </div>
      </div>

      {integrations && (
        <div className="c-home-visual__meta">
          {title && (
            <NavLink className="c-home-visual__title" to={integrationsUrl}>
              {title}
            </NavLink>
          )}

          <div className="c-home-visual__systems">
            {systems.map((system) =>
              system.url ? (
                <NavLink key={system.id} className="c-home-visual__link" to={system?.url}>
                  <img
                    loading="lazy"
                    alt={system.title}
                    title={system.title}
                    src={system.image}
                    className="c-home-visual__image"
                    width="76"
                    height="76"
                  />
                </NavLink>
              ) : (
                <img
                  loading="lazy"
                  key={system.id}
                  alt={system.title}
                  title={system.title}
                  src={system.image}
                  className="c-home-visual__image"
                  width="76"
                  height="76"
                />
              )
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default HomeVisual
