import cn from 'classnames'
import React from 'react'

//
import ArticleCard from '../ArticleCard'
import SectionHeader from '../SectionHeader'
import { withAnchor } from '../hooks'

const Articles = ({
  title,
  eyebrow = '',
  actionSlot,
  articles,
  footerSlot,
  hideDescription = false,
  classes = ''
}) => {
  return (
    <section className={cn('c-articles l-section', classes)}>
      <div className="l-container:12/12 l-container--default-spacing">
        {title && (
          <div className="l-section__header">
            <div className="l-container:8/12 u-margin-left:0">
              <SectionHeader title={title} eyebrow={eyebrow}>
                {actionSlot}
              </SectionHeader>
            </div>
          </div>
        )}

        {articles && (
          <div className="l-section__content">
            <div className="l-grid l-grid--default-spacing">
              {articles?.map((story) => {
                const {
                  id,
                  eyebrow,
                  image,
                  video,
                  title,
                  description,
                  date,
                  readtime,
                  url,
                  anchor,
                  type,
                  icon
                } = story

                return (
                  <ArticleCard
                    key={`story-${id}`}
                    eyebrow={eyebrow}
                    image={image}
                    video={video}
                    title={title}
                    icon={icon}
                    description={!hideDescription ? description : null}
                    date={date}
                    readtime={readtime}
                    url={url}
                    anchor={anchor}
                    classes={cn(
                      'l-grid_col:12/12 l-grid__col:6/12@at:viewport-7 l-grid__col:4/12@at:viewport-9',
                      {
                        'c-article-card--customer': type && type === 'ContentfulCustomer'
                      }
                    )}
                  />
                )
              })}
            </div>
          </div>
        )}

        {footerSlot && <div className="l-section__footer">{footerSlot}</div>}
      </div>
    </section>
  )
}

export default withAnchor(Articles)
