import cn from 'classnames'
import debounce from 'lodash/debounce'
import React, { useCallback, useState } from 'react'

//
import IconNew from '../IconNew'
import SearchResult from '../SearchResult'

const IntegrationResults = ({
  items = [],
  placeholder = '',
  handleSearch = () => null,
  handleClick = () => null,
  debounceDelay = 400,
  emptyState = '',
  classes = ''
}) => {
  const [value, setValue] = useState('')

  const delayedSearch = useCallback(
    debounce((val) => handleSearch(val), debounceDelay),
    [debounceDelay]
  )

  const handleChange = (e) => {
    setValue(e.target.value)

    delayedSearch(e.target.value)
  }

  const renderItem = (item) => (
    <li key={item.id} className="c-integration-results__item c-integration-results__item--result">
      <SearchResult label={item.label} logo={item.logo} handleClick={() => handleClick(item)} />
    </li>
  )

  return (
    <div className={cn('c-integration-results', classes)}>
      <div className="c-integration-results__input">
        <IconNew name="tabler-search" classes="c-integration-results__icon" size={16} />

        <input
          type="text"
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          className="c-integration-results__field"
        ></input>
      </div>

      {items && items.length > 0 && (
        <ul className="c-integration-results__list">
          {items.map((item) => {
            if (item.items && item.items.length > 0) {
              return (
                <li key={item.id} className="c-integration-results__item">
                  {item.label && <span className="c-integration-results__group">{item.label}</span>}

                  <ul className="c-integration-results__list">
                    {item.items.map((result) => renderItem(result))}
                  </ul>
                </li>
              )
            } else {
              return renderItem(item)
            }
          })}
        </ul>
      )}

      {emptyState && (!items || !items.length) && (
        <div className="c-integration-results__empty-state">{emptyState}</div>
      )}
    </div>
  )
}

export default IntegrationResults
