import cn from 'classnames'
import React from 'react'

//
import { THEME } from '../../utils/common'
import BoardMemberCard from '../BoardMemberCard/BoardMemberCard'
import SectionHeader, { HEADING_TYPE } from '../SectionHeader'
import { withAnchor } from '../hooks'

const OfficesBlock = ({
  title,
  eyebrow = '',
  actionSlot,
  items = [],
  classes = '',
  theme = THEME.light
}) => {
  return (
    <section
      className={cn('l-section', classes, {
        'l-section--dark u-block-gap': theme === THEME.dark
      })}
    >
      <div className="l-container:12/12 l-container--default-spacing">
        <div className="l-section__content">
          <div className="l-grid l-grid--default-spacing">
            {title && (
              <div className="l-section__header l-grid__col:12/12 l-grid__col:6/12@at:viewport-7 l-grid__col:4/12@at:viewport-9">
                <div className="l-container:8/12 u-margin-left:0">
                  <SectionHeader
                    title={title}
                    eyebrow={eyebrow}
                    headingType={HEADING_TYPE.h3}
                    inverted={theme === THEME.dark}
                    classes={'c-offices-block__heading'}
                  >
                    {actionSlot}
                  </SectionHeader>
                </div>
              </div>
            )}
            {items.length > 0 &&
              items.map((story) => {
                const { id, description, image, title, action } = story
                return (
                  <div
                    key={`offices-${id}`}
                    className="l-grid l-grid--align:center l-grid__col:12/12 l-grid__col:6/12@at:viewport-7 l-grid__col:4/12@at:viewport-9"
                  >
                    <BoardMemberCard
                      image={image}
                      title={title}
                      description={description}
                      inverted={true}
                      classes={'c-offices-block__card'}
                      action={action}
                    />
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default withAnchor(OfficesBlock)
