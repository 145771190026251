import { useMemo, useState } from 'react'

import { getNumberInputValue } from '../../helpers/getNumberInputValue'
import { PRICING } from '../../utils/common'
import { getPercentage } from '../../utils/percentage'

export const useOrdersPrice = (pricingOrders, orderErrorPercentage, reductionErrorPercentage) => {
  const [storedSliderValue, setStoredSliderValue] = useState(
    pricingOrders.initialMonthlyNumberOfOrders || PRICING.DEFAULT_ORDERS_VALUE
  )
  const [orders, setOrders] = useState(
    pricingOrders.initialMonthlyNumberOfOrders || storedSliderValue
  )
  const [orderValue, setOrderValue] = useState(
    pricingOrders?.initialOrderValue || PRICING.DEFAULT_ORDERS_VALUE
  )
  const [locationValue, setLocationValue] = useState(
    pricingOrders?.initialNumberOfLocations || PRICING.DEFAULT_VALUE
  )

  const minNumberOfLocations = pricingOrders?.minNumberOfLocations || PRICING.DEFAULT_VALUE
  const minOrderValue = pricingOrders?.minOrderValue || PRICING.DEFAULT_ORDERS_VALUE
  const maxOrdersLimit = pricingOrders?.maxOrdersLimit || PRICING.DEFAULT_ORDERS_LIMIT

  const handleOrderChange = (e) =>
    getNumberInputValue(e, setOrders, PRICING.DEFAULT_ORDERS_VALUE, maxOrdersLimit)

  const handleOrderValueChange = (e) =>
    getNumberInputValue(e, setOrderValue, minOrderValue, pricingOrders?.maxOrderValue)

  const handleLocationChange = (e) =>
    getNumberInputValue(
      e,
      setLocationValue,
      minNumberOfLocations,
      pricingOrders?.maxNumberOfLocations
    )

  const handleLocationDecrement = () =>
    setLocationValue((count) => Math.max(+count - 1, minNumberOfLocations))

  const handleLocationIncrement = () =>
    setLocationValue((count) => Math.min(+count + 1, pricingOrders?.maxNumberOfLocations))

  const monthlySavingsPrice = useMemo(
    () =>
      Math.round(
        orders *
          orderValue *
          locationValue *
          getPercentage(orderErrorPercentage) *
          getPercentage(reductionErrorPercentage)
      ),
    [orders, orderValue, locationValue]
  )

  const handleHiddenValues = (inputName, value) => {
    const input = document.querySelector(`[type=hidden][name=${inputName}]`)
    if (!input) return
    input.value = value
  }

  const annualSavingsPrice = monthlySavingsPrice * PRICING.ANNUAL_VALUE

  return {
    orders,
    orderValue,
    setOrders,
    storedSliderValue,
    setStoredSliderValue,
    locationValue,
    handleOrderChange,
    handleOrderValueChange,
    handleLocationChange,
    handleLocationDecrement,
    handleLocationIncrement,
    handleHiddenValues,
    monthlySavingsPrice,
    annualSavingsPrice
  }
}
