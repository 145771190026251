import React from 'react'

import { ImageFluid, ImageFluidPreview } from '../utils/image'
import { getUrl } from '../utils/urls'

const getImageComponent = (image, preview) => {
  if (preview && image?.url) return <ImageFluidPreview image={image?.url} />
  return <ImageFluid image={image} />
}
export const getFeatureCards = (featureCards = [], slugPrefix = '', preview) =>
  featureCards?.map((item) => {
    const { image, action } = item
    return {
      ...item,
      id: item?.id || item?.sys?.id,
      textTokens: {},
      image: image && getImageComponent(image, preview),
      action: action && {
        text: action?.title,
        small: false,
        url: preview ? '#' : getUrl(action, slugPrefix)
      }
    }
  })
