import cn from 'classnames'
import React, { Children } from 'react'

import { SiteDataContext } from '../../components'
import RichText from '../../components/rich-text'

//
import { componentType } from './index'

const MenuDropdown = ({ bottomContent, textTokens = {}, visible = false, children }) => {
  const { isTextRtl } = React.useContext(SiteDataContext)

  return (
    <div
      className={cn('c-menu-dropdown', {
        'is-visible': visible,
        'c-menu-dropdown--rtl': isTextRtl
      })}
    >
      <div className="c-menu-dropdown__container">
        <div className="c-menu-dropdown__grid l-grid">
          {Children.map(children, (child) => {
            return (
              <div
                className={cn(
                  'c-menu-dropdown__item l-grid__col',
                  {
                    'c-menu-dropdown__item--large':
                      child.props.componentType === componentType.testimonial ||
                      child.props.componentType === componentType.integrations ||
                      child.props.componentType === componentType.summary
                  },
                  {
                    'c-menu-dropdown__item__summary':
                      child.props.componentType === componentType.summary
                  }
                )}
                key={child.key}
              >
                {child}
              </div>
            )
          })}
        </div>
      </div>

      {bottomContent && (
        <div className="c-menu-dropdown__bottom-bar">
          <RichText data={bottomContent} tokens={textTokens} />
        </div>
      )}
    </div>
  )
}

export default MenuDropdown
