import React from 'react'

//
import { FeatureListBlock } from '../../../storybook'

const BlockFeatureList = ({ data: { title, description, featureList = [] } }) => {
  return <FeatureListBlock title={title} text={description} features={featureList} />
}

export default BlockFeatureList
