import React, { Suspense, lazy } from 'react'

import Loader from '../../Loader'

const LazyAnimation9Component = lazy(() => import('./Animation9'))

const Animation9 = (props) => (
  <Suspense fallback={<Loader />}>
    <LazyAnimation9Component {...props} />
  </Suspense>
)

export default Animation9
