import cn from 'classnames'
import React from 'react'

import { IconNew } from '..'
import { SiteDataContext } from '../../components'
import RichText from '../../components/rich-text'
import { withAnchor } from '../hooks'

const Kpi = ({
  title = '',
  icon = null,
  align = 'left',
  description,
  textTokens = {},
  classes = '',
  preface,
  children,
  variant,
  bulletListColor
}) => {
  const { isTextRtl } = React.useContext(SiteDataContext)

  return (
    <div
      data-variant={variant}
      className={cn('c-kpi', classes, {
        'c-kpi--center': align === 'center',
        'c-kpi--rtl': isTextRtl
      })}
    >
      {preface && <p className={'c-kpi__preface'}>{preface}</p>}

      <div className="c-kpi__content">
        <div className="c-kpi__header">
          {icon && (
            <IconNew
              classes={`c-feature-card-list__items-icon ${
                bulletListColor ? `c-feature-card-list__items-icon--${bulletListColor}` : ''
              }`}
              name={icon}
            />
          )}
          {title && <span className="c-kpi__title">{title}</span>}
        </div>

        {description && (
          <div className="c-kpi__description">
            {typeof description === 'object' ? (
              <RichText data={description} tokens={textTokens} />
            ) : (
              description
            )}
          </div>
        )}

        {children && <div className="c-kpi__action">{children}</div>}
      </div>
    </div>
  )
}

export default withAnchor(Kpi)
