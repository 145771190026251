import cn from 'classnames'
import React from 'react'

//
import Statistic from '../Statistic'

const Statistics = ({ statistics = [], classes = '', vertical = false }) => {
  return (
    <section className={cn('l-section', classes)}>
      <div className="l-container:12/12 l-container--default-spacing">
        <div
          className={`${
            !vertical ? 'l-grid' : 'c-statistics--vertical'
          } l-grid--default-spacing l-grid--align:center`}
        >
          {statistics?.map(({ number, unit, title, description, ...rest }, i) => (
            <Statistic
              key={`${title}-${i}`}
              number={number || ''}
              unit={unit || ''}
              title={title || ''}
              text={description || ''}
              {...rest}
              classes={`${
                vertical
                  ? 'c-statistic--vertical'
                  : 'l-grid__col:4/12 l-grid__col:6/12@to:viewport-7 l-grid__col:12/12@to:viewport-4'
              }`}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Statistics
