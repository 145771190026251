import cn from 'classnames'
import React from 'react'

import { withAnchor } from '../hooks'
//

const Author = ({ name, image, classes = '' }) => {
  return (
    <div className={cn('c-author', classes)}>
      <div className="c-author__avatar">{image}</div>

      <div className="c-author__name">{name}</div>
    </div>
  )
}

export default withAnchor(Author)
