import cn from 'classnames'
import React from 'react'

import IconNew from '../IconNew'

const IconButton = ({
  icon = null,
  black = false,
  handleClick = () => null,
  classes = '',
  size = 40
}) => {
  return (
    <button
      type="button"
      className={cn('c-icon-button', classes, {
        'c-icon-button--black': black
      })}
      onClick={handleClick}
    >
      {icon && <IconNew classes="c-icon-button__icon" name={icon} size={size} />}
    </button>
  )
}

export default IconButton
