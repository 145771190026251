import React from 'react'

//
import { ButtonNew, HeroHomeBlock } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { getWistiaAspectRatio } from '../../../utils/common'
import { getUrl } from '../../../utils/urls'
import { LocationContext } from '../../page-wrapper'

const BlockHeroHome = ({
  data: {
    title,
    description,
    image,
    wistiaVideo,
    alternate,
    primaryAction,
    secondaryAction,
    integrationsLabel,
    integrations
  }
}) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  const aspectRatio = getWistiaAspectRatio(wistiaVideo?.aspectRatios)

  const systems = integrations?.map((item) => {
    const { id, logo, title, slug } = item

    return {
      id,
      image: logo?.file?.url || '',
      title: title || '',
      slug: slug || '',
      url: `/${slugPrefix}integrations/${slug}`.replace('//', '/')
    }
  })

  const wistiaOptions = {
    autoPlay: wistiaVideo?.autoPlay || false,
    cover: wistiaVideo?.cover || false,
    muted: wistiaVideo?.muted || false,
    hideControls: wistiaVideo?.hideControls || false,
    controlsVisibleOnLoad: wistiaVideo?.controlsVisibleOnLoad || false
  }

  return (
    <>
      <HeroHomeBlock
        title={title || ''}
        description={description || ''}
        videoId={wistiaVideo?.videoId || ''}
        aspectRatio={{ x: aspectRatio.x, y: aspectRatio.y }}
        visualTitle={integrationsLabel || ''}
        systems={systems}
        alternate={alternate}
        image={image || ''}
        wistiaOptions={wistiaOptions}
        integrationsUrl={`/${slugPrefix}integrations`.replace('//', '/')}
      >
        {primaryAction && (
          <ButtonNew
            variant={BUTTON_VARIANT.GREEN}
            trackingId={primaryAction?.trackingId}
            url={getUrl(primaryAction, slugPrefix)}
          >
            {primaryAction?.title || ''}
          </ButtonNew>
        )}
        {secondaryAction && (
          <ButtonNew
            trackingId={secondaryAction?.trackingId}
            url={getUrl(secondaryAction, slugPrefix)}
            icon={secondaryAction?.icon || ''}
            variant={BUTTON_VARIANT.SECONDARY}
          >
            {secondaryAction?.title || ''}
          </ButtonNew>
        )}
      </HeroHomeBlock>
    </>
  )
}

export default BlockHeroHome
