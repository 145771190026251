import React from 'react'

//
import { ConditionalWrapper } from '../helpers'
import NavLink from '../helpers/nav-link'

const Search = ({ url, label, logo, handleClick = () => null }) => {
  return (
    <ConditionalWrapper
      condition={url}
      trueWrapper={(child) => (
        <NavLink to={url} className="c-search-result">
          {child}
        </NavLink>
      )}
      falseWrapper={(child) => (
        <button onClick={handleClick} className="c-search-result" type="button">
          {child}
        </button>
      )}
    >
      <img
        loading="lazy"
        className="c-search-result__logo"
        src={logo}
        alt={label}
        title={label}
        width="32"
        height="32"
      />

      {label}
    </ConditionalWrapper>
  )
}

export default Search
