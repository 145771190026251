export const componentType = Object.freeze({
  testimonial: 'testimonial',
  integrations: 'integrations',
  summary: 'summary'
})

export const bottomContentMock = Object.freeze({
  json: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [
              {
                type: 'bold'
              }
            ],
            value: 'Salutantibus',
            nodeType: 'text'
          },
          {
            data: {},
            marks: [],
            value: ' vitae elit libero, a pharetra augue. ',
            nodeType: 'text'
          }
        ],
        nodeType: 'paragraph'
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text'
          }
        ],
        nodeType: 'paragraph'
      }
    ],
    nodeType: 'document'
  }
})
