import cn from 'classnames'
import React from 'react'

import Icon from '../IconNew'

const IconButton = ({
  icon = null,
  handleClick = () => null,
  classes = '',
  size = 16,
  ...rest
}) => {
  return (
    <button
      type="button"
      className={cn('new-c-icon-button', classes)}
      onClick={handleClick}
      {...rest}
    >
      {icon && <Icon classes="c-icon-button__icon" name={icon} size={size} />}
    </button>
  )
}

export default IconButton
