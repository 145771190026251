import cn from 'classnames'
import React, { memo } from 'react'

//
import { getMicroCopy } from '../../utils/microcopy'
import ButtonNew from '../ButtonNew/ButtonNew'
import IconButton from '../IconButton'

const ModalBodyDesktop = ({
  microCopyData,
  selectedCategories,
  handleOnCategoryClick,
  handleClear,
  categories,
  close,
  handleSelectAll
}) => {
  return (
    <div className="c-blogFilters__modal--container--desktop">
      <div className="c-blogFilters__modal--header">
        <div className="c-blogFilters__modal--title">
          <h4>{getMicroCopy({ key: `blog.filter.categories.label`, data: microCopyData })}</h4>
          {selectedCategories.length ? (
            <span className="c-blogFilters__numberTip">{selectedCategories.length}</span>
          ) : null}
        </div>
        <IconButton
          classes="c-blogFilters__modal--closeBtn"
          icon="tabler-x"
          handleClick={() => close()}
          size={24}
        />
      </div>
      <div className="c-blogFilters__modal--body">
        <ButtonNew
          classes={cn(
            'c-blogFilters__button modal',
            selectedCategories.length === categories.length && 'active'
          )}
          buttonProps={{
            onClick: () => handleSelectAll(selectedCategories.length !== categories.length)
          }}
          filter
          small
        >
          {getMicroCopy({
            key: `blog.filter.categories.all`,
            data: microCopyData,
            fallback: 'All'
          })}
        </ButtonNew>
        {categories.map((category) => (
          <ButtonNew
            key={category.id}
            classes={cn('c-blogFilters__button modal', category.selected && 'active')}
            buttonProps={{ onClick: () => handleOnCategoryClick(category.id) }}
            filter
            small
          >
            {category.name}
          </ButtonNew>
        ))}
      </div>
      <div className="c-blogFilters__modal--footer">
        <ButtonNew
          variant="green"
          classes="c-blogFilters__apply__button"
          buttonProps={{ onClick: () => close() }}
        >
          {getMicroCopy({ key: `blog.filter.categories.apply`, data: microCopyData })}
        </ButtonNew>
        <ButtonNew
          classes="c-blogFilters__clear-all__button"
          buttonProps={{ onClick: () => handleClear() }}
          variant="tertiary"
        >
          {getMicroCopy({ key: `blog.filter.categories.clear`, data: microCopyData })}
        </ButtonNew>
      </div>
    </div>
  )
}

export default memo(ModalBodyDesktop)
