import cn from 'classnames'
import React, { useContext, useEffect, useState } from 'react'

import { getCityCountryByCurrentTimezone } from '../../helpers/getCitiesToCountries'
import { Loader } from '../../storybook'
import { LocationContext, SiteDataContext } from '../page-wrapper'

const ORDER_PLAN_SIZE = Object.freeze({
  STARTER: '0 - 350',
  MEDIUM: '350 - 750',
  LARGE: '750 - 1250',
  EXTRA_LARGE: '> 1250'
})

export const ORDER_ESTIMATE_PLAN_SIZE_OPTIONS = Object.freeze([
  { label: ORDER_PLAN_SIZE.STARTER, value: 350 }, // "Starter"
  { label: ORDER_PLAN_SIZE.MEDIUM, value: 750 }, // "Medium"
  { label: ORDER_PLAN_SIZE.LARGE, value: 1250 }, // "Large"
  { label: ORDER_PLAN_SIZE.EXTRA_LARGE, value: 1250 } // "Extra Large"
])

export const NUMBER_OF_LOCATIONS = Object.freeze([
  { value: '1', label: '1', max: 1 },
  { value: '2-5', label: '2-5', max: 5 },
  { value: '6-10', label: '6-10', max: 10 },
  { value: '11-25', label: '11-25', max: 25 },
  { value: '25-50', label: '25-50', max: 50 },
  { value: '+50', label: '+50', max: 50 }
])

const HubSpotForm = ({
  portalId = '',
  formId = '',
  mode = 'universal',
  text = '',
  action = '',
  overridenId = '',
  prefill = [],
  countryRedirect = [],
  onFormLoaded = () => {},
  closeModal,
  showLoader = false,
  jsScript
}) => {
  if (!portalId || !formId) {
    return null
  }

  const location = useContext(LocationContext)
  const { isTextRtl } = useContext(SiteDataContext)

  const [loaded, setLoaded] = useState(false)

  const loadHubSpotForm = (callback, scriptSrc) => {
    const isDivLoaded = document.getElementById(`hubspot-${mode}-${formId}`)

    if (!scriptSrc) {
      scriptSrc = 'https://js.hsforms.net/forms/v2.js'
    }

    if (!isDivLoaded) {
      const head = document.getElementsByTagName('head')[0]
      const script = document.createElement('script')
      script.setAttribute('src', scriptSrc)
      script.setAttribute('type', 'text/javascript')
      script.setAttribute('async', 'true')

      head.appendChild(script)

      script.onload = () => {
        if (callback) callback()
      }
    }

    if (isDivLoaded && callback) callback()
  }

  function valueExistsInArray(value, array) {
    // Iterate through the elements of the array
    for (let i = 0; i < array.length; i++) {
      const element = array[i]

      // Check if the element is a string
      if (typeof element === 'string') {
        if (element === value) {
          return true // Found a match
        }
      } else if (Array.isArray(element)) {
        // If the element is an array, recursively search it
        if (valueExistsInArray(value, element)) {
          return true // Found a match within the nested array
        }
      }
    }

    // Value not found in the array
    return false
  }

  useEffect(() => {
    loadHubSpotForm(() => {
      if (typeof hbspt !== 'undefined') {
        // eslint-disable-next-line no-undef
        hbspt.forms.create({
          portalId,
          formId,
          target: `.hubspot-form-${mode}-${formId}`,
          onFormReady: function ($form) {
            onFormLoaded($form)
            if (Array.isArray(prefill) && prefill.length > 0) {
              prefill.forEach((field) => {
                if ($form.querySelectorAll(`input[name="${field.name}"]`).length) {
                  $form.querySelector(`input[name="${field.name}"]`).setAttribute('value', country)
                }
              })
            }

            if ($form.querySelectorAll(`input[name="referrer"]`).length) {
              $form.querySelector(`input[name="referrer"]`).value = location?.state?.referrer || ''
            }

            // Fill the country field with current location
            const { country } = getCityCountryByCurrentTimezone()
            const countryInput = $form.querySelector(`select[name="country"]`)
            countryInput.value = country
            countryInput.dispatchEvent(new Event('input', { bubbles: true }))

            // Override action if present (to not send to hubspot)
            if (action) {
              $form.action = action
            }

            // Override hubspot form id
            if (overridenId) {
              $form.id = overridenId
            }
          },
          onFormSubmit: function ($form) {
            const isFormPulse = !!$form.querySelector('input[type="hidden"][value="Pulse"]')
            const numberOfLocations =
              $form.querySelector(`select[name="number_of_locations"]`)?.value ||
              $form.querySelector(`input[name="number_of_locations"]:checked`)?.value ||
              ''

            const posValue =
              $form.querySelector(`select[name="pos"]`)?.value ||
              $form.querySelector(`select[name="pos_verified_self_serve"]`)?.value

            window.dataLayer.push({
              event: 'hubspot-form-success'
            })

            if (Array.isArray(countryRedirect) && countryRedirect.length > 0) {
              const arrayOfCountries = countryRedirect.map((redirect) => {
                return redirect.country
              })
              let selectedCountry = countryRedirect.filter(
                (redirect) => redirect.country === 'default'
              )
              let selectedUrl =
                selectedCountry?.[0]?.url || 'https://welcome.deliverect.com/en-us/thank-you'

              const countryValue =
                $form.querySelector(`select[name="country"]`) ||
                $form.querySelector(`input[name="country"]`) ||
                {}

              const stateValue = $form.querySelector(`select[name="state_region"]`)?.value || null
              //

              if (valueExistsInArray(countryValue?.value, arrayOfCountries)) {
                // Get URLS from the country selected
                let availableOptions = []

                if (stateValue) {
                  availableOptions = countryRedirect.filter((redirect) => {
                    const isCountry = Array.isArray(redirect.country)
                      ? redirect.country.includes(countryValue.value)
                      : redirect.country === countryValue.value
                    const isState = Array.isArray(redirect.state)
                      ? redirect.state.includes(stateValue)
                      : redirect.state === stateValue
                    const isNumberOfLocations = Array.isArray(redirect['number_of_locations'])
                      ? redirect['number_of_locations'].includes(numberOfLocations)
                      : redirect['number_of_locations'] === numberOfLocations

                    return isCountry && isState && isNumberOfLocations
                  })

                  if (!availableOptions || !availableOptions.length) {
                    availableOptions = countryRedirect.filter((redirect) => {
                      const isCountry = Array.isArray(redirect.country)
                        ? redirect.country.includes(countryValue.value)
                        : redirect.country === countryValue.value
                      const isState = Array.isArray(redirect.state)
                        ? redirect.state.includes(stateValue)
                        : redirect.state === stateValue
                      return !redirect['number_of_locations'] && isCountry && isState
                    })
                  }
                }

                if (!availableOptions || !availableOptions.length) {
                  availableOptions = countryRedirect.filter((redirect) => {
                    const isCountry = Array.isArray(redirect.country)
                      ? redirect.country.includes(countryValue.value)
                      : redirect.country === countryValue.value
                    return !redirect.state && isCountry
                  })
                }

                // Get Correct Option via Minimum Number of Locations
                if (availableOptions.length > 1) {
                  selectedCountry = availableOptions.filter((redirect) =>
                    Array.isArray(redirect['number_of_locations'])
                      ? redirect['number_of_locations'].includes(numberOfLocations)
                      : redirect['number_of_locations'] === numberOfLocations
                  )
                  selectedUrl =
                    selectedCountry[0]?.url ||
                    availableOptions[0].url ||
                    'https://welcome.deliverect.com/en-us/thank-you'
                } else {
                  selectedUrl =
                    availableOptions[0]?.url || 'https://welcome.deliverect.com/en-us/thank-you'
                }
              }

              if (closeModal) closeModal()

              let redirectUrl = new URL(selectedUrl)

              if (isFormPulse) {
                const doesRedirectUrlHasQueryParams = redirectUrl.toString().split('?').length > 1
                const urlParams = location.href.split('?')[1] || ''
                if (doesRedirectUrlHasQueryParams) {
                  return setTimeout(() => {
                    window.location = redirectUrl.toString() + '&' + urlParams
                  }, 100)
                }
                return setTimeout(() => {
                  window.location = redirectUrl.toString() + '?' + urlParams
                }, 100)
              }

              let search = {
                firstName: $form.querySelector(`input[name="firstname"]`)?.value || '',
                lastName: $form.querySelector(`input[name="lastname"]`)?.value || '',
                email: $form.querySelector(`input[name="email"]`)?.value || '',
                company: $form.querySelector(`input[name="company"]`)?.value || '',
                phone:
                  ($form.querySelector(`input[type="tel"]`)?.value || '').replace(/\s/g, '') || ''
              }

              if (numberOfLocations === NUMBER_OF_LOCATIONS[0].value && posValue !== 'Other') {
                redirectUrl = new URL('https://frontend.deliverect.com/createaccount')
                search = {
                  firstName: $form.querySelector(`input[name="firstname"]`)?.value || '',
                  lastName: $form.querySelector(`input[name="lastname"]`)?.value || '',
                  email: $form.querySelector(`input[name="email"]`)?.value || '',
                  company: $form.querySelector(`input[name="company"]`)?.value || '',
                  country: countryValue?.value || '',
                  phone:
                    ($form.querySelector(`input[type="tel"]`)?.value || '').replace(/\s/g, '') ||
                    '',
                  number_of_locations: numberOfLocations
                }
              }

              let auxSearch = []
              for (const key in search) {
                if (search[key]) {
                  auxSearch.push(encodeURIComponent(key) + '=' + encodeURIComponent(search[key]))
                }
              }
              auxSearch = auxSearch.join('&')

              setTimeout(() => {
                window.location = redirectUrl.toString() + '?' + auxSearch
              }, 100)
            }
          }
        })
        ;(function () {
          window.jQuery =
            window.jQuery ||
            function (nodeOrSelector) {
              if (typeof nodeOrSelector === 'string') {
                // eslint-disable-next-line no-undef
                return document.querySelector(nodeOrSelector)
              }
              return nodeOrSelector
            }
        })()
      }
      setLoaded(true)
    }, jsScript)
  }, [])

  return (
    <>
      <div className="c-hubspot-form">
        <div
          className={cn(`s-hubspot hubspot-form-${mode}-${formId}`, {
            's-hubspot--rtl': isTextRtl
          })}
        />

        {loaded ? <div id={`hubspot-${mode}-${formId}`} /> : showLoader ? <Loader /> : null}

        {text && <p className="c-hubspot-form__text">{text}</p>}
      </div>
    </>
  )
}

export default HubSpotForm
