import cn from 'classnames'
import React from 'react'

//
import { SiteDataContext } from '../../components'
import RichText from '../../components/rich-text'
import ButtonNew, { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
import { withAnchor } from '../hooks'

import { HEADING_TYPE } from './Summary.const'

const Summary = ({
  title = '',
  brandedTitle = false,
  subtitle = '',
  headingType = HEADING_TYPE.h5,
  description = null,
  textTokens = {},
  inverted = false,
  action = {},
  secondaryAction = {},
  classes,
  children
}) => {
  const HeadingTag = headingType
  const { isTextRtl } = React.useContext(SiteDataContext)

  const headingIndex = Object.values(HEADING_TYPE).findIndex((type) => type === headingType)
  const SubtitleTag = Object.values(HEADING_TYPE)[headingIndex + 1] || 'h6'

  return (
    <div
      className={cn('c-summary', classes, {
        'c-summary--inverted': inverted,
        'c-summary--rtl': isTextRtl
      })}
    >
      {title && (
        <HeadingTag
          className={cn('c-summary__title', {
            'c-summary__title--branded': brandedTitle
          })}
        >
          {title}
        </HeadingTag>
      )}

      {subtitle && <SubtitleTag className="c-summary__subtitle">{subtitle}</SubtitleTag>}

      {description && (
        <div className="c-summary__description">
          <RichText data={description} tokens={textTokens} classes="s-wysiwyg-editor " />
        </div>
      )}

      <div className="l-grid u-gap:8">
        {action && action.url && action.label && (
          <ButtonNew
            url={action.url}
            classes="c-summary__action-button"
            variant={inverted ? BUTTON_VARIANT.SECONDARY : BUTTON_VARIANT.PRIMARY}
            size="md"
          >
            {action.label}
          </ButtonNew>
        )}

        {secondaryAction && secondaryAction.url && secondaryAction.label && (
          <ButtonNew
            url={secondaryAction.url}
            classes="c-summary__action-button"
            variant={inverted ? BUTTON_VARIANT.PRIMARY : BUTTON_VARIANT.SECONDARY}
            size="md"
          >
            {secondaryAction.label}
          </ButtonNew>
        )}
      </div>

      {children && <div className="c-summary__content">{children}</div>}
    </div>
  )
}

export default withAnchor(Summary)
