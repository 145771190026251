import debounce from 'lodash/debounce'
import { useEffect } from 'react'

export default (cb) => {
  useEffect(() => {
    // Add event listener
    const debouncedResize = debounce(cb, 200)
    window.addEventListener('resize', debouncedResize)

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', debouncedResize)
  }, [cb])
}
