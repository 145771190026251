/**
 * @param {*} max input range max value
 * @param {*} min input range min value
 * @param {*} array
 * @param {*} value inputEvent value
 * @returns the index of the array based on range (min<->max) of inputEvent
 */
export const getSliderIndexValue = (max = 0, min = 0, array = [], value = '') => {
  const ratio = (max - min) / array?.length
  const area = Math.floor(parseInt(value, 10) / ratio)
  const lastIndex = array.length - 1
  const index = area > lastIndex ? lastIndex : area

  return index
}
