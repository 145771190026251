import React from 'react'
import { Helmet } from 'react-helmet'

import DelieverctLogo from '../../../static/images/Deliverect_Logo_Primary.png'

export const SiteDataContext = React.createContext({})
export const LocationContext = React.createContext({})
export const MicroCopyContext = React.createContext({})
export const LocationContextProvider = LocationContext.Provider

const PageWrapper = ({ children, location, data, pageContext, country = null }) => {
  if (location.pathname === '/offline-plugin-app-shell-fallback/') {
    return null
  }

  const match = location.pathname.match(/^\/([\w]{2})-?([\w]{2})?\//)

  let {
    microCopy,
    settings,
    locale: lang,
    siteMetadata,
    market,
    allPublishedIntegrations,
    allMarketsByTitle,
    allMarkets,
    allMarketsSlugs
  } = pageContext

  const isTextRtl = market?.locale === 'ar' // text right to left (Arabic)

  if (data?.site404) {
    const { site404, allContentfulMicrocopySet, allContentfulSettings, allContentfulMarket } = data

    lang = (match && match[1]) || 'en'
    const prefix = (match && match[0].replace(/\//g, '')) || 'en'

    siteMetadata = site404?.siteMetadata

    microCopy = {}

    allContentfulMicrocopySet.group
      .find((group) => group.locale === lang)
      .edges.map((edge) => edge.node)
      .forEach((node) => {
        node.values.forEach((value) => {
          microCopy[`${node.key}.${value.key}`] = { value: value.value }
        })
      })

    market = allContentfulMarket.edges
      .map((edge) => edge.node)
      .find((node) => node.slugPrefix && node.slugPrefix === prefix)

    settings = {}
    allContentfulSettings.group.forEach((group) => {
      if (group.locale === lang) {
        settings = group.edges.map((edge) => edge.node)[0]
      }
    })
  }

  location.slugPrefix =
    (market?.slugPrefix && `/${market?.slugPrefix}/`) || (match && match[0]) || '/'
  location.country = country || (match && match[2]) || ''

  const siteData = {
    settings: {
      pages: {
        homepage: { ...settings?.homepage },
        integrationsOverview: { ...settings?.integrationsOverviewPage },
        requestDemo: { ...settings?.requestYourIntegrationPage },
        requestIntegration: { ...settings?.requestYourIntegrationPage }
      },
      siteTitle: settings?.siteTitle,
      siteDescription: settings?.siteDescription?.siteDescription,
      siteImage: market?.image?.fixed?.src
    },
    site: { ...siteMetadata },
    lang,
    locale: market?.locale,
    allPublishedIntegrations,
    allMarketsByTitle,
    allMarkets,
    allMarketsSlugs,
    isTextRtl,
    market: {
      id: market?.id,
      slug: market?.slugPrefix,
      title: market?.title,
      localName: market?.localName,
      isoCode: market?.countryIsoCode,
      currencySymbol: market?.currencySymbol,
      pricing: market?.pricing,
      flagUrl: market?.svgFlag?.file?.url,
      pageTitlePrefix: market?.pageTitlePrefix,
      footer: market?.footer
    }
  }

  const links = []

  if (location.pathname.includes('/blog')) {
    links.push(
      <link
        rel="alternate"
        type="application/rss+xml"
        href={`https://www.deliverect.com${location.slugPrefix}blog/rss.xml`}
      />
    )
  }

  const schemaOrgCorporation = pageContext?.metaTags?.schemaOrg
    ? {
        '@context': 'https://schema.org',
        '@type': 'Corporation',
        name: siteData.market.pageTitlePrefix,
        description: pageContext?.metaTags?.description || '',
        url: location.href || '',
        image: `${DelieverctLogo}`,
        sameAs: pageContext?.metaTags?.schemaOrg.sameAs
      }
    : null

  const structuredDataMarkup = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: siteData.market.pageTitlePrefix,
    description: pageContext?.metaTags?.description || '',
    url: location.href || '',
    image: `${DelieverctLogo}`
  }

  return (
    <LocationContextProvider value={location}>
      <SiteDataContext.Provider value={siteData}>
        <MicroCopyContext.Provider value={microCopy}>
          <>
            <Helmet htmlAttributes={{ lang }}>
              {links}
              <script type="application/ld+json">
                {JSON.stringify(structuredDataMarkup, null, 2)}
              </script>
              {schemaOrgCorporation && (
                <script type="application/ld+json">
                  {JSON.stringify(schemaOrgCorporation, null, 2)}
                </script>
              )}
            </Helmet>
            {children}
          </>
        </MicroCopyContext.Provider>
      </SiteDataContext.Provider>
    </LocationContextProvider>
  )
}

export default PageWrapper
