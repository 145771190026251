import cn from 'classnames'
import { Link } from 'gatsby'
import { isEmpty } from 'lodash'
import React, { useContext } from 'react'

//
import { ButtonNew, IconNew } from '..'
import { LocationContext, MicroCopyContext, SiteDataContext } from '../../components/page-wrapper'
import { getMicroCopy } from '../../utils/microcopy'
import { getUrl } from '../../utils/urls'
import { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
import Dropdown from '../Dropdown'
import FooterMenu from '../FooterMenu'
import Logo from '../Logo'
import { isExternalUrl } from '../helpers'

import FooterCallMenu from './FooterCallMenu'

const Footer = ({
  minified = false,
  footerMenus = {},
  languageCountryMenu = {},
  callMenu = {},
  socialMenu = {},
  footerText = {},
  legalMenu = {},
  actions = [],
  language = '',
  country = '',
  handleSelectLanguage = () => null,
  handleSelectCountry = () => null
}) => {
  const microCopyData = useContext(MicroCopyContext)
  const { isTextRtl } = React.useContext(SiteDataContext)
  const isEmptyCallMenu = isEmpty(callMenu)

  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  const rootClasses = cn('c-footer', {
    'c-footer--minified': minified,
    'c-footer--rtl': isTextRtl
  })

  const renderSocials = (socialMenu) => (
    <ul className="c-footer__social-menu">
      {socialMenu.items.map((item) => {
        const socialIcon = `${item.label.toLowerCase()}`

        return (
          <li key={item.id} className="c-footer__social-menu-item">
            <a
              className="c-footer__social-menu-link"
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              title={item.label}
            >
              <IconNew social={true} name={socialIcon} size={24} />
            </a>
          </li>
        )
      })}
    </ul>
  )

  const renderLanguageLocation = (mobile) => {
    const id = mobile ? `-${mobile}` : ''
    return (
      <div className="l-grid l-grid--default-spacing@to:viewport-4 l-grid--spacing:2">
        <div className="l-grid__col:12/12">
          <Dropdown
            items={languageCountryMenu.languageItems}
            value={language}
            handleSelect={handleSelectLanguage}
            inverted
            rounded={true}
            customId={`${getMicroCopy({
              key: 'global.language',
              fallback: 'Language',
              data: microCopyData
            })}${id}`}
            label={getMicroCopy({
              key: 'global.language',
              fallback: 'Language',
              data: microCopyData
            })}
            classes="c-footer__dropdown"
          />
        </div>

        <div className="l-grid__col:12/12">
          <Dropdown
            items={languageCountryMenu.countryItems}
            value={country}
            handleSelect={handleSelectCountry}
            inverted
            rounded={true}
            customId={`${getMicroCopy({
              key: 'global.country',
              fallback: 'Country',
              data: microCopyData
            })}${id}`}
            label={getMicroCopy({
              key: 'global.country',
              fallback: 'Country',
              data: microCopyData
            })}
            classes="c-footer__dropdown"
          />
        </div>
      </div>
    )
  }

  return (
    <footer className={rootClasses}>
      <div className="c-footer-container">
        <div>
          <div className="l-grid c-footer__top">
            <div className="l-grid__col:12/12 l-grid__col:4/12@at:viewport-7">
              <div className="c-footer__logo">
                <Logo variant="white" size={60} withText />
              </div>
            </div>

            <p className="c-footer__copyright">
              {footerText.copyright}
              <br />
              {footerText.companyInfo}
            </p>
          </div>
        </div>
        {!minified && (
          <div className="c-footer__body">
            <div className="l-grid l-grid--default-spacing@at:viewport-4 c-footer__main-wrapper">
              <div className="l-grid__col:12/12 c-footer__language-country-mobile">
                {renderLanguageLocation(
                  getMicroCopy({
                    key: 'global.mobile',
                    fallback: 'Mobile',
                    data: microCopyData
                  })
                )}
              </div>

              {actions && actions.length > 0 ? (
                <div className="c-footer-menu__login c-footer-menu__login--mobile l-grid__col:12/12">
                  {actions.map((action, index) => {
                    return (
                      <ButtonNew
                        key={'footer-action-' + action.id}
                        url={getUrl(action, slugPrefix)}
                        variant={index === 0 ? BUTTON_VARIANT.GREEN : BUTTON_VARIANT.SECONDARY}
                        size={'md'}
                        fullWidth
                      >
                        {' '}
                        {action.title}{' '}
                      </ButtonNew>
                    )
                  })}
                </div>
              ) : null}
              <div className="l-grid__col:12/12 l-grid__col:9/12@at:viewport-12 c-site-footer__sitemap">
                <nav className="l-grid l-grid--default-spacing@at:viewport-4">
                  {footerMenus &&
                    footerMenus.map((col, i) => (
                      <section
                        key={col.id}
                        className="l-grid__col:12/12 l-grid__col:6/12@at:viewport-4 l-grid__col:3/12@at:viewport-9"
                      >
                        {col.menus &&
                          col.menus.map((menu, i) => (
                            <FooterMenu title={menu.title} items={menu.items} key={menu.id} />
                          ))}
                      </section>
                    ))}
                </nav>
              </div>

              <div
                className={cn(
                  'l-grid__col:12/12 l-grid__col:3/12@at:viewport-12 c-footer__contact',
                  { 'c-footer__contact--mobile': isEmptyCallMenu }
                )}
              >
                <div className="l-grid l-grid--default-spacing@at:viewport-4">
                  <div className="c-footer__language-country-desktop l-grid__col:12/12 l-grid__col:6/12@at:viewport-4 l-grid__col:12/12@at:viewport-12">
                    <div className="c-footer-menu">
                      <h4 className="c-footer-menu__title">{languageCountryMenu.title}</h4>
                      {renderLanguageLocation()}
                      {actions && actions.length > 0 ? (
                        <div className="c-footer-menu__login l-grid__col:12/12">
                          {actions.map((action, index) => {
                            return (
                              <ButtonNew
                                key={'footer-action-' + action.id}
                                url={getUrl(action, slugPrefix)}
                                variant={
                                  index === 0 ? BUTTON_VARIANT.GREEN : BUTTON_VARIANT.SECONDARY
                                }
                                size={'md'}
                                fullWidth
                              >
                                {' '}
                                {action.title}{' '}
                              </ButtonNew>
                            )
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <FooterCallMenu {...{ callMenu, isEmptyCallMenu }} />

                  <div className="c-footer__socialmedia l-grid__col:12/12 l-grid__col:6/12@at:viewport-4 l-grid__col:12/12@at:viewport-7">
                    <div className="c-footer-menu">
                      <h4 className="c-footer-menu__title">{socialMenu.title}</h4>

                      {socialMenu.items.length > 0 ? renderSocials(socialMenu) : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {minified ? (
          <div className="c-footer__footer-minimal l-grid">
            <div className="l-grid c-footer__footer-top l-grid__col:12/12">
              <div className="l-grid__col:12/12">
                {legalMenu.items && (
                  <ul className="c-footer__legal-menu">
                    {legalMenu.items.map((item, i) => (
                      <li key={item.id} className="c-footer__legal-menu-item">
                        {item.url && isExternalUrl(item.url) ? (
                          <a
                            className="c-footer-menu__legal-menu-link"
                            rel="noopener noreferrer"
                            target="_blank"
                            href={item.url}
                          >
                            {item.label}
                          </a>
                        ) : (
                          <Link to={item.url} className="c-footer-menu__legal-menu-link">
                            {item.label}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div className="c-footer__footer-bottom l-grid__col:12/12">
              {socialMenu.items.length > 0 && (
                <div className="c-footer__socialmedia">{renderSocials(socialMenu)}</div>
              )}
            </div>
          </div>
        ) : (
          <div className="c-footer__footer-bottom">
            <div className="l-grid__col:12/12">
              <div className="l-grid c-footer__top--mobile ">
                <div className="l-grid__col:12/12 l-grid__col:4/12@at:viewport-7">
                  <div className="c-footer__logo">
                    <Logo variant="white" size={60} withText />
                  </div>
                </div>
              </div>
              {legalMenu.items && (
                <ul className="c-footer__legal-menu">
                  {legalMenu.items.map((item) => (
                    <li key={item.id} className="c-footer__legal-menu-item">
                      <a className="c-footer__legal-menu-link" href={item.url} title={item.label}>
                        {item.label}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
              {socialMenu.items.length > 0 && (
                <div className="c-footer__socialmedia--mobile">{renderSocials(socialMenu)}</div>
              )}
            </div>
            <p className="c-footer__copyright--mobile">
              {footerText.copyright}
              <br />
              {footerText.companyInfo}
            </p>
          </div>
        )}
      </div>
    </footer>
  )
}

export default Footer
