import React, { useContext, useEffect, useRef, useState } from 'react'

import { IconButtonNew } from '..'
import { SiteDataContext } from '../../components'
import { usePricingContext } from '../../context/pricingContext'
import { PRICING } from '../../utils/common'
import Input from '../Input'
import RangeSlider from '../RangeSlider'
import { usePrevious } from '../hooks/use-previous'

const PricingCalculatorOrders = () => {
  const { market, isTextRtl } = useContext(SiteDataContext)
  const inputRef = useRef()
  const [isFocus, setIsFocus] = useState(null)

  const {
    orderValue,
    locationValue,
    handleOrderChange,
    handleOrderValueChange,
    handleLocationChange,
    handleLocationDecrement,
    handleLocationIncrement,
    pricingOrders,
    orders,
    setOrders,
    range,
    sliderRef,
    storedSliderValue,
    setStoredSliderValue,
    handleHiddenValues,
    monthlySavingsPrice,
    annualSavingsPrice
  } = usePricingContext()

  const prevOrders = usePrevious(orders)

  const onChangeHandler = (e) => {
    handleOrderChange(e)
    setIsFocus(inputRef?.current)
  }

  useEffect(() => {
    if (orders !== prevOrders) {
      handleHiddenValues('monthly_orders', orders)
    }

    handleHiddenValues('monthly_potential_savings', monthlySavingsPrice)
    handleHiddenValues('yearly_potential_savings', annualSavingsPrice)
  }, [locationValue, orders, prevOrders, orderValue])

  const onBlurHandler = () => setIsFocus(null)

  const sliderValue = storedSliderValue || PRICING.DEFAULT_VALUE

  return (
    <div className="c-pricing-calculator__inputs" dir={isTextRtl ? 'rtl' : 'ltr'}>
      <div className="c-pricing-calculator__card-input">
        <div className="c-pricing-calculator__card-input__header">
          <span>1</span>
          {pricingOrders?.ordersLabel}
        </div>
        <div className="c-pricing-calculator__card-input__content">
          <Input
            ref={inputRef}
            onBlur={onBlurHandler}
            value={orders}
            handleChange={onChangeHandler}
            classes={'u-margin-bottom:24'}
          />
          <RangeSlider
            isStoredValue
            classes="c-pricing-calculator-orders__range-slider u-margin-bottom:24"
            ref={sliderRef}
            orders={orders}
            defaultValue={sliderValue}
            value={sliderValue}
            labels={range}
            isFocus={isFocus}
            getValue={setOrders}
            setStoredSliderValue={setStoredSliderValue}
            hideLabel={true}
          />
        </div>
      </div>
      <div className="c-pricing-calculator__card-input">
        <div className="c-pricing-calculator__card-input__header">
          <span>2</span>
          {pricingOrders?.orderValueLabel}
        </div>
        <div className="c-pricing-calculator__card-input__content">
          <div className="c-pricing-calculator__card-input__input-container">
            <div
              className={
                'c-pricing-calculator-orders__button c-pricing-calculator-orders__currencyBtn'
              }
            >
              {market.currencySymbol}
            </div>
            <Input value={orderValue} handleChange={handleOrderValueChange} />
          </div>
        </div>
      </div>
      <div className="c-pricing-calculator__card-input">
        <div className="c-pricing-calculator__card-input__header">
          <span>3</span>
          {pricingOrders?.locationsLabel}
        </div>
        <div className="c-pricing-calculator__card-input__content">
          <div className="c-pricing-calculator__card-input__input-container">
            <IconButtonNew
              icon="ti-minus"
              handleClick={handleLocationDecrement}
              disabled={locationValue <= 1}
            />
            <Input
              classes="c-pricing-calculator-orders__input"
              value={locationValue}
              handleChange={handleLocationChange}
              center
            />
            <IconButtonNew icon="ti-plus" handleClick={handleLocationIncrement} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricingCalculatorOrders
