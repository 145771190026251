import React from 'react'

//
import { ButtonGroup, ButtonNew, Hero, Wistia } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { getWistiaAspectRatio } from '../../../utils/common'
import { ImageFluid } from '../../../utils/image'
import { getUrl } from '../../../utils/urls'
import { LocationContext } from '../../page-wrapper'

const BlockHeroMedia = ({
  data: { preface, title, description, image, video, heroMediaActions, ...rest }
}) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  const aspectRatio = getWistiaAspectRatio(video?.aspectRatio)

  const actions =
    heroMediaActions?.map((action, index) => {
      const buttonVariant = index === 0 ? BUTTON_VARIANT.GREEN : BUTTON_VARIANT.SECONDARY
      return (
        <>
          <ButtonNew
            key={`hero-media__${action.id}`}
            trackingId={action.trackingId}
            url={getUrl(action, slugPrefix)}
            variant={buttonVariant}
            subtext={action.subtext}
          >
            {action.title || ''}
          </ButtonNew>
        </>
      )
    }) || []

  return (
    <>
      <Hero title={title || ''} content={description || ''} preface={preface || null}>
        {actions.length > 0 ? <ButtonGroup center>{actions}</ButtonGroup> : null}
      </Hero>

      {video ? (
        <Wistia
          videoSource={video?.videoSource}
          videoId={video?.videoId}
          thumbnail={video?.thumbnail || image || null}
          aspectRatioX={aspectRatio.x}
          aspectRatioY={aspectRatio.y}
          cover={video?.cover}
          autoPlay={video?.autoPlay}
          controlsVisibleOnLoad={video?.controlsVisibleOnLoad}
          muted={video?.muted}
          transparentBackground={video?.transparentBackground}
          popover={video?.popover}
          hideControls={video?.hideControls}
          limitHeight
        />
      ) : (
        <div className="l-container:12/12">
          <ImageFluid image={image} style={{ maxHeight: '600px' }} />
        </div>
      )}
    </>
  )
}

export default BlockHeroMedia
