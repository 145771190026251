import React from 'react'

//
import { ButtonNew, FeatureCard, FeatureContact, SectionHeader } from '../../../storybook'
import { ALIGN, HEADING_TYPE } from '../../../storybook/SectionHeader'
import { getUrl } from '../../../utils/urls'
import { LocationContext } from '../../page-wrapper'

const BlockFeatureContact = ({
  data: {
    title,
    preface,
    description,
    descriptionTokens = {},
    simpleContentBlocks = [],
    featureCards = []
  }
}) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  const features =
    simpleContentBlocks?.map(({ id, title, icon, description, action }, i) => ({
      id: `${id}-${i}`,
      title: title || '',
      icon: icon || '',
      description: description || '',
      textTokens: {},
      action: action
        ? {
            text: action.title || '',
            small: false,
            url: getUrl(action, slugPrefix)
          }
        : null
    })) || []

  return (
    <section className={'l-section'}>
      {title && (
        <div className="l-section__header">
          <div className="l-container:8/12 l-container--default-spacing">
            <SectionHeader
              title={title}
              eyebrow={preface}
              text={description}
              textTokens={descriptionTokens || {}}
              headingType={HEADING_TYPE.h2}
              align={ALIGN.center}
            />
          </div>
        </div>
      )}

      <div className="l-section--abstract">
        <div className="l-container:12/12 l-container--default-spacing">
          <div className="l-grid l-grid--default-spacing">
            {featureCards &&
              featureCards.length > 0 &&
              featureCards.map((feature) => {
                const {
                  id,
                  title,
                  icon,
                  description,
                  textTokens,
                  action,
                  image,
                  disclaimer,
                  extraInfo,
                  price,
                  month,
                  bulletListColor
                } = feature
                return (
                  <FeatureContact
                    key={`story-${id}`}
                    title={title}
                    icon={icon}
                    description={description}
                    textTokens={textTokens}
                    classes="l-grid__col:6/12 l-grid__col:12/12@to:viewport-7"
                    image={image}
                    disclaimer={disclaimer}
                    action={action}
                    extraInfo={extraInfo}
                    price={price}
                    month={month}
                    bulletListColor={bulletListColor}
                  ></FeatureContact>
                )
              })}
            {(!featureCards || featureCards.length === 0) && features.length > 0
              ? features.map(({ id, icon, title, description, textTokens = {}, action }) => (
                  <FeatureCard
                    key={`story-${id}`}
                    title={title}
                    icon={icon}
                    description={description}
                    textTokens={textTokens}
                    classes="l-grid__col:6/12 l-grid__col:12/12@to:viewport-7"
                  >
                    {action && <ButtonNew {...action}>{action.text}</ButtonNew>}
                  </FeatureCard>
                ))
              : null}
          </div>
        </div>
      </div>
    </section>
  )
}
/* <FeatureContactBlock title={title} eyebrow={preface} items={features} /> */
export default BlockFeatureContact
