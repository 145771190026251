import cn from 'classnames'
import React, { Children } from 'react'

const ButtonGroup = ({ center = false, children }) => {
  return (
    <div
      className={cn('c-button-group', {
        'c-button-group--center': center
      })}
    >
      {children.length > 0 &&
        Children.map(children, (child) => <div className="c-button-group__item">{child}</div>)}
    </div>
  )
}

export default ButtonGroup
