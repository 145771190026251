import { useState, useEffect } from 'react'

function isBrowser() {
  return typeof window !== 'undefined'
}

function setWindowWidthHandler() {
  this.setWidth(window.innerWidth)
}

const useWindowWidth = () => {
  const [widowWidth, setWindowWidth] = useState(isBrowser() ? window?.innerWidth : null)

  useEffect(() => {
    if (isBrowser()) {
      setWindowWidth(widowWidth || window?.innerWidth)
      window.addEventListener('resize', setWindowWidthHandler.bind({ setWidth: setWindowWidth }))
    }

    return () => {
      if (isBrowser()) {
        window.removeEventListener('resize', setWindowWidthHandler)
      }
    }
  }, [])

  return widowWidth
}

export default useWindowWidth
