import React from 'react'

//
import { RichText } from '../../../storybook'

const BlockRichText = ({ data: { text } }) => {
  return text ? (
    <section className="l-section">
      <div className="l-container:12/12 l-container--default-spacing">
        <div className="l-grid l-grid--default-spacing">
          <div className="l-grid__col:12/12">
            <RichText content={text} />
          </div>
        </div>
      </div>
    </section>
  ) : null
}

export default BlockRichText
