import React from 'react'

import { getFilterLogos, getLogoUrl, MAX_NUMBER_OF_LOGOS } from '../../../helpers/getUserLogos'
//
import { LogoGrid } from '../../../storybook'
import { shuffleArray } from '../../../utils/common'

const BlockLogos = ({ data: { logos, title, extra } }) => {
  let filteredLogos = []

  if (!logos?.length && extra?.slugPrefix) {
    const allLogos = shuffleArray(extra?.allLogos || [])
    filteredLogos = allLogos
      .filter((logo) => getFilterLogos(logo, extra))
      .slice(0, MAX_NUMBER_OF_LOGOS)

    if (!filteredLogos?.length) {
      filteredLogos = allLogos?.slice(0, MAX_NUMBER_OF_LOGOS)
    }
  } else {
    filteredLogos = logos?.slice(0, MAX_NUMBER_OF_LOGOS)
  }

  const logosToShow = filteredLogos?.map((logo, idx) => ({
    id: `logo-${logo.id || idx}`,
    logo: getLogoUrl(logo),
    title: logo?.name || ''
  }))

  return <LogoGrid title={title || ''} logos={logosToShow} />
}

export default BlockLogos
