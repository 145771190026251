import cn from 'classnames'
import React, { Children } from 'react'

import { SiteDataContext } from '../../components'

const CrissCross = ({ reverse = false, children }) => {
  const { isTextRtl } = React.useContext(SiteDataContext)

  if (isTextRtl) {
    reverse = !reverse
  }

  return (
    <section className="l-section l-container:12/12 l-container--default-spacing">
      <div className="l-grid l-grid--default-spacing l-grid--align:middle">
        {Children.map(children, (child, i) => {
          const firstChild = i === 0
          const lastChild = i === Children.count(children) - 1
          const firstChildLeftOffset = reverse && firstChild
          const lastChildLeftOffset = !reverse && lastChild

          return (
            <div
              className={cn('l-grid__col:12/12@to:viewport-7', {
                'l-grid__col:6/12': firstChild,
                'l-grid__col:5/12': lastChild,
                'l-grid__col--left-offset:1/12@at:viewport-7':
                  firstChildLeftOffset || lastChildLeftOffset,
                'l-grid__col--order:-1@at:viewport-7': reverse && lastChild
              })}
            >
              {child}
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default CrissCross
