import cn from 'classnames'
import React from 'react'

//
import { SiteDataContext } from '../../components'
import Kpi from '../Kpi'
import SectionHeader, { ALIGN, HEADING_TYPE } from '../SectionHeader'
import { withAnchor } from '../hooks'

const UspListBlock = ({
  items = [],
  title = '',
  description,
  preface = '',
  classes,
  action = null
}) => {
  const { isTextRtl } = React.useContext(SiteDataContext)
  return (
    <section className={cn('l-section u-block-gap', classes)}>
      <div
        className={cn('c-uspblock l-container:12/12 l-container--default-spacing', {
          'c-uspblock--rtl': isTextRtl
        })}
      >
        {title && (
          <div className="l-grid">
            <div className="c-uspblock__header l-section__header l-grid__col:12/12">
              <SectionHeader
                title={title || ''}
                eyebrow={preface || ''}
                text={description || ''}
                headingType={HEADING_TYPE.h3}
                align={ALIGN.center}
              />
            </div>
          </div>
        )}

        <div className="l-grid l-grid--spacing:20 l-grid--spacing:16@to:viewport-9">
          {items.length > 0 &&
            items?.map(({ id, icon, title, description, textTokens, ...rest }, index) => (
              <Kpi
                key={`${id}-${index}`}
                align="center"
                title={title || ''}
                icon={icon || ''}
                description={description || ''}
                textTokens={textTokens || {}}
                {...rest}
                classes="l-grid__col:4/12 l-grid__col:6/12@to:viewport-9 l-grid__col:12/12@to:viewport-7 c-uspblock__kpi-icon"
              />
            ))}
        </div>

        {action ? <div className="c-uspblock__action">{action}</div> : null}
      </div>
    </section>
  )
}

export default withAnchor(UspListBlock)
