import cn from 'classnames'
import React from 'react'

//
import RichText from '../../components/rich-text'
import { renderRichTextLine } from '../../utils/text'
import ExpansionPanel from '../ExpansionPanel'
import { HEADING_TYPE } from '../SectionHeader/SectionHeader.const'
import { withAnchor } from '../hooks'

import SectionHeader from './../SectionHeader'

const FAQ = ({
  title,
  eyebrow = '',
  text = '',
  actionSlot,
  faqs,
  footerSlot,
  classes = '',
  defaultSpacing = true
}) => {
  return (
    <section className={cn('l-section', classes)}>
      <div className={`l-container:12/12 ${defaultSpacing ? 'l-container--default-spacing' : ''}`}>
        {title && (
          <div className="l-section__header">
            <div className="l-container:8/12 u-margin-left:0">
              <SectionHeader
                headingType={HEADING_TYPE.h3}
                title={title}
                eyebrow={eyebrow}
                text={renderRichTextLine(text)}
              >
                {actionSlot}
              </SectionHeader>
            </div>
          </div>
        )}

        {faqs && (
          <div className="l-section__content">
            <div className="l-container:12/12 u-margin-left:0">
              {faqs?.map((faq, idx) => {
                const { id, question, answer, ...rest } = faq

                return (
                  <ExpansionPanel
                    key={`faq-${id}-${idx}`}
                    label={question}
                    iconFilled
                    withBorder
                    itemProp="mainEntity"
                    itemType="https://schema.org/Question"
                  >
                    <div itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                      <RichText
                        itemProp="text"
                        data={answer}
                        {...rest}
                        classes="s-wysiwyg-editor s-wysiwyg-editor--no-max-width"
                      />
                    </div>
                  </ExpansionPanel>
                )
              })}
            </div>
          </div>
        )}

        {footerSlot && <div className="l-section__footer">{footerSlot}</div>}
      </div>
    </section>
  )
}

export default withAnchor(FAQ)
