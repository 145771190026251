import React from 'react'

//
import { ImageFluid } from '../../../utils/image'

// TODO: expand this if design or visual is final (inline playing video for example)
const OrderIntegrationVisual = ({ image = '' }) => {
  return <ImageFluid image={image} />
}

export default OrderIntegrationVisual
