import cn from 'classnames'
import React, { useContext } from 'react'

import { SiteDataContext } from '../../components'
import RichText from '../../components/rich-text'

import { HEADING_TYPE } from './FormLayout.const'

const MainContentDesktop = () => null

const MainContentMobile = () => null

const RightContent = () => null

const FormLayout = ({
  title = '',
  text = null,
  textTokens = {},
  children,
  smallPadding = false,
  headingType = HEADING_TYPE.h2,
  formPosition
}) => {
  const mainContentDesktop = children.find((el) => el.type === MainContentDesktop)
  const mainContentMobile = children.find((el) => el.type === MainContentMobile)
  const rightContent = children.find((el) => el.type === RightContent)

  const { isTextRtl } = useContext(SiteDataContext)

  const HeadingTag = headingType

  return (
    <section
      data-variant={formPosition}
      className={cn('c-form-layout l-section', {
        'c-form-layout--small-padding': smallPadding,
        'c-form-layout--rtl': isTextRtl
      })}
    >
      <div className="l-container:12/12 l-container--default-spacing">
        <div className="c-form-layout__container-desktop">
          <div className="l-grid l-grid--default-spacing c-form-layout__grid">
            <div className="c-form-layout__content-right-container l-grid__col:12/12 l-grid__col:6/12@at:viewport-7 l-grid__col--order:-1@to:viewport-7 l-grid__col--align:middle">
              <div className="c-form-layout__content-right">
                {mainContentDesktop?.props?.children}
              </div>
            </div>

            <div className="l-grid__col:12/12 l-grid__col:6/12@at:viewport-7">
              <HeadingTag className="c-form-layout__title-left">{title}</HeadingTag>

              <div className="c-form-layout__text-left">
                <RichText data={text} tokens={textTokens} />
              </div>

              <div className="c-form-layout__content-left-container">
                <div className="l-grid l-grid--default-spacing">
                  <div className="l-grid__col:12/12 l-grid__col:10/12@at:viewport-7 l-grid__col--align:middle">
                    <div className="c-form-layout__content-left">
                      {rightContent?.props?.children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="c-form-layout__container-mobile">
          <h2 className="c-form-layout__title-center">{title}</h2>

          <div className="c-form-layout__text-center">
            <RichText data={text} tokens={textTokens} />
          </div>

          <div className="c-form-layout__content-mobile">
            <div className="c-form-layout__content-main-block">
              {mainContentMobile?.props?.children}
            </div>
            <div className="c-form-layout__content-block">{rightContent?.props?.children}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

FormLayout.MainContentDesktop = MainContentDesktop
FormLayout.MainContentMobile = MainContentMobile
FormLayout.RightContent = RightContent

export default FormLayout
