import React, { useContext } from 'react'

import { CrissCross, Summary } from '../../../storybook'
import { getUrl } from '../../../utils/urls'
import { LocationContext } from '../../page-wrapper'

import { MediaComponent } from './mediaComponent'

const BlockCrissCross = ({ data }) => {
  const {
    title,
    preface,
    description,
    action,
    secondaryAction,
    image,
    orientation,
    anchor,
    headingType,
    wistiaVideo
  } = data
  const location = useContext(LocationContext)
  const { slugPrefix } = location

  return (
    <CrissCross reverse={orientation === 'right'} anchor={anchor}>
      {image || wistiaVideo ? (
        <MediaComponent {...{ image, wistiaVideo }} height={255} width={400} />
      ) : null}

      <Summary
        headingType={headingType || 'h3'}
        title={title}
        subtitle={preface}
        description={description}
        action={action && action.title && { label: action.title, url: getUrl(action, slugPrefix) }}
        secondaryAction={
          secondaryAction &&
          secondaryAction.title && {
            label: secondaryAction.title,
            url: getUrl(secondaryAction, slugPrefix)
          }
        }
        classes="c-criss-cross-summary"
      />
    </CrissCross>
  )
}

export default BlockCrissCross
