import React from 'react'

//
import { Statistics } from '../../../storybook'

const BlockBenefitsListing = ({ data: { benefits } }) => {
  return <Statistics statistics={benefits} />
}

export default BlockBenefitsListing
