import cn from 'classnames'
import React, { useContext } from 'react'

import { SiteDataContext } from '../../components'
import RichText from '../../components/rich-text'

import { HEADING_TYPE } from './FormLayout.const'

const Form = () => null

const MainContentMobile = () => null

const Content = () => null

const FormLayout = ({
  title = '',
  text = null,
  textTokens = {},
  children,
  smallPadding = false,
  headingType = HEADING_TYPE.h2,
  formPosition
}) => {
  const form = children ? children.find((el) => el.type === Form) : null
  const content = children ? children.find((el) => el.type === Content) : null
  const textPosition = formPosition === 'center' ? 'center' : 'left'

  const { isTextRtl } = useContext(SiteDataContext)

  const HeadingTag = headingType

  return (
    <section
      data-variant={formPosition}
      className={cn('c-form-layout l-section', {
        'c-form-layout--small-padding': smallPadding,
        'c-form-layout--rtl': isTextRtl
      })}
    >
      <div className="l-container:12/12 l-container--default-spacing">
        <div className="c-form-layout__container-desktop">
          <div className="l-grid l-grid--default-spacing c-form-layout__grid">
            <div className="u-display:none@at:viewport-9">
              <HeadingTag className={`c-form-layout__text-center`}>{title}</HeadingTag>

              <div className={`c-form-layout__text-center`}>
                <RichText data={text} tokens={textTokens} />
              </div>

              <div className="c-form-layout__content-center-container">
                <div className="l-grid l-grid--default-spacing">
                  <div className="l-grid__col:12/12 l-grid__col:10/12@at:viewport-7 l-grid__col--align:middle">
                    <div className="c-form-layout__content-center">{content?.props?.children}</div>
                  </div>
                </div>
              </div>
            </div>

            {/* form */}
            <div className="c-form-layout__content-right-container l-grid__col:12/12 l-grid__col:7/12@at:viewport-9 l-grid__col--order:-1@to:viewport-7 l-grid__col--align:middle">
              <div className="c-form-layout__content-right">{form?.props?.children}</div>
            </div>

            {/* Content */}
            <div
              className={cn('l-grid__col:12/12', {
                'l-grid__col:5/12@at:viewport-9': formPosition !== 'center',
                'l-grid__col:8/12@at:viewport-9 l-grid__col--align:middle':
                  formPosition === 'center'
              })}
            >
              <div className="u-display:none@to:viewport-9">
                <HeadingTag className={`c-form-layout__text-${textPosition}`}>{title}</HeadingTag>

                <div className={`c-form-layout__text-${textPosition}`}>
                  <RichText data={text} tokens={textTokens} />
                </div>

                <div className="c-form-layout__content-left-container">
                  <div className="l-grid l-grid--default-spacing">
                    <div className="l-grid__col:12/12 l-grid__col:10/12@at:viewport-7 l-grid__col--align:middle">
                      <div className="c-form-layout__content-left">{content?.props?.children}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

FormLayout.Form = Form
FormLayout.MainContentMobile = MainContentMobile
FormLayout.Content = Content

export default FormLayout
