import classNames from 'classnames'
import React from 'react'

import RichText from '../../components/rich-text'
import ButtonNew, { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'

import { HEADER_CONTENT_VARIANT } from './HeaderContentBlock.const'

const HeaderContentBlock = ({
  title,
  content,
  action,
  variant = HEADER_CONTENT_VARIANT.GREY,
  textTokens = {}
}) => {
  return (
    <div
      className={classNames('c-header-content-block', {
        'c-header-content-block--green': variant === HEADER_CONTENT_VARIANT.green
      })}
    >
      {title ? <h5>{title}</h5> : null}
      {content && (
        <div className="c-header-content-block__content">
          <RichText data={content} tokens={textTokens} />
        </div>
      )}
      {action && action.url && action.label && (
        <ButtonNew
          url={action.url}
          classes="c-header-content-block__action-button"
          variant={BUTTON_VARIANT.SECONDARY}
          size="md"
          icon={'ti-circle-arrow-right'}
        >
          {action.label}
        </ButtonNew>
      )}
    </div>
  )
}

export default HeaderContentBlock
