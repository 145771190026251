import cn from 'classnames'
import React, { forwardRef, memo, useContext } from 'react'

import { IconNew } from '..'
import { SiteDataContext } from '../../components'
import { useSlider } from '../hooks/use-slider'

const RangeSlider = (
  {
    defaultValue,
    value = 0,
    labels = [],
    getValue = () => null,
    orders,
    classes,
    isFocus = null,
    setStoredSliderValue,
    isStoredValue = false,
    hideLabel
  },
  ref
) => {
  const { isTextRtl } = useContext(SiteDataContext)
  const MIN = labels[0].min
  const MAX = labels[labels.length - 1].max

  const { sliderInput, sliderThumb, sliderValue, thumbPosition, handleChange } = useSlider(
    ref,
    orders,
    MIN,
    MAX,
    isFocus,
    getValue,
    isStoredValue,
    setStoredSliderValue
  )

  return (
    <div
      className={cn('c-range-slider-wrapper', classes, {
        'c-range-slider-wrapper--rtl': isTextRtl
      })}
    >
      <p className="c-range-slider-wrapper__min">{MIN}</p>
      <div
        className={cn('c-range-slider', {
          'c-range-slider--with-labels': labels.length > 0,
          'c-range-slider--rtl': isTextRtl
        })}
      >
        <div className="c-range-slider__fill-container">
          <div className="c-range-slider__fill" style={{ transform: `scaleX(${sliderValue})` }} />
          <input
            ref={sliderInput}
            className="c-range-slider__input"
            type="range"
            min={MIN}
            max={MAX}
            defaultValue={defaultValue}
            value={value}
            onChange={handleChange}
            dir={isTextRtl ? 'rtl' : 'ltr'}
          />
        </div>

        <div
          ref={sliderThumb}
          className="c-range-slider__thumb"
          style={{
            ...(isTextRtl
              ? { right: `calc(${thumbPosition}px)` }
              : { left: `calc(${thumbPosition}px)` })
          }}
        >
          {!hideLabel ? (
            <div className="c-range-slider__tooltip-wrapper">
              <div className="c-range-slider__tooltip">{orders}</div>
            </div>
          ) : null}
          <IconNew
            name={'tabler-arrows-horizontal'}
            size={8}
            classes={'c-range-slider__thumb__icon'}
          />
        </div>
      </div>
      <p className="c-range-slider-wrapper__max">{MAX}+</p>
    </div>
  )
}

const FowardRefComponent = forwardRef(RangeSlider, {})

export default memo(FowardRefComponent)
