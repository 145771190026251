import cn from 'classnames'
import React, { useContext } from 'react'

//
import { SiteDataContext } from '../../components'
import { PricingSide } from '../PricingSide'
import TabBox from '../TabBox'
import { withAnchor } from '../hooks'

const Pricing = ({ tabs = [], sideContent, children, classes = '' }) => {
  const { isTextRtl } = useContext(SiteDataContext)
  return (
    <div className={cn('c-pricing', classes)}>
      <div className="c-pricing__container c-pricing__container--mobile">
        <div
          className={cn('l-container:12/12 l-container--default-spacing', {
            'c-pricing__spacing--rtl': isTextRtl
          })}
        >
          <div className="l-grid l-grid--align:middle">
            <div
              className={cn('l-grid__col:12/12 l-grid__col:7/12@at:viewport-9', {
                'l-grid__col--left-offset:1/12@at:viewport-12': !isTextRtl
              })}
            >
              <TabBox tabs={tabs}>{children}</TabBox>
            </div>

            <div className="l-grid__col:12/12 l-grid__col:5/12@at:viewport-9 l-grid__col:4/12@at:viewport-12">
              <div className="c-pricing__container c-pricing__container--desktop">
                <PricingSide classes="c-pricing__side" {...sideContent} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withAnchor(Pricing)
