import cn from 'classnames'
import React, { forwardRef, useContext } from 'react'

import { SiteDataContext } from '../../components'

const Input = (
  { textLabel = 'text', handleChange, readOnly = false, center = false, classes, ...InputProps },
  ref
) => {
  const { isTextRtl } = useContext(SiteDataContext)
  return (
    <div
      className={cn(
        'c-text-input',
        { 'c-text-input--center': center, 'c-text-input--rtl': isTextRtl },
        classes
      )}
    >
      <input
        ref={ref}
        onChange={handleChange}
        className="c-text-input__field"
        aria-label={textLabel}
        readOnly={readOnly}
        {...InputProps}
      />
    </div>
  )
}

Input.displayName = 'Input'

export default forwardRef(Input)
