import React from 'react'

//
import { IntegrationLabelTypes } from '../storybook/IntegrationLabel'

// Support for multiline text fields
export const multilineTextField = (title, span = false) => {
  if (!title) {
    return ''
  }

  const titlePieces = title.trim().split(/(?:\r\n|\r|\n|\\n)/g)
  const total = titlePieces.length
  const multiline = []

  titlePieces.forEach((value, idx) => {
    if (idx < total - 1) {
      multiline.push(
        <React.Fragment key={`text-${Math.random()}`}>
          {span ? <span>{value}</span> : value}
          {value ? <br /> : null}
        </React.Fragment>
      )
    } else {
      multiline.push(
        <React.Fragment key={`text-${Math.random()}`}>
          {span ? <span>{value}</span> : value}
        </React.Fragment>
      )
    }
  })

  return multiline
}

// Get URL parameter helper
export const getUrlParameter = (url, query) => {
  // eslint-disable-next-line
  const name = query.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  // eslint-disable-next-line
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`)
  const results = regex.exec(url)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const queryParams = (params) =>
  Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')

export const getRandomIndex = (arr = []) => {
  if (!arr || !arr.length) {
    return 0
  }

  const min = Math.ceil(0)
  const max = Math.floor(arr.length - 1)

  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const shuffleArray = (arr) =>
  arr
    .map((a) => [Math.random(), a])
    .sort((a, b) => a[0] - b[0])
    .map((a) => a[1])

export const getIntegrationLabel = (label) => {
  switch (label) {
    case 'comingSoon': {
      return 'Coming Soon'
    }
    case 'new': {
      return 'New'
    }
    default: {
      return ''
    }
  }
}

export const getIntegrationLabelColor = (label) => {
  switch (label) {
    case 'comingSoon': {
      return IntegrationLabelTypes.yellow
    }
    case 'new': {
      return IntegrationLabelTypes.green
    }
    default: {
      return IntegrationLabelTypes.white
    }
  }
}

export const getWistiaAspectRatio = (aspectRatio) => {
  switch (aspectRatio) {
    case '4:3 (standard)': {
      return { x: 4, y: 3 }
    }
    case '21:9 (anamorphic)': {
      return { x: 21, y: 9 }
    }
    case '1:1 (square)': {
      return { x: 1, y: 1 }
    }
    case '4:5 (portrait)': {
      return { x: 4, y: 5 }
    }
    case '16:9 (widescreen - default), 4:3 (standard), 21:9 (anamorphic), 1:1 (square), 4:5 (portrait)':
    default: {
      return { x: 16, y: 9 }
    }
  }
}

export const NUMBER_OF_WORDS = 4

export const getTruncatedString = (str, num) => {
  const words = str.split(' ').splice(0, num)
  if (str.split(' ').length > num) return words.join(' ')
  return str
}

export const THEME = Object.freeze({
  dark: 'dark',
  light: 'light'
})

export const PRICING = Object.freeze({
  BILLING_TIME_ANNUALLY: 'annual',
  BILLING_TIME_MONTHLY: 'monthly',
  BILLING_TIME_YEAR: 'year',
  BILLING_TIME_MONTH: 'month',
  PAY_MONTHLY_KEY: 'pricing.payMonthly',
  PAY_ANNUALLY_KEY: 'pricing.payAnnually',
  DEFAULT_ORDERS_VALUE: 0,
  DEFAULT_VALUE: 1,
  DEFAULT_ORDERS_LIMIT: 9999,
  ORDER_PLAN: {
    maxNoOfOrders: 0,
    monthlyExtraCharge: 0,
    annualExtraCharge: 0,
    maxOrderThreshold: 0,
    monthlyPricePlan: 0,
    annualPricePlan: 0,
    totalAnnualPricePlan: 0,
    monthlySetupFee: 0,
    annualSetupFee: 0
  },
  ANNUAL_VALUE: 12
})
