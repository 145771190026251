import cn from 'classnames'
import React from 'react'

//
import FeatureCardList from '../FeatureCard/FeatureCardList'
import SectionHeader, { ALIGN } from '../SectionHeader'
import { withAnchor } from '../hooks'

const FeatureListBlock = ({ title, text, features, classes = '' }) => {
  const getfeatureListItems = (feature) =>
    feature?.featureListItems || feature?.featureListItemsCollection?.items
  return (
    <section className={cn('c-feature-list l-section', classes)}>
      <div>
        {title && (
          <div className="l-section__header l-container:12/12 l-container--default-spacing">
            <div className="l-container:6/12">
              <SectionHeader title={title} text={text} align={ALIGN.center} />
            </div>
          </div>
        )}

        <div className="l-section__content">
          {features.map((feature) => {
            const {
              id,
              title,
              icon,
              description,
              textTokens,
              theme,
              preface,
              bulletListColor,
              price,
              month,
              disclaimer,
              tag,
              action
            } = feature
            return (
              <FeatureCardList
                key={`story-${id}`}
                classes="l-grid_col:12/12 l-grid__col:10/12@at:viewport-7"
                {...{
                  title,
                  icon,
                  description,
                  textTokens,
                  featureListItems: getfeatureListItems(feature),
                  theme,
                  preface,
                  bulletListColor,
                  price,
                  month,
                  disclaimer,
                  tag,
                  action
                }}
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default withAnchor(FeatureListBlock)
