import cn from 'classnames'
import React, { useContext } from 'react'

import { ButtonNew, Chip, IconNew } from '..'
import { LocationContext, SiteDataContext } from '../../components'
import RichText from '../../components/rich-text'
import { getUrl } from '../../utils/urls'
import { BUTTON_VARIANT } from '../ButtonNew/ButtonNew.js'
import { CHIP_COLORS } from '../Chip/Chip.jsx'
import Kpi from '../Kpi'
import { withAnchor } from '../hooks'

const FeatureCardList = ({
  title = '',
  description,
  preface = '',
  textTokens = {},
  classes = '',
  featureListItems = [],
  bulletListColor,
  price,
  month,
  disclaimer,
  tag,
  action
}) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  const isListItems = !!featureListItems?.length
  const { isTextRtl } = useContext(SiteDataContext)

  return (
    <div
      className={cn('c-feature-card-list', classes, {
        'c-feature-card-list--with-price': price,
        'c-feature-card-list--rtl': isTextRtl
      })}
    >
      <div className="l-container:12/12 l-container--default-spacing c-feature-card-list__container">
        <div className="c-feature-card-list__content">
          <Kpi variant="list" {...{ title, description, textTokens, preface }} />
          {price ? (
            <div className="c-feature-card-list__info-text">
              {tag ? <Chip text={tag} color={CHIP_COLORS.YELLOW} /> : null}
              <p>
                <span className="c-feature-card-list__price-number">{price}</span> /{month}
              </p>
            </div>
          ) : null}
          {disclaimer ? <p className="c-feature-card-list__disclaimer">{disclaimer}</p> : null}
          {action ? (
            <div className="c-feature-card-list__action">
              <ButtonNew
                trackingId={action?.trackingId}
                url={getUrl(action, slugPrefix)}
                variant={BUTTON_VARIANT.GREEN}
              >
                {action.title || ''}
              </ButtonNew>
            </div>
          ) : null}
        </div>
        <ul className={`c-feature-card-list__items`}>
          {isListItems &&
            featureListItems?.map(({ icon, content, id }) => (
              <li key={id} className="c-feature-card-list__items-list">
                <div
                  className={`c-feature-card-list__items-icon c-feature-card-list__items-icon--${bulletListColor}`}
                >
                  {icon && <IconNew name={icon} size={16} />}
                </div>
                {content && (
                  <div className="c-feature-card-list__items-content">
                    <RichText
                      data={content}
                      tokens={textTokens}
                      classes="c-feature-card-list__items-text"
                    />
                  </div>
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export default withAnchor(FeatureCardList)
