import React, { createContext, useContext, useRef } from 'react'

import { getPricingTiers } from '../helpers/getPricingTiers'
import { useOrdersPrice } from '../storybook/hooks/use-orders-price'

export const PricingContext = createContext(null)

export const PricingContextProvider = ({ children, data }) => {
  const { microCopy, slugPrefix } = data?.extra
  const sliderRef = useRef()

  const range = getPricingTiers(data?.pricingTiers)

  const minOrders = data?.pricingTiers[0].minOrders
  const maxOrders = data?.pricingTiers.slice(-1)[0].maxOrders

  const pricingOrders = {
    initialMonthlyNumberOfOrders: data.pricingOrdersInitialMonthlyNumberOfOrders,
    initialOrderValue: data.pricingOrdersInitialOrderValue,
    initialNumberOfLocations: data.pricingOrdersInitialNumberOfLocations,
    minNumberOfLocations: data.pricingOrdersMinimumNumberOfLocations,
    maxNumberOfLocations: data.pricingOrdersMaximumNumberOfLocations,
    minOrderValue: data.pricingOrdersMinimumOrderValue,
    maxOrdersLimit: data.pricingOrdersMaxOrdersLimit || null,
    maxOrderValue: data.pricingOrdersMaximumOrderValue,
    preface: data.pricingOrderPreface,
    ordersLabel: data.pricingOrdersLabel,
    locationsLabel: data.pricingOrdersLocationsLabel,
    addMoreLocationsLabel: data.pricingOrdersAddMoreLocationsLabel,
    orderValueLabel: data.pricingOrdersValueLabel,
    action: data.pricingOrdersAction
  }

  const pricingEstimations = {
    preface: data.pricingEstimationsPreface,
    monthlyNote: data.pricingEstimationsMonthlyNote,
    annualNote: data.pricingEstimationsAnnualNote,
    action: data.pricingEstimationsAction,
    savingsPreface: data.pricingEstimationsSavingsPreface,
    savingsLabel: data.pricingEstimationsSavingsLabel,
    hideLabel: data.pricingEstimationsHideLabel,
    showLabel: data.pricingEstimationsShowLabel,
    totalLabel: data.pricingEstimationsTotalLabel,
    onlineOrdersLabel: data.pricingEstimationsOnlineOrdersLabel,
    maxOrdersPlanLabel: data.pricingEstimationsMaxOrdersLabel,
    extraChargeLabel: data.pricingEstimationsExtraChargeLabel,
    setupFeeLabel: data.pricingEstimationsSetupFeeLabel,
    waivedLabel: data.pricingEstimationsWaivedLabel
  }

  const {
    orders,
    setOrders,
    orderValue,
    locationValue,
    handleOrderChange,
    handleOrderValueChange,
    handleLocationChange,
    handleLocationDecrement,
    handleLocationIncrement,
    storedSliderValue,
    setStoredSliderValue,
    monthlySavingsPrice,
    annualSavingsPrice,
    handleHiddenValues
  } = useOrdersPrice(pricingOrders, data?.orderErrorPercentage, data?.reductionErrorPercentage)

  // define provider to send
  const contextProps = {
    orders,
    setOrders,
    orderValue,
    locationValue,
    handleOrderChange,
    handleOrderValueChange,
    handleLocationChange,
    handleLocationDecrement,
    handleLocationIncrement,
    handleHiddenValues,
    storedSliderValue,
    setStoredSliderValue,
    monthlySavingsPrice,
    annualSavingsPrice,
    slugPrefix,
    range,
    sliderRef,
    microCopy,
    maxOrders,
    minOrders,
    pricingOrders,
    pricingEstimations,
    ...data
  }
  return <PricingContext.Provider value={contextProps}>{children}</PricingContext.Provider>
}

export const usePricingContext = () => useContext(PricingContext)
