import React from 'react'

//
import { SectionHeader, Integration, ButtonNew } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { IntegrationTypes } from '../../../storybook/Integration'
import { ALIGN } from '../../../storybook/SectionHeader'

const BlockIntegrations = ({
  data: { title, description, ctaUrl, ctaLabel, integrations, slugPrefix }
}) => {
  return (
    <section className="l-section l-section--grey c-integration-listing">
      <div className="l-container:12/12 l-container--default-spacing">
        <SectionHeader title={title} headingType="h3" align={ALIGN.center} />
        <div className="l-grid l-grid--align:center u-margin-top:40">
          {integrations &&
            Array.isArray(integrations) &&
            integrations.length === 1 &&
            integrations.map((integrationList, i) => {
              return (
                <div
                  key={`integrationsList_${i}_${integrationList.title}`}
                  className={`l-grid__col:8/12@at:viewport-9`}
                >
                  {integrationList?.title && <h5>{integrationList?.title}</h5>}

                  {integrationList?.data?.length > 0 && (
                    <>
                      <div
                        className={`${
                          integrationList?.title ? 'u-margin-top:24 ' : ''
                        }l-grid l-grid--spacing:4`}
                      >
                        {integrationList?.data?.map((integration) => (
                          <div
                            key={integration?.id || integration?.sys?.id}
                            className="l-grid__col:12/12 l-grid__col:6/12@at:viewport-7"
                          >
                            <Integration
                              title={integration?.title}
                              type={integration?.type}
                              url={`/${slugPrefix}/integrations/${integration?.slug}`.replace(
                                '//',
                                '/'
                              )}
                              logo={integration?.logo?.url}
                              size={IntegrationTypes.medium}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              )
            })}

          {integrations &&
            Array.isArray(integrations) &&
            integrations.length > 1 &&
            integrations.map((integrationList, i) => {
              return (
                <div
                  key={`integrationsList_${i}_${integrationList.title}`}
                  className={`l-grid__col:3/12${
                    i === 0 ? ' l-grid__col--left-offset:1/12@at:viewport-9' : ''
                  } l-grid__col:12/12@to:viewport-9`}
                >
                  {integrationList?.title && (
                    <h5 className="u-color:white">{integrationList?.title}</h5>
                  )}

                  {integrationList?.data?.length > 0 && (
                    <div
                      className={`${
                        integrationList?.title ? 'u-margin-top:24 ' : ''
                      }l-grid l-grid--spacing:4`}
                    >
                      {integrationList?.data?.map((integration) => (
                        <div
                          key={integration?.id}
                          className="l-grid__col:12/12 l-grid__col:6/12@at:viewport-4 l-grid__col:12/12@at:viewport-9"
                        >
                          <Integration
                            title={integration?.title}
                            type={integration?.type}
                            url={integration?.url}
                            logo={integration?.logo?.url}
                            size={IntegrationTypes.medium}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )
            })}
        </div>

        <div className="l-grid l-grid--align:middle l-grid--align:center l-grid--default-spacing u-margin-top:40 u-gap:48">
          <p className="u-text-align:center">{description}</p>
          <ButtonNew variant={BUTTON_VARIANT.SECONDARY} url={ctaUrl}>
            {ctaLabel}
          </ButtonNew>
        </div>
      </div>
    </section>
  )
}

export default BlockIntegrations
