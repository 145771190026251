import React from 'react'

//
import IconLabel from '../IconLabel'
import NavLink from '../helpers/nav-link'

const iconMapping = {
  type: 'topic/user',
  location: 'topic/location',
  language: 'topic/comment'
}

const SectionItemsRow = ({ cardId, items = [] }) => {
  if (!items.length) {
    return null
  }

  return (
    <div className="c-careers-offer-card__categories-row">
      {items.map((item, idx) => (
        <div key={`careers-card-${cardId}-${idx}`}>
          <IconLabel secondary icon={iconMapping?.[item.type] || 'tabler-comet'}>
            {item.value}
          </IconLabel>
        </div>
      ))}
    </div>
  )
}

const CareersOfferCard = ({ id, to = '', title, subTitle, categories = [] }) => {
  const filteredCategories = categories.filter((category) => category?.value?.length)

  return (
    <NavLink to={to}>
      <div className="c-careers-offer-card l-container:12/12">
        <h5>{title}</h5>
        <h6>{subTitle}</h6>
        <hr />
        <div className="c-careers-offer-card__categories">
          <SectionItemsRow cardId={id} items={filteredCategories.slice(0, 2)} />
          <SectionItemsRow cardId={id} items={filteredCategories.slice(2, 4)} />
        </div>
      </div>
    </NavLink>
  )
}

export default CareersOfferCard
