import React, { useEffect } from 'react'

//
import { ButtonNew, FAQ, LoadMore, SectionHeader } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { ALIGN, HEADING_TYPE } from '../../../storybook/SectionHeader'
import { getMicroCopy } from '../../../utils/microcopy'
import { getUrl } from '../../../utils/urls'
import { LocationContext, MicroCopyContext } from '../../page-wrapper'

const BlockFaqTop = ({ data }) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location
  const limit = 10
  const microCopyData = React.useContext(MicroCopyContext)

  const questions = data.questions
    ? data.questions.map((question) => ({
        question: question.title,
        answer: question.description
      }))
    : []

  const [offsetToShow, setOffsetToShow] = React.useState(limit)

  useEffect(() => {
    setOffsetToShow(limit)
  }, [])

  return (
    <section className="l-section">
      <div className="l-container:12/12 l-container--default-spacing">
        <div className="l-grid l-grid--default-spacing">
          <div className="c-faq-top__header l-grid__col:4/12 l-grid__col:12/12@to:viewport-9">
            <SectionHeader
              title={data?.title}
              text={data?.description}
              headingType={HEADING_TYPE.h3}
              align={ALIGN.left}
            >
              {data.action ? (
                <ButtonNew variant={BUTTON_VARIANT.GREEN} url={getUrl(data.action, slugPrefix)}>
                  {data.action.title}
                </ButtonNew>
              ) : null}
            </SectionHeader>
          </div>
          <div className="l-grid__col:8/12 l-grid__col:12/12@to:viewport-9">
            {questions.length > 0 ? (
              <FAQ
                faqs={questions.slice(0, offsetToShow)}
                classes={['l-section--no-padding-top', 'l-section--no-padding-bottom']}
                defaultSpacing={false}
                footerSlot={
                  offsetToShow < questions.length && (
                    <div className="l-section__footer">
                      <LoadMore
                        current={offsetToShow}
                        total={questions.length}
                        loadLabel={getMicroCopy({
                          key: `integration.paginationLoadMore`,
                          data: microCopyData
                        })}
                        separationLabel={getMicroCopy({
                          key: `integration.paginationOf`,
                          data: microCopyData
                        })}
                        handleLoadMore={() => setOffsetToShow(offsetToShow + limit)}
                      />
                    </div>
                  )
                }
              />
            ) : (
              <p>{getMicroCopy({ key: 'faq.noFaqsMessage', data: data.extra.microCopy })}</p>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockFaqTop
