import React from 'react'

//
import { IconNew } from '../../storybook'

const ErrorMessage = ({ title, text }) => (
  <div className="c-hubspot-form c-hubspot-form-thank-you">
    <IconNew
      classes="c-hubsport-form-message__icon c-hubsport-form-error-message__icon"
      name="tabler-x"
      size={60}
    />
    <h4>{title}</h4>
    <p>{text}</p>
  </div>
)

export default ErrorMessage
