import React from 'react'

//
import { Integration, LoadMore } from '../../../storybook'
import { IntegrationTypes } from '../../../storybook/Integration'
import { getIntegrationLabel, getIntegrationLabelColor } from '../../../utils/common'

const INTEGRATION_LABELS = Object.freeze({
  COMING_SOON: 'comingSoon',
  NEW: 'new'
})

export const IntegrationsList = ({ integrations, slugPrefix, labels, limit = 10 }) => {
  const [offsetToShow, setOffsetToShow] = React.useState(limit)
  const integrationsWithHighlight = integrations
    .filter((a) => a.highlight)
    .sort((a) => (a.label === INTEGRATION_LABELS.NEW ? -1 : 1))

  const integrationsWithLabels = integrations
    .filter((a) => !!a.label && !a.highlight)
    .sort((a) => (a.label === INTEGRATION_LABELS.NEW ? -1 : 1))

  const integrationsWithoutLabels = integrations.filter((a) => !a.label && !a.highlight)

  const integrationsToShow = [
    ...integrationsWithHighlight,
    ...integrationsWithLabels,
    ...integrationsWithoutLabels
  ]

  return (
    <div className="l-section__content">
      <div className="l-grid l-grid--default-spacing">
        {integrationsToShow.map((node, index) => {
          const {
            sys: { id },
            logo,
            slug,
            title,
            label,
            numberOfUsers,
            categoriesCollection: { items: categories }
          } = node
          return (
            <div
              key={id}
              className="l-grid__col:6/12 l-grid__col:12/12@to:viewport-9"
              style={{ display: index < offsetToShow ? 'block' : 'none' }}
            >
              <Integration
                {...{
                  id,
                  title,
                  type: categories.map((cat) => cat.title).join(', '),
                  size: IntegrationTypes.largeVariation,
                  label: getIntegrationLabel(label),
                  labelType: getIntegrationLabelColor(label),
                  url: `/${slugPrefix}/integrations/${slug}`,
                  logo: logo || '',
                  numberOfUsers: numberOfUsers
                }}
              />
            </div>
          )
        })}
      </div>
      {offsetToShow < integrationsToShow.length && (
        <div className="l-section__footer">
          <LoadMore
            current={offsetToShow}
            total={integrationsToShow.length}
            loadLabel={labels.paginationLoadMore}
            separationLabel={labels.paginationOf}
            handleLoadMore={() => setOffsetToShow(offsetToShow + limit)}
          />
        </div>
      )}
    </div>
  )
}

export default IntegrationsList
