import cn from 'classnames'
import React from 'react'

//
import { ImageFluid } from '../../utils/image'
import Logo from '../Logo'

const HeroIntegrationVisual = ({ integrations = [], classes = '' }) => {
  return (
    <div className={cn('c-hero-integration-visual', classes)}>
      <div className="lines lines-right u-display:none@to:viewport-7"></div>
      <div className="lines lines-left u-display:none@to:viewport-7"></div>

      <div className="c-hero-integration-visual__container">
        {(integrations.length === 0 || integrations.length === 1) && (
          <>
            <div className="c-hero-integration-visual__integration">
              <div
                className={cn(
                  'c-hero-integration-visual__visual c-hero-integration-visual__visual--green'
                )}
              >
                <Logo
                  withText={false}
                  classes="c-hero-integration-visual__image c-hero-integration-visual__image--logo"
                />
              </div>
            </div>
            <div className="c-hero-integration-visual__separator"></div>
          </>
        )}

        {integrations.length > 0 &&
          integrations.map((item, index) => {
            if (index === integrations.length - 1) {
              return (
                <div key={item.id} className="c-hero-integration-visual__integration">
                  <div className="c-hero-integration-visual__visual">
                    <ImageFluid
                      className="c-hero-integration-visual__image"
                      image={{
                        url: item.image
                      }}
                      containImages={true}
                    />

                    <span className="c-hero-integration-visual__borders">
                      <span className="c-hero-integration-visual__border"></span>
                    </span>
                  </div>
                </div>
              )
            }
            return (
              <>
                <div key={item.id} className="c-hero-integration-visual__integration">
                  <div className="c-hero-integration-visual__visual">
                    <img
                      loading="lazy"
                      className="c-hero-integration-visual__image"
                      src={item.image}
                      alt={item.label}
                      title={item.label}
                    />

                    <span className="c-hero-integration-visual__borders">
                      <span className="c-hero-integration-visual__border"></span>
                    </span>
                  </div>
                </div>
                <div className="c-hero-integration-visual__separator"></div>
              </>
            )
          })}
      </div>
    </div>
  )
}

export default HeroIntegrationVisual
