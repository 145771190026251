/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'gatsby'
import React from 'react'
//

const SITE_URL = process.env.GATSBY_SITE_URL || ''

export const NavLink = ({ to, children, trackingId, ...rest }) => {
  if (process.env.PREVIEW_MODE === 'true') {
    return (
      <a href="#" {...rest} onClick={(e) => e.preventDefault()}>
        {children}
      </a>
    )
  }

  if (!to) {
    ;<div {...rest}>{children}</div>
  }

  let path = to
  const homepage = path === SITE_URL ? path : null

  path = to?.startsWith(SITE_URL) ? to?.replace(SITE_URL, '') : to

  if (path?.startsWith('/') || homepage) {
    return (
      <Link to={homepage ? '/' : path} {...rest}>
        {children}
      </Link>
    )
  }

  const uriParts = path?.split('###')
  if (uriParts?.length > 1 && uriParts[uriParts?.length - 1]) {
    return <span id={uriParts[uriParts?.length - 1]}> </span>
  }

  const props = {}

  if ((!SITE_URL || !path?.startsWith(SITE_URL)) && !path?.startsWith('#')) {
    props.target = '_blank'
  }

  if (rest?.activeClassName) {
    props.className = props?.className
      ? `${props.className} ${rest.activeClassName}`
      : rest.activeClassName
    delete rest.activeClassName
  }

  return (
    <a id={trackingId} href={path} {...props} {...rest}>
      {children}
    </a>
  )
}

export default NavLink
