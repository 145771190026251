import { getContentForMarket } from './market'
import { getUrl } from './urls'

/**
 *  Used in the Header to see if a ContentfulNavigationItem has
 * content for a ContentfulModalHubSpotForm
 *
 * @param {ContentfulNavigationItem} navigationItem
 * @param {String} slugPrefix
 * @returns
 */
export default function getButtonAction(navigationItem, slugPrefix) {
  if (!navigationItem) {
    return { label: null, url: '#' }
  }

  const { content } = navigationItem

  if (Array.isArray(content) && content.length > 0) {
    const contentToUse = getContentForMarket(content, slugPrefix)

    if (contentToUse && contentToUse['__typename'] === 'ContentfulModalHubSpotForm') {
      return {
        label: navigationItem?.title,
        url: null,
        modal: {
          title: contentToUse?.title,
          description: contentToUse?.description,
          hubSpotForm: contentToUse?.hubSpotForm
        }
      }
    }
  }

  return { label: navigationItem?.title, url: getUrl(navigationItem, slugPrefix) }
}
