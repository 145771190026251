import cn from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

//
import TablerIconPlay from '../Icon/icons/topic/player-play.svg'

const Video = ({
  videoUrl = '',
  posterUrl = '',
  playButton = true,
  autoPlay = false,
  loop = false,
  muted = false,
  aspectRatioX = 16,
  aspectRatioY = 9
}) => {
  const video = useRef(null)

  const [isPlaying, setIsPlaying] = useState(!playButton)

  const calculateAspectRatio = (aspectRatioX, aspectRatioY) => {
    return (aspectRatioY / aspectRatioX) * 100
  }

  const handlePlayButton = () => {
    if (!playButton) {
      return
    }

    setIsPlaying(!isPlaying)
  }

  useEffect(() => {
    if (isPlaying) {
      video.current.play()
    } else {
      video.current.pause()
    }
  }, [isPlaying])

  const handleEndVideo = () => {
    if (!playButton) {
      return
    }

    setIsPlaying(false)

    video.current.load()
  }

  const paddingTop = calculateAspectRatio(aspectRatioX, aspectRatioY)

  return (
    videoUrl && (
      <div className="c-media" style={{ paddingTop: `${paddingTop}%` }}>
        <div
          className="c-media__wrapper"
          onClick={handlePlayButton}
          onKeyDown={(e) => (e.key === ' ' ? handlePlayButton() : null)}
          role="button"
          tabIndex={0}
        >
          {
            // to prevent "video thats muted needs <track>" linting error
            /* eslint-disable jsx-a11y/media-has-caption */
            <video
              ref={video}
              className="c-media__video"
              autoPlay={autoPlay}
              muted={muted}
              loop={loop}
              playsInline
              preload="metadata"
              poster={posterUrl}
              onEnded={handleEndVideo}
            >
              <source src={videoUrl} type="video/mp4" />
              Sorry, your browser doesnt support embedded videos.
            </video>
            /* eslint-enable */
          }

          {playButton && (
            <div className={cn('c-media__button', { 'is-hidden': isPlaying })}>
              <button aria-label="Play Button">
                <TablerIconPlay width="40" height="40" fill="#1c1c1c1" />
              </button>
            </div>
          )}
        </div>
      </div>
    )
  )
}

export default Video
